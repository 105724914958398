// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";

// application

import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import NavMenu from "./NavMenu";
import { ArrowRoundedDown9x6Svg } from "../../svg";
import { url } from "../../helper";

import { useSelector } from "react-redux";
// data stubs

export default function NavLinksNew(props) {
    const [navLinks, SetNavLinks] = useState();
    const selectedData = useSelector((state) => state.locale);
    let linksList = "";

    useEffect(() => {
        fetch(`${url}/api/cms/menus?locale=${selectedData}`)
            .then((response) => response.json())
            .then((res) => {
                SetNavLinks(res.data?.filter((e) => e.name != "Blog"));
            });
    }, [selectedData]);

    if (navLinks != undefined) {
        navLinks.sort(function (a, b) {
            var keyA = a.position,
                keyB = b.position;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }
    if (navLinks) {
        linksList = navLinks.map((item, index) => {
            let arrow;
            let submenu;
            if (item && item.length > 0) {
                arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
            }

            if (item && item.length > 0) {
                submenu = (
                    <div className="nav-links__menu">
                        <NavMenu items={item} />
                    </div>
                );
            }

            const classes = classNames("nav-links__item fromLeft", {
                "nav-links__item--with-submenu fromLeft": item,
            });

            // const hello = (event, pageUrl) => {
            //     if (pageUrl.url_key === 'help') {
            //         event.preventDefault();
            //     }
            // };
            if (item.page_id && item.type == "header") {
                return (
                    <li key={index} className={classes}>
                        <NavLink exact activeClassName="is-active" to={`/page/${item.page_id}`} style={{marginTop:20}}>
                            <span>
                                <FormattedMessage
                                    id={`${item.name}`.toLowerCase().replace(/ /g, ".")}
                                    defaultMessage={item.name}
                                />
                                {arrow}
                            </span>
                            <span className="border"></span>
                        </NavLink>

                        {submenu}
                    </li>
                );
            }
            return null;
        });
    }

    return (
        <ul className="nav-links__list">
          <li className="nav-links__item  fromLeft" style={{backgroundColor:"#161616",height:50 }}>
            <NavLink exact activeClassName="is-active" to="/stores" style={{marginTop:16}}>
                    <span style={{color:"#ffffff"}}>
                        <FormattedMessage id="stores" defaultMessage="Stores" />
                    </span>
              <span className="border"></span>
            </NavLink>
          </li>

            <li className="nav-links__item  fromLeft">
                <NavLink exact activeClassName="is-active" to="/shop/saledProducts" style={{marginTop:20}}>
                    <span>
                        <FormattedMessage id="saledProducts" defaultMessage="Saled Products" />
                    </span>
                    <span className="border"></span>
                </NavLink>
            </li>
          <li className="nav-links__item  fromLeft">
            <NavLink exact activeClassName="is-active" to="/shop/newCollection" style={{marginTop:20}}>
                    <span >
                        <FormattedMessage id="newcollection" defaultMessage="New Collection" />
                    </span>
              <span className="border"></span>
            </NavLink>
          </li>
            <li className="nav-links__item  fromLeft">
                <NavLink exact activeClassName="is-active" to="/blog" style={{marginTop:20}}>
                    <span>
                        <FormattedMessage id="blogs" defaultMessage="Blogs" />
                    </span>
                    <span className="border"></span>
                </NavLink>
            </li>
            {linksList}
        </ul>
    );
}
