// react
import React, { useState, useEffect } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import { Cart16Svg } from "../../svg";
import { cartAddItem } from "../../store/cart";
import { url } from "../../services/utils";
import { urlLink } from "../../helper";
import { FormattedMessage } from "react-intl";
import Currency from "../shared/Currency";

function Suggestions(props) {
    
    const { context, className, products, cartAddItem, query: value, setQuery } = props;
    // const ifEmpty = products.length == 0 && value != "";
    const rootClasses = classNames(`suggestions suggestions--location--${context}  ${context}-detect-id`, className);
    
    // products.splice(products.length - 2);

    function handleClick(element) {
        if (value != "") {
            const boolean = document.getElementsByClassName("suggestions__list")[1].contains(element.target);
            if (!boolean) {
                setQuery("");
            }
        }
    }

    useEffect(() => {
        window.addEventListener("click", handleClick);
        return () => {
            window.removeEventListener("click", handleClick);
        };
    }, [value]);

    function hasFalsyValue(arr){

        const obj =  arr.find(product =>product.id == -1)
        if(typeof obj == "object"){
            return true
        }
        return false
    }

   

    let list = null;
    if (products) {
        
        list = products.map((product) => (
            <li key={product.product_id} className="suggestions__item">
                {product.id != -1 ? (
                    <>
                  
                        {product.images && product.images.length > 0 && (
                            <div className="suggestions__item-image product-image">
                                <div className="suggestions-img product-image__body">
                                    {/*<img className="product-image__img" src={product.base_image.medium_image_url} alt="" />*/}

                                    <img
                                        className="product-image__img"
                                        src={`${urlLink}/storage/${product.images[0]}`}
                                        alt="Product image"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="suggestions__item-info">
                            <Link className="suggestions__item-name" to={url.product(product)}>
                                {product.name}
                            </Link>
                        </div>
                        {/*<div className="suggestions__item-price">*/}
                        {/*    /!* <Currency value={price} /> *!/*/}
                        {/*    {product.product_type == "configurable" ? (*/}
                        {/*        product.min_price*/}
                        {/*    ) : product.special_price !== null ? (*/}
                        {/*        <>*/}
                        {/*            <span className="product-card__old-price">*/}
                        {/*                <Currency value={product.formatted_price} />*/}
                        {/*            </span>*/}
                        {/*            <span className="product-card__new-price">*/}
                        {/*                <Currency value={product.formatted_special_price} />*/}
                        {/*            </span>*/}
                        {/*        </>*/}
                        {/*    ) : (*/}
                        {/*        <span>*/}
                        {/*            <Currency value={product.formatted_price} />*/}
                        {/*        </span>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </>
                ) : <div className="suggestions__item-name ">
                <FormattedMessage id="SorryNothingFoundFor" defaultMessage="Sorry, nothing found for" /> "{"  " + value} " </div>}
            </li>
        ));
    } 

    return (
        <div className={hasFalsyValue(products) && value != "" ? `ff-${rootClasses}` : rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
