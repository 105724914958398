// react
import React from 'react'

// third-party
import classNames  from 'classnames'
import PropTypes   from 'prop-types'
import { connect } from 'react-redux'
import { Link }    from 'react-router-dom'

// application
import AsyncAction                  from './AsyncAction'
import Currency                     from './Currency'
import { cartAddItem }              from '../../store/cart'
import { Cart16Svg, Wishlist16Svg } from '../../svg'
import { compareAddItem }           from '../../store/compare'
import { quickviewOpen }            from '../../store/quickview'
import { url }                      from '../../services/utils'
import { wishlistAddItem }          from '../../store/wishlist'
import { urlLink }                  from '../../helper'
import { FormattedMessage }         from 'react-intl'

import { useSelector } from 'react-redux'



function ProductCard (props) {
  const { customer, product, layout, cartAddItem, wishlistAddItem } = props
  const currency = useSelector((state) => state.currency)
  const selectedData = useSelector((state) => state.locale)
  const cartToken = useSelector((state) => state.cartToken)
  const unknownImgSRC = 'https://api.marketin.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png'

  const containerClasses = classNames('product-card', {
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list'                       : layout === 'list',
    'product-card--layout--horizontal'                 : layout === 'horizontal',
  })

  let badges = []
  let image
  let price
  let features
  if (product.images || product.image) {
    image = (
      <div className="product-card__image product-image">
        <Link to={url.product(product)} className="product-image__body">
          <img
            className="product-image__img"
            src={`${urlLink}/storage/${product.images ? product.images[0] : product.image}`}
            alt=""
          />
        </Link>
      </div>
    )
  } else {
    image = (
      <div className="product-card__image product-image">
        <Link to={url.product(product)} className="product-image__body">
          <img
            className="product-image__img"
            src={unknownImgSRC}
            alt=""
          />
        </Link>
      </div>
    )
  }

  // product.price
  const selectedPrice = currency.code === 'AMD' ? product.formatted_price : product.formatted_price
  const selectedSuperPrice =
           product.formatted_special_price  
  if (product.product_type === 'configurable') {

    price = (
      <div className="product-card__prices">
        <Currency value={product.formatted_price}/>
      </div>
    )
  } else if (product.formatted_special_price && Number(product?.formatted_special_price.slice(4,5) > 0)) {
    price = (
      <div className="product-card__prices">
        <span className="product-card__new-price">
            <Currency value={selectedSuperPrice}/>
        </span>
        <span className="product-card__old-price">
            <Currency value={selectedPrice}/>
        </span>
      </div>
    )
  } else {
    price = (
      <div className="product-card__prices">
    {/* old code */}
        {/* <Currency value={product.min_price ? product.min_price : product.price}/> */}

        {/* new code */}
        <Currency value={selectedPrice || product.price || product.min_price} />
      </div>
    )
  }

  // if (product.attributes && product.attributes.length) {
  //   features = (
  //     <ul className="product-card__features-list">
  //       {product.attributes
  //         .filter((x) => x.featured)
  //         .map((attribute, index) => (
  //           <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
  //         ))}
  //     </ul>
  //   )
  // }


  return (


    <>
      <div className={containerClasses}>
        {badges}
        {image}
        <div className="d-flex product-card__info">
          {product && product.product_type === 'configurable' ? (
            <>


            <Link  to={url.product(product)} className="Product_addToCart">

            <span className="btn btn btn-primary conf_prod-button">
                  <FormattedMessage id="addtocart " defaultMessage="Ավելացնել"/>
            </span>
            </Link>
            </>
          ) : (
            <AsyncAction
              action={() => cartAddItem(product, [], 1, cartToken, customer, selectedData)}
              render={({ run, loading }) => (
                <React.Fragment>
                  <div className="Product_addToCart">
                  <button
                    type="button"
                    disabled={product.in_stock}
                    onClick={run}
                    className={classNames('btn  product-card__addtocart' , {
                      'btn-loading': loading,
                    })}
                  >
                    {/*<Cart16Svg/>*/}

                    <FormattedMessage id="addtocart " defaultMessage="Ավելացնել"/>
                  </button>
                  </div>

                  {/*<button*/}
                  {/*  type="button"*/}
                  {/*  onClick={run}*/}
                  {/*  className={classNames(*/}
                  {/*    'btn btn-secondary product-card__addtocart product-card__addtocart--list',*/}
                  {/*    {*/}
                  {/*      'btn-loading': loading,*/}
                  {/*    },*/}
                  {/*  )}*/}
                  {/*>*/}
                  {/*  <FormattedMessage id="addToCart" defaultMessage="Ավելացնել զամբյուղ"/>*/}
                  {/*</button>*/}
                </React.Fragment>
              )}
            />
          )}
          <div className="product-card__name">
            <Link to={url.product(product)}>{product.name}</Link>
          </div>
          <div className="product-card__name product-card__name_Responsive">
            <Link to={url.product(product)}>{product.name ? product.name.length < 25 ? product.name :  product.name.slice(0,25)+"..." : null}</Link>
          </div>
          {price}
        </div>
      </div>
    </>
  )
}


ProductCard.propTypes = {
  /**
   * product object
   */
  product: PropTypes.object.isRequired,
  /**
   * product card layout
   * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
   */
  layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
  quickviewOpen,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)
