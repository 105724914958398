// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// application
import AppLink from "../shared/AppLink";
import Collapse from "../shared/Collapse";
import { ArrowRoundedDown12x7Svg } from "../../svg";
import NavLinksNew from "../header/NavLinksNew";
import { NavLink, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { changeLinks } from "../../services/utils";

function MobileLinks(props) {
  const locale = useSelector((state) => state.locale);
  const { links, level, onItemClick, pag } = props;
  const selectedData = useSelector((state) => state.locale);
  const handleItemClick = (item) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };
  // console.log('props', props)



  const linksList = links.map((link, index) => {

    

   
    let item;
    item = (
      <Collapse
        toggleClass="mobile-links__item--open"
        render={({ toggle, setItemRef, setContentRef }) => {
          let arrow;
          let subLinks;
          let linkOrButton;
          // link.type = "link";
          if (link.childs && link.childs.length > 0) {
            arrow = (
              <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
              </button>
            );

            subLinks = (
              <div className="mobile-links__item-sub-links" ref={setContentRef}>
                <MobileLinks links={link.childs} level={level + 1} onItemClick={onItemClick} />
              </div>
            );
          } else if (link.children && link.children.length > 0) {
            arrow = (
              <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
              </button>
            );

            subLinks = (
              <div className="mobile-links__item-sub-links" ref={setContentRef}>
               
                <MobileLinks links={link.children} level={level + 1} onItemClick={onItemClick} />
                {((link.name == undefined && link?.label?.props.id == "categoies.s") ||
                  (link.name == undefined && link?.label?.props.id == "categoies")) && (
                  <ul className="mobile_item-link-fm">
                    {/* remove static menu items and transfer in component MobileMenu.jsx */}


                    <div className="nav-links__item-mob-fm  ">
                      <AppLink
                        to="/shop/newCollection"
                        className="mobile-links__item-link "
                        onClick={() => handleItemClick(link)}
                      >
                        <span>
                          <FormattedMessage id="newcollection" defaultMessage="New Collection" />
                        </span>
                      </AppLink>
                    </div>
                    <div className="nav-links__item-mob-fm  ">
                      <AppLink
                        to="/shop/saledProducts"
                        className="mobile-links__item-link"
                        onClick={() => handleItemClick(link)}
                      >
                        <span>
                          <FormattedMessage id="saledProducts" defaultMessage="Saled Products" />
                        </span>
                      </AppLink>
                    </div>
                    <div className="nav-links__item-mob-fm ">
                      <AppLink to="/stores" className="mobile-links__item-link" onClick={() => handleItemClick(link)}>
                        <span>
                          <FormattedMessage id="stores" defaultMessage="Stores" />
                        </span>
                      </AppLink>
                    </div>
                  </ul>
                 )}
              </div>
            );
          }

          if (link.type === "button") {
            linkOrButton = (
              <button type="button" className="mobile-links__item-link" onClick={() => handleItemClick(link)}>
                {link.translations.find((item) => item.locale === selectedData).name}
              </button>
            );
          } else {
            
            linkOrButton = (
              <>
                {link?.label?.props.id != "pages" &&
                link?.label?.props.id != "categoies.s" &&
                link.name != "Root" &&
                link.name != null ? (
                  // old version of code
                  // <AppLink
                  //   to={link.children ? '/shop/catalog/' + changeLinks(link.slug, locale) : '/page/' + link.page_id}
                  //   className="mobile-links__item-link"
                  //   onClick={() => handleItemClick(link)}
                  // >
                  //   {
                  //     link.name === 'Root'
                  //       ? <FormattedMessage id="categories " defaultMessage="Categories"/>
                  //       : link.slug
                  //         ? <FormattedMessage id={link.slug} defaultMessage={link.name}/>
                  //         : link.label || link.name || link.page_title
                  //   }
                  // </AppLink>
                  <AppLink
                    to={link.children ? "/shop/catalog/" + link.slug : "/page/" + link.page_id}
                    className="mobile-links__item-link"
                    onClick={() => handleItemClick(link)}
                  >
                    {link.name === null ? (
                      <FormattedMessage id="categories " defaultMessage="Categories" />
                    ) : link.slug ? (
                      
                      <FormattedMessage id={link.slug} defaultMessage={link.name} />
                    ) : (
                      link.label || link.name || link.page_title
                    )}
                  </AppLink>
                ) : (
                  <span className="link-label-fms" onClick={toggle}>
                    {link?.label ? link?.label : <FormattedMessage id="categories " defaultMessage="Categories" />}
                  </span>
                )}
              </>
            );
          }

          return (
            <>
              <div className="mobile-links__item" ref={setItemRef}>
                <div className="mobile-links__item-title">
                  {linkOrButton}
                  {arrow}
                </div>
                {subLinks}
              </div>
            </>
          );
        }}
      />
    );

    return <li key={index}>{item} </li>;
  });
  
  return <ul className={`mobile-links mobile-links--level--${level}`}>{linksList}</ul>;
}

MobileLinks.propTypes = {
  links: PropTypes.array,
  level: PropTypes.number,
  onItemClick: PropTypes.func,
};

MobileLinks.defaultProps = {
  links: [],
  level: 0,
};

export default MobileLinks;
