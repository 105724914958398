// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { url } from "../../helper";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import shopApi from "../../api/shop";
import { url as RedirectUrl } from "../../services/utils";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

// widgets
import WidgetCategories from "../widgets/WidgetCategories";
import WidgetProducts from "../widgets/WidgetProducts";

// data stubs
import categories from "../../data/shopWidgetCategories";
import theme from "../../data/theme";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

function ShopPageProduct(props) {
    const selectedData = useSelector((state) => state.locale);
    const customer = useSelector((state) => state.customer);
    const currency = useSelector((state) => state.currency);
    const { productSlug, layout, sidebarPosition, parentId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [crossProducts, setCrossProducts] = useState([]);
    // Load product.
    useEffect(() => {
        let canceled = false;

        setIsLoading(true);

        shopApi
            .getProductBySlug(parentId == -1 ? productSlug : parentId, { lang: selectedData, currency: currency.code })
            .then((product) => {
                if (canceled) {
                    return;
                }
                setProduct(product);
                setIsLoading(false);
            });

        return () => {
            canceled = true;
        };
    }, [productSlug, selectedData, currency.code]);
    // Load related products.
    useEffect(() => {
        let canceled = false;

        // shopApi
        //     .getRelatedProducts(productSlug, { lang: selectedData, currency: currency.code }, { limit: 8 })
        //     .then((products) => {
        //         if (canceled) {
        //             return;
        //         }
        //
        //         setRelatedProducts(products.data);
        //     });
        //
        // return () => {
        //     canceled = true;
        // };
    }, [selectedData, currency.code]);

    // Load crossProducts  .
    useEffect(() => {
        let canceled = false;
        shopApi
            .getCrossSaleProducts(productSlug, { lang: selectedData, currency: currency.code }, { limit: 5 })
            .then((result) => {
                if (canceled) {
                    return;
                }
                if (result) {
                    result.data.splice(result.data.length - 2);
                    setCrossProducts(result.data);
                }
            });
        // }

        // return () => {
        //     canceled = true;
        // };
    }, [selectedData, currency.code]);

    if (isLoading) {
        return <BlockLoader />;
    }

    // const breadcrumb = [
    //     { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: RedirectUrl.home() },
    //     {
    //         title: <FormattedMessage id={product.data.shop_name} defaultMessage={product.data.shop_name} />,
    //         url: `/stores/${product.data.shop_id}`,
    //     },
    //     { title: product.data.name, url: RedirectUrl.product(product.data) },
    // ];

    const related = <FormattedMessage id="relatedProducts" defaultMessage="Հարակից ապրանքներ" />;
    const similar = <FormattedMessage id="similarProducts" defaultMessage="Նմանատիպ ապրանքներ" />;

    let content = (
        <React.Fragment>
            <div className="block">
                <div className="container">
                    <Product
                        product={product}
                        layout={layout}
                        customer={customer}
                        productSlug={props.productSlug}
                        parentId={props.parentId}
                    />
                    {/* <ProductTabs /> */}
                </div>
            </div>
            {crossProducts.length > 0 && (
                <BlockProductsCarousel
                    customer={customer}
                    wbc={true}
                    title={"Նման  ապրանքներ"}
                    layout="grid-5"
                    id="realitiv.products"
                    products={crossProducts}
                />
            )}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product.name} — ${theme.name}`}</title>
            </Helmet>

            {/* <PageHeader breadcrumb={breadcrumb} /> */}

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProduct.defaultProps = {
    layout: "standard",
    sidebarPosition: "start",
};

export default ShopPageProduct;
