// react
import React, { Component } from 'react'

// third-party
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Link, Redirect } from 'react-router-dom'

// application
import Collapse from '../shared/Collapse'
import Currency from '../shared/Currency'
import PageHeader from '../shared/PageHeader'
import { Check9x7Svg } from '../../svg'
import { cartRemoveAllItems } from '../../store/cart'
import { setRewards } from '../../store/customer'

// data stubs
import payments from '../../data/shopPayments'
import theme from '../../data/theme'

import { FormattedMessage, injectIntl } from 'react-intl'

import { url } from '../../helper'



const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
)

const validateForm = (errors) => {
  let valid = true
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false),
  )
  return valid
}
const phonenumber = RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/)


//const url='https://megalight-api.fidem.am';

class ShopPageCheckout extends Component {
  payments = payments

  constructor(props) {
    super(props)
    this.state = {
      customer: this.props.customer,
      loading: false,
      checkbox: false,
      token: this.props.token,
      locale: this.props.locale,
      payment: 'cashondelivery',
      cart_token: this.props.cart_token,
      ShippingAddress: false,
      withBonus: false,
      notes: '',
      fullName: '',
      lname: '',
      name: '',
      street: '',
      shipingStreet: '',
      shipingPhone: '',
      phone: '',
      appartment: '',
      email: '',
      termsPageUrl: '',
      errors: {
        fullName: '',
        name: '',
        street: '',
        shipingStreet: '',
        appartment: '',
        shipingPhone: '',
        phone: '',
        email: '',
        checkboxCheck: '',
      },
      newArray: [],
      withShippingMethods:[],

      selectedShippingMethod:{}
    }
  }

  componentDidMount() {

    const id = this.props.match.path
    console.log('payments', payments)
    // console.log(this.state.payment)

    // console.log("id", id)

    fetch(`${url}/api/payments?locale=${this.state.locale}`)
      .then(res => res.json())
      .then(data => {
        this.setState({
          ...this.state,
          newArray: this.state.newArray.concat(data),
        })
      },
      )

    if (this.state.customer.token) {
      fetch(url + '/api/customer/get?token=' + this.state.customer.token)
        .then((responce) => responce.json())
        .then((res) => {
          const { phone, email, first_name: fullName, last_name: lname } = res.data
          this.setState({
            fullName,
            lname,
            phone,
            email,
          })
        })
        .catch((err) => console.error(err))
    }
    fetch(`${url}/api/shippings?locale=${this.state.locale}`)
    .then(res => res.json())
    .then(data => {
      this.setState({
        ...this.state,
        withShippingMethods:[...data],
        selectedShippingMethod:data.length ? data[0] : {}
      })
    },
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.locale !== this.state.locale) {
      fetch(`${url}/api/payments?locale=${this.state.locale}`)
        .then(res => res.json())
        .then(data => {
          this.setState({
            ...this.state,
            newArray: this.state.newArray.concat(data),
          })
        },
        )
    }
  }

  handlePaymentChange = (event) => {
    if (event.target.checked) {
      this.setState({ payment: event.target.value })
    }
  }

  renderTotals() {
    const { cart } = this.props
    if (cart.extraLines.length <= 0) {
      return null
    }

    const extraLines = cart.extraLines.map((extraLine, index) => (
      <tr key={index}>
        <th>{extraLine.title}</th>
        <td>
          <Currency value={extraLine.price} symbol="am" />
        </td>
      </tr>
    ))

    return (
      <React.Fragment>
        <tbody className="checkout__totals-subtotals">
          <tr>
            <th>Subtotal</th>
            <td>

              <Currency value={cart.subtotal} symbol="am" />
            </td>
          </tr>
          {extraLines}
        </tbody>
      </React.Fragment>
    )
  }

  renderCart() {
    const { cart } = this.props
    // console.log(this.props)
    const items = cart.items.map((item) => {
      return (
        <tr key={item.id}>
          <td>{`${item.product.name} × ${item.quantity}`}</td>
          <td>
            <Currency value={item.total} symbol="am" />{' '}
          </td>
        </tr>
      )
    })

    return (
      <table className="checkout__totals">
        <thead className="checkout__totals-header">
          <tr>
            <th>
              <FormattedMessage id="product" defaultMessage="Ապրանք" />
            </th>
            <th>
              <FormattedMessage id="total" defaultMessage="Ընդհանուր" />
            </th>
          </tr>
        </thead>
        <tbody className="checkout__totals-products">
          {items}
          {Number(this.state.selectedShippingMethod.rate) > 0 && <tr>

              <td>{this.state.selectedShippingMethod.name}</td>
               <td>
            <Currency value={this.state.selectedShippingMethod.rate} symbol="am" />{' '}
          </td>

          </tr> }
        </tbody>
        {/* {this.renderTotals()} */}
        <tfoot className="checkout__totals-footer">
          <tr>
            <th>
              <FormattedMessage id="total" defaultMessage="Ընդհանուր" />
            </th>
            <th>
              {' '}
              
              <Currency value={cart.subtotal + (Number(this.state.selectedShippingMethod.rate) > 0 ? Number(this.state.selectedShippingMethod.rate) : 0)} symbol="am" />
              {/*{console.log("cart.subtotal", cart.subtotal)}*/}
            </th>
          </tr>
          {this.state.withBonus && (
            <tr>
              <th>
                <FormattedMessage id="with.bonus" defaultMessage="With bonus" />
              </th>
              <th>
                <p>{/* <Currency value={global_total - this.state.customer.rewards} /> */}</p>
              </th>
            </tr>
          )}
          {/*{this.state.customer.token && (*/}
          {/*    <tr>*/}
          {/*        <td className="use-bonus-tr">*/}
          {/*                /!*<span className="input-check__body">*!/*/}
          {/*                /!*    <input*!/*/}
          {/*                /!*        className="input-check__input"*!/*/}
          {/*                /!*        type="checkbox"*!/*/}
          {/*                /!*        id="bonuses"*!/*/}
          {/*                /!*        onClick={() => this.setState({ withBonus: !this.state.withBonus })}*!/*/}
          {/*                /!*    />*!/*/}
          {/*                /!*    <span className="input-check__box" />*!/*/}
          {/*                /!*    <Check9x7Svg className="input-check__icon" />*!/*/}
          {/*                /!*</span>*!/*/}
          {/*            /!*<span>*!/*/}
          {/*            /!*        <FormattedMessage*!/*/}
          {/*            /!*            id="do.use.bonuses"*!/*/}
          {/*            /!*            defaultMessage="Do you want to use your bonuses"*!/*/}
          {/*            /!*        />*!/*/}
          {/*            /!*    </span>*!/*/}
          {/*        </td>*/}
          {/*    </tr>*/}
          {/*)}*/}
        </tfoot>
      </table>
    )
  }

  renderPaymentsList() {
    const { payment: currentPayment, newArray: paymentListData } = this.state

    const payments = paymentListData.length > 0 && paymentListData.map((payment) => {
      const renderPayment = ({ setItemRef, setContentRef }) => (
        <li className="payment-methods__item" ref={setItemRef}>
          <label className="payment-methods__item-header">
            <span className="payment-methods__item-radio input-radio">
              <span className="input-radio__body">
                <input
                  type="radio"
                  className="input-radio__input"
                  name="checkout_payment_method"
                  value={payment.method}
                  checked={currentPayment === payment.method}
                  onChange={this.handlePaymentChange}
                />

                <span className="input-radio__circle" />
              </span>
            </span>
            <span className="payment-methods__item-title">
              {payment.title}
            </span>
          </label>
          <div className="payment-methods__item-container" ref={setContentRef}>
            <img
              style={{
                margin: 'auto',
                display: 'block',
                width: '200px',
              }}
              src={payment.img}
              alt=""
            />
          </div>
        </li>
      )

      return (

        <Collapse
          key={payment.key}
          open={currentPayment === payment.key}
          toggleClass="payment-methods__item--active"
          render={renderPayment}
        />
      )
    })

    return (
      <div className="payment-methods">
        <ul className="payment-methods__list">{payments}</ul>
      </div>
    )
  }

  openAddress() {
    this.setState({ ShippingAddress: !this.state.ShippingAddress })
  }

  handleChange = (event) => {
    event.preventDefault()

    const { name, value } = event.target

    let errors = this.state.errors
    this.setState({ [name]: value })
  }

  onclick = (event) => {
    this.setState({ checkbox: !this.state.checkbox })
  }

  chacking = () => {
    const errors = this.state.errors

    errors.fullName = this.state.fullName === '' ? 'Full Name must be 3 characters long!' : ''

    errors.email = validEmailRegex.test(this.state.email) ? '' : 'Email is not valid!'

    errors.street = this.state.street === '' ? 'Full Name must be 5 characters long!' : ''

    errors.checkboxCheck = this.state.checkbox === false ? 'This field is required' : ''

    errors.phone = phonenumber.test(this.state.phone) ? '' : 'Phone is not valid!'

    if (this.state.ShippingAddress) {
      errors.name = this.state.name < 5 ? 'Full Name must be 3 characters long!' : ''
      errors.shipingStreet = this.state.shipingStreet.length < 5 ? 'Street must be 5 characters long!' : ''

      errors.shipingPhone = this.state.shipingStreet.length < 5 ? 'Street must be 5 characters long!' : ''
      this.setState({ errors })
    }
    this.setState({ errors })
  }

  destroyCart() {
    let local = localStorage.getItem('state')
    let obj = JSON.parse(local)
    delete obj.cart

    localStorage.setItem('state', JSON.stringify(obj))
  }

  requestOrder() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    let shippingDetalies, options, options_payment, save_shipping
    if (this.state.ShippingAddress) {
      shippingDetalies = {
        address1: this.state.shipingStreet,
        name: this.state.name,
        phone: this.state.shipingPhone,
      }
    }

    const billing = {
      use_for_shipping: true,
      save_as_address: true,
      address1: [this.state.street],
      email: this.state.email,
      first_name: this.state.fullName,
      last_name: this.state.lname,
      city: 'Yerevan',
      country: 'AM',
      state: 'Yerevan',
      postcode: '75017',
      phone: this.state.phone,
      company_name: '',
      additional: this.state.notes,
    }

    const shipping = {
      address1: [this.state.shipingStreet],
      name: this.state.name,
      phone: this.state.shipingPhone,
    }

    if (this.state.customer.token) {
      options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          billing: billing,
          shipping: shipping,
          api_token: this.state.token.cartToken,
          token: this.state.customer.token,
        }),
      }
      options_payment = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          payment: { method: this.state.payment },
          api_token: this.state.token.cartToken,
          token: this.state.customer.token,
        }),
      }

      save_shipping = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          shipping_method: this.state.selectedShippingMethod.code,
          api_token: this.state.token.cartToken,
          token: this.state.customer.token,
        }),
      }
    } else {
      options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          billing: billing,
          shipping: shipping,
          api_token: this.state.token.cartToken,
        }),
      }
      options_payment = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          payment: { method: this.state.payment },
          api_token: this.state.token.cartToken,
        }),
      }

      save_shipping = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          shipping_method: this.state.selectedShippingMethod.code,
          api_token: this.state.token.cartToken,
        }),
      }
    }

    const { cart_token, customer } = this.state
    if (this.state.customer.token && customer.rewards > 0) {
      fetch(url + `/api/use/rewards?token=${this.state.customer.token}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    }

    fetch(
      url +
      `/api/checkout/save-address?api_token=${cart_token}${this.state.customer.token ? `&token=${this.state.customer.token}` : ''
      } `,
      options,
    )
      .then((response) => {
        if (response.ok) {
          fetch(
            url +
            `/api/checkout/save-shipping${this.state.customer.token ? `?token=${this.state.customer.token}` : ''
            } `,
            save_shipping,
          )
            .then((res) => {
              if (res.ok) {
                fetch(url + '/api/checkout/save-payment', options_payment)
                  .then((rsponce) => {
                    if (rsponce.ok) {
                      let body
                      if (this.state.customer.token) {
                        // console.log(description)
                        body = {
                          api_token: cart_token,
                          token: this.state.customer.token,
                          description: this.state.notes,
                        }
                      } else {
                        body = {
                          api_token: cart_token,
                          description: this.state.notes,
                        }
                      }

                      fetch(url + '/api/checkout/save-order', {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(body),
                      })
                        .then((res) => res.json())
                        .then((res) => {
                          if (res.success) {
                            if (this.state.customer.token) {
                              fetch(
                                url +
                                '/api/customer/get?token=' +
                                this.state.customer.token,
                              )
                                .then((responce) => responce.json())
                                .then((res) => {
                                  if (res) {
                                    /// test with Ruben
                                    this.props.setRewards(res.data.rewards)
                                  }
                                })
                                .catch((err) => console.error(err))
                            }

                            // const dispatch = useDispatch();
                            // dispatch(this.props.removeAllItems());
                            // removeAllItems

                            // dispatch({ type: "WISH_LIST", payload: arr });

                            if (typeof res.redirect_url == 'undefined') {
                              this.props.cartRemoveAllItems()
                              // this.destroyCart();
                              window.location = '/thanks?orderID=' + res.order.id
                              window.fbq('track', 'OrderPlaced');

                            }

                            if (
                              res.redirect_url.backURL !=
                              'https://money.idram.am/payment.aspx'
                            ) {
                              if (res.redirect_url) {
                                // this.destroyCart();
                                this.props.cartRemoveAllItems()

                                window.location = res.redirect_url
                              } else {
                                // this.destroyCart();
                                this.props.cartRemoveAllItems()

                                window.location = '/thanks?orderID=' + res.order.id
                              }
                            } else {
                              this.setState({
                                recAccount: res.redirect_url.EDP_REC_ACCOUNT,
                              })
                              this.setState({ billNo: res.redirect_url.EDP_BILL_NO })
                              this.setState({ amount: res.redirect_url.Amount })
                            
                              // console.log("url", url)
                              /* let url = `https://banking.idram.am/Payment/GetPayment?EDP_REC_ACCOUNT=${
                                 res.redirect_url.EDP_REC_ACCOUNT
                               }&EDP_AMOUNT=${+res.redirect_url.Amountl}&EDP_BILL_NO=${
                                 res.redirect_url.EDP_BILL_NO
                               }`;*/

                              document.getElementById('idramSubmit').click()

                              console.log(`https://banking.idram.am/Payment/GetPayment?EDP_REC_ACCOUNT=${res.redirect_url.EDP_REC_ACCOUNT
                                }&EDP_AMOUNT=${this.state.amount}&EDP_BILL_NO=${res.redirect_url.EDP_BILL_NO
                                } `,)
                              window.location = `https://banking.idram.am/Payment/GetPayment?EDP_REC_ACCOUNT=${res.redirect_url.EDP_REC_ACCOUNT
                                }&EDP_AMOUNT=${this.state.amount}&EDP_BILL_NO=${res.redirect_url.EDP_BILL_NO
                                } `
                              // console.log("cvnbdjkhgkaksjgah",
                              //   this.setState({ amount: res.redirect_url.Amount })
                              // );

                              /* document.getElementById("idramSubmit").click();
                               window.location = `https://banking.idram.am/Payment/GetPayment?EDP_REC_ACCOUNT=${
                                 res.redirect_url.EDP_REC_ACCOUNT
                               }&EDP_AMOUNT=${+res.redirect_url.Amountl}&EDP_BILL_NO=${
                                 res.redirect_url.EDP_BILL_NO
                               }`;
                               console.log(
                                 this.setState({ amount: res.redirect_url.Amount })
                               );*/
                            }
                          }
                        })
                        .catch((err) => console.log(err, 'err'))
                    }
                  })
                  .then((res) => {
                    // redirect to thankyou page
                    // return <Redirect to="/thanks" />;

                    // console.log(res, "Payment Done");
                  })
              }
            })
            .catch((err) => console.error(err))
        }
      })
      .catch((err) => console.log(err, 'err'))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ loading: true })
    this.chacking()
    if (validateForm(this.state.errors)) {
      this.requestOrder()
    } else {
      this.setState({ loading: false })
    }
  }

  clicking = () => {
    document.getElementById('idramSubmit').click()
  }

  render() {
    const { cart } = this.props
    const { errors } = this.state
    const { locale } = this.props

    if (cart.items.length < 1) {
      return <Redirect to="cart" />
    }

    const breadcrumb = [
      { title: 'Home', url: '' },
      { title: 'Shopping Cart', url: '/shop/cart' },
      { title: 'Checkout', url: '' },
    ]

    console.log(this.state.selectedShippingMethod);
    // console.log(this.state.newArray, '59595959')
    return (
      <React.Fragment>
        <PageHeader />
        <form className="idramform" action="https://banking.idram.am/Payment/GetPayment" method="POST">
          <input type="hidden" name="EDP_LANGUAGE" value="EN" />
          <input type="hidden" name="EDP_REC_ACCOUNT" value={this.state.recAccount} />
          {/* <input type="hidden" name="EDP_DESCRIPTION" value={items} /> */}
          <input type="hidden" name="EDP_AMOUNT" value={this.state.amount} />
          <input type="hidden" name="EDP_BILL_NO" value={this.state.billNo} />
          <input id="idramSubmit" type="submit" value="submit" style={{ opacity: '0' }} />
        </form>
        {this.state.recAccount ? this.clicking() : ''}

        <div className="checkout block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0">
                  <form>
                    <div className="card-body">
                      <h3 className="card-title">
                        <FormattedMessage id="orderhistoryadd" defaultMessage="Առաքման հասցե" />
                      </h3>

                      <div className="form-group ">
                        <FormattedMessage id="name" defaultMessage="Անուն">
                          {(placeholder) => (
                            <input
                              onChange={this.handleChange}
                              value={this.state.fullName}
                              type="text"
                              name="fullName"
                              className="form-control"
                              id="checkout-first-name"
                              placeholder={placeholder}
                            />
                          )}
                        </FormattedMessage>
                        {errors.fullName.length > 0 && (
                          <span className="alert-danger">
                            {' '}
                            <FormattedMessage
                              id="name.error"
                              defaultMessage="Անուն դաշտը պարտադիր է"
                            />
                          </span>
                        )}
                      </div>

                      <div className="form-group ">
                        <FormattedMessage id="lname" defaultMessage="Ազգանուն">
                          {(placeholder) => (
                            <input
                              onChange={this.handleChange}
                              value={this.state.lname}
                              type="text"
                              name="lname"
                              className="form-control"
                              id="checkout-first-name"
                              placeholder={placeholder}
                            />
                          )}
                        </FormattedMessage>
                        {errors.fullName.length > 0 && (
                          <span className="alert-danger">
                            {' '}
                            <FormattedMessage
                              id="errors.lname"
                              defaultMessage="Ազգանուն դաշտը պարտադիր է"
                            />
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <FormattedMessage id="account.address" defaultMessage="Հասցե">
                          {(placeholder) => (
                            <input
                              onChange={this.handleChange}
                              value={this.state.street}
                              name="street"
                              type="text"
                              className="form-control"
                              id="checkout-street"
                              placeholder={placeholder}
                            />
                          )}
                        </FormattedMessage>

                        {errors.street.length > 0 && (
                          <span className="alert-danger">
                            <FormattedMessage
                              id="address.error"
                              defaultMessage="Հասցե դաշտը պարտադիր է"
                            />
                          </span>
                        )}
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <FormattedMessage id="topbar.email" defaultMessage="էլ․ Հասցե">
                            {(placeholder) => (
                              <input
                                value={this.state.email}
                                onChange={this.handleChange}
                                type="email"
                                name="email"
                                className="form-control"
                                id="checkout-email"
                                placeholder={placeholder}
                              />
                            )}
                          </FormattedMessage>
                          {errors.email.length > 0 && (
                            <span className="alert-danger">
                              {' '}
                              <FormattedMessage
                                id="email.error"
                                defaultMessage="էլ․ Հասցե պարտադիր է"
                              />
                            </span>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <FormattedMessage id="reg.phone" defaultMessage="Հեռախոս">
                            {(placeholder) => (
                              <input
                                onChange={this.handleChange}
                                value={this.state.phone}
                                name="phone"
                                type="text"
                                className="form-control"
                                id="checkout-phone"
                                placeholder={placeholder}
                              />
                            )}
                          </FormattedMessage>
                          {errors.phone.length > 0 && (
                            <span className="alert-danger">
                              <FormattedMessage
                                id="phone.error"
                                defaultMessage="Հեռ։  պարտադիր է"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>

                  {/*<div className="card-divider" />*/}
                  <div className="card-body">
                    {/*<h3 className="card-title check-outTilye-fms">*/}
                    {/*    <FormattedMessage id="orderhistoryadd" defaultMessage="Առաքման հասցե" />*/}
                    {/*</h3>*/}

                    <div className="form-group">
                      {/*<div className="form-check">*/}
                      {/*    <span*/}
                      {/*        onClick={this.openAddress.bind(this)}*/}
                      {/*        className="form-check-input input-check"*/}
                      {/*    >*/}
                      {/*        <span className="input-check__body">*/}
                      {/*            <input*/}
                      {/*                className="input-check__input"*/}
                      {/*                type="checkbox"*/}
                      {/*                id="checkout-different-address"*/}
                      {/*            />*/}

                      {/*            <span className="input-check__box" />*/}
                      {/*            <Check9x7Svg className="input-check__icon" />*/}
                      {/*        </span>*/}
                      {/*    </span>*/}
                      {/*    <label>*/}
                      {/*        <FormattedMessage*/}
                      {/*            id="chekout.shippingDifferen"*/}
                      {/*            defaultMessage="Ուղարկե՞լ այլ հասցե:"*/}
                      {/*        />*/}
                      {/*    </label>*/}
                      {/*</div>*/}
                    </div>

                    {this.state.ShippingAddress ? (
                      <>
                        <div className="form-group ">
                          <FormattedMessage id="name" defaultMessage="Անուն">
                            {(placeholder) => (
                              <input
                                onChange={this.handleChange}
                                type="text"
                                name="name"
                                value={this.state.name}
                                className="form-control"
                                id="checkout-first-name"
                                placeholder={placeholder}
                              />
                            )}
                          </FormattedMessage>

                          {errors.name.length > 0 && (
                            <span className="alert-danger">
                              {' '}
                              <FormattedMessage
                                id="name.error"
                                defaultMessage="Անուն դաշտը պարտադիր է"
                              />
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <FormattedMessage id="account.address" defaultMessage="Հասցե">
                            {(placeholder) => (
                              <input
                                onChange={this.handleChange}
                                type="text"
                                name="shipingStreet"
                                value={this.state.shipingStreet}
                                className="form-control"
                                id="checkout-street-address"
                                placeholder={placeholder}
                              />
                            )}
                          </FormattedMessage>

                          {errors.shipingStreet.length > 0 && (
                            <span className="alert-danger">
                              <FormattedMessage
                                id="shipingStreet.error"
                                defaultMessage="Առաքման հասցե պարտադիր է"
                              />
                            </span>
                          )}
                        </div>

                        <div className="form-group  ">
                          <FormattedMessage id="reg.phone" defaultMessage="Հեռախոս">
                            {(placeholder) => (
                              <input
                                onChange={this.handleChange}
                                value={this.state.shipingPhone}
                                name="shipingPhone"
                                type="text"
                                className="form-control"
                                id="checkout-phone"
                                placeholder={placeholder}
                              />
                            )}
                          </FormattedMessage>
                          {errors.shipingPhone.length > 0 && (
                            <span className="alert-danger">
                              <FormattedMessage
                                id="phone.error"
                                defaultMessage="Հեռ։  պարտադիր է"
                              />
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}

                    <div className='shipping-methods'>
                        {this.state.withShippingMethods.map(item => {
                          return <>
                          <div>
                            <div>
                            <input type="radio" name={item.name} checked={this.state.selectedShippingMethod.code === item.code} onChange={(e)=> {this.setState({selectedShippingMethod:item})}} />  <span>{item.name} </span>
                            </div>
                            <small>{item.description}</small>
                          
                          </div>
                          </>
                        })}
                    </div>

                    <div className="form-group">
                      <label htmlFor="checkout-comment">
                        <FormattedMessage
                          id="checkout.notes"
                          defaultMessage="Պատվերի նշումներ (ըստ ցանկության)"
                        />
                      </label>
                      <textarea
                        onChange={this.handleChange}
                        name="notes"
                        id="checkout-comment"
                        className="form-control"
                        rows="4"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                <div className="card mb-0">
                  <div className="card-body">
                    <h3 className="card-title">
                      {' '}
                      <FormattedMessage id="your.order" defaultMessage="Ձեր Պատվերը" />
                    </h3>

                    {this.renderCart()}

                    {this.renderPaymentsList()}

                    <div className="checkout__agree form-group">
                      <div className="form-check">
                        <span className="form-check-input input-check">
                          <span className="input-check__body">
                            <input
                              onClick={this.onclick}
                              className="input-check__input"
                              type="checkbox"
                              id="checkout-terms"
                            />
                            <span className="input-check__box" />
                            <Check9x7Svg className="input-check__icon" />
                          </span>
                        </span>

                        <label className="form-check-label" htmlFor="checkout-terms">
                          <Link to={`page/18`} target="_blank">
                            <FormattedMessage
                              id="order.term"
                              defaultMessage="Ես կարդացել և համաձայն եմ դրանց հետևյալ կայքերի և պայմանների հետ"
                            />
                          </Link>
                          *
                        </label>
                        {errors.checkboxCheck.length > 0 && (
                          <span className="alert-danger">
                            <FormattedMessage
                              id="a"
                              defaultMessage={errors.checkboxCheck}
                            />
                          </span>
                        )}
                      </div>
                    </div>

                    <button
                      onClick={this.handleSubmit}
                      type="submit"
                      className={
                        !this.state.loading ? 'btn btn-primary' : 'btn btn-primary btn-loading'
                      }
                    >
                      <FormattedMessage id="order.button" defaultMessage="Վճարել" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state) => ({
  cart: state.cart,
  locale: state.locale,
  token: state.cartToken,
  customer: state.customer,
  cart_token: state.cartToken.cartToken,
})

const mapDispatchToProps = {
  cartRemoveAllItems,
  setRewards,
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout))
