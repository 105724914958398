// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
// data stubs
import theme from "../../data/theme";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { url } from "../../helper";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { setName } from "../../store/customer";
function AccountPageProfile(props) {
    const customer = useSelector((state) => state.customer);
    const [address, setAddress] = useState();
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState(false);
    const [successData, setSuucessData] = useState();
    const [input, setInput] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        const abortController = new AbortController();
        const single = abortController.single;
        if (customer.token) {
            fetch(url + "/api/customer/get?token=" + customer.token, { single: single })
                .then((responce) => responce.json())
                .then((res) => {
                    if (res) {
                        setAddress(res.data);
                        props.setName(res.data.first_name);
                    }
                })
                .catch((err) => console.error(err));
        }

        return function cleaup() {
            abortController.abort();
        };
    }, [customer.token]);

    if (!address) {
        return "";
    }
    const handleClick = (event) => {
        event.preventDefault();
        setLoading(true);
        let option = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                token: customer.token,
                first_name: input.first_name !== undefined ? input.first_name : address.first_name,
                last_name: input.last_name !== undefined ? input.last_name : address.last_name,
                email: input.email !== undefined ? input.email : address.email,
                password: input.password,
                password_confirmation: input.password_confirmation,
                gender: input.gender !== undefined ? input.gender : address.gender,
                phone: input.phone !== undefined ? input.phone : address.phone,
                date_of_birth: null,
            }),
        };

        fetch(`${url}/api/customer/profile`, option)
            .then((response) => response.json())
            .then((res) => {
                setSuucessData(res.message);
                setLoading(false);
                if (res.errors) {
                    setErrors(res.errors);
                } else {
                    setSuccess(true);
                }
            });
    };

    const ErrorsOutput = () => {
        const arr = [];
        for (let error in errors) {
            arr.push(<div>{errors[error]}</div>);
        }
        return arr;
    };
    // if (!address) {
    //     return null;
    // }
    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>

            {errors ? <div className="alert alert-danger">{ErrorsOutput()}</div> : ""}

            {success ? <div className="alert alert-success">{successData}</div> : ""}

            <div className="card-body  card-body_fms">
                <div className="row no-gutters">
                    <div className="col-6 col-lg-7 col-xl-6 pr-3">
                        <div className="form-group form-group-fms">
                            <label htmlFor="profile-first-name">
                                <FormattedMessage id="name" defaultMessage="Անուն" />
                            </label>

                            <input
                                id="profile-first-name"
                                name="first_name"
                                onChange={handleChange}
                                defaultValue={address ? address.first_name : ""}
                                value={input.first_name}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group form-group-fms">
                            <label htmlFor="profile-last-name">
                                <FormattedMessage id="lname" defaultMessage="Ազգանուն" />{" "}
                            </label>
                            <input
                                id="profile-last-name"
                                name="last_name"
                                onChange={handleChange}
                                defaultValue={address ? address.last_name : ""}
                                value={input.last_name}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        {/*<div className="form-group">*/}
                        {/*    <label htmlFor="password-current"><FormattedMessage id="currentPassword" defaultMessage="Ընթացիկ գաղտնաբառ" /></label>*/}
                        {/*    <input*/}
                        {/*        onChange={handleChange}*/}
                        {/*        value={input.password}*/}
                        {/*        name='password'*/}
                        {/*        type="password"*/}
                        {/*        className="form-control"*/}
                        {/*        id="password-current"*/}

                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="form-group">*/}
                        {/*    <label htmlFor="password-new"><FormattedMessage id="passwordConfirmation" defaultMessage="Գաղտնաբառի հաստատում" /></label>*/}
                        {/*    <input*/}
                        {/*        onChange={handleChange}*/}
                        {/*        value={input.password_confirmation}*/}
                        {/*        name='password_confirmation'*/}
                        {/*        type="password"*/}
                        {/*        className="form-control"*/}
                        {/*        id="password-new"*/}

                        {/*    />*/}
                        {/*</div>*/}
                    </div>

                    <div className="col-6 col-lg-7 col-xl-6 pl-3">
                        <div className="form-group form-group-fms">
                            <label htmlFor="profile-email">
                                <FormattedMessage id="topbar.email" defaultMessage="Էլ․ Հասցե" />
                            </label>
                            <input
                                onChange={handleChange}
                                name="email"
                                defaultValue={address ? address.email : ""}
                                value={input.email}
                                id="profile-email"
                                type="email"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group form-group-fms">
                            <label htmlFor="profile-phone">
                                <FormattedMessage id="reg.phone" defaultMessage="Հեռ։" />
                            </label>
                            <input
                                onChange={handleChange}
                                defaultValue={address ? address.phone : ""}
                                value={input.phone}
                                name="phone"
                                id="profile-phone"
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group mt-5 text-right">
                            <button
                                onClick={handleClick}
                                type="button"
                                className={!loading ? "btn btn-primary" : "btn btn-primary btn-loading"}
                            >
                                <FormattedMessage id="save" defaultMessage="Պահպանել" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    setName,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageProfile);
