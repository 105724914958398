import Visa                    from '../images/Visa.png'
// import { useEffect, useState } from 'react'



// export default function () {
//
//   const [payments, setPayments] = useState([])
//
//   useEffect()
//   {
//     fetch(`${url}/api/payments?locale=${this.state.locale}`)
//       .then(res => res.json())
//       .then(data => setPayments(res)
//   }
//
// }

export default [

  {
    key  : 'cashondelivery',
    title: {
      en: 'Cash on delivery',
      hy: 'Վճարել տեղում',
    },
    img  : '',
  },
  {
    key  : 'ameria_vpos',
    title: {
      en: 'Idram',
      hy: 'Իդրամ',
    },
    img  : '',
  },
]
