// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";

// application
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import languages from "../../i18n";
import NavMenu from "./NavMenu";
import { ArrowRoundedDown9x6Svg } from "../../svg";
// data stubs
// import navLinks from '../../data/headerNavigation';

import { url } from "../../helper";

function NavLinks(props) {
    const selectedData = useSelector((state) => state.locale);
    const [navLinks, SetNavLinks] = useState();
    let linksList = "";

    useEffect(() => {
        fetch("https://api.marketin.am/api/cms/pages")
            .then((response) => response.json())
            .then((res) => {
                SetNavLinks(res);
            });
    }, []);

    const handleMouseEnter = (event) => {
        const { locale } = props;
        const { direction } = languages[locale];

        const item = event.currentTarget;
        const megamenu = item.querySelector(".nav-links__megamenu");
        const linksList = "";
        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            if (direction === "rtl") {
                const itemPosition = containerWidth - (itemOffsetLeft + item.getBoundingClientRect().width);

                const megamenuPosition = Math.round(Math.min(itemPosition, containerWidth - megamenuWidth));

                megamenu.style.left = "";
                megamenu.style.right = `${megamenuPosition}px`;
            } else {
                const megamenuPosition = Math.round(Math.min(itemOffsetLeft, containerWidth - megamenuWidth));

                megamenu.style.right = "";
                megamenu.style.left = `${megamenuPosition}px`;
            }
        }
    };

    if (navLinks) {
        linksList = navLinks.map((item, index) => {
            let arrow;
            let submenu;
            // eslint-disable-next-line max-len
            // const pageUrl = item.pages.find((it) => it.locale === selectedData && item.page_id === it.cms_page_id);
            if (item.data && item.data.length > 0) {
                arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
            }

            if (item.data && item.data.length > 0) {
                submenu = (
                    <div className="nav-links__menu">
                        <NavMenu items={item.data} />
                    </div>
                );
            }

            const classes = classNames("nav-links__item", {
                "nav-links__item--with-submenu": item.data,
            });

            // const hello = (event, pageUrl) => {
            //     if (pageUrl.url_key === 'help') {
            //         event.preventDefault();
            //     }
            // };

            return (
                /** Chackoing if object has pageID  then url to pages/? else custom url * */
                <li key={index} className={classes} onMouseEnter={handleMouseEnter}>
                    {item.page_id ? (
                        // <Link to={`/page/${pageUrl.url_key}`} onClick={(event) => hello(event, pageUrl)}>
                        <Link to={`${item.links.first}`}>
                            <span>
                                {item.translations.find((item) => item.locale === selectedData).name}
                                {arrow}
                            </span>
                        </Link>
                    ) : item.translations.find((item) => item.locale === "en").name === "Blog" ? (
                        <Link to="/blog">
                            <span>
                                {item.translations.find((item) => item.locale === selectedData).name}
                                {arrow}
                            </span>
                        </Link>
                    ) : (
                        <Link to={`/page/${item["custom-url"]}`}>
                            <span>
                                {item.translations.find((item) => item.locale === selectedData).name}
                                {arrow}
                            </span>
                        </Link>
                    )}

                    {/* <AppLink  {...item.props}>
                        <span>
                        {item.translations.find( item =>item.locale===selectedData).name}
                            {arrow}
                        </span>
                    </AppLink> */}
                    {submenu}
                </li>
            );
        });
    }

    return (
        <ul className="nav-links__list">
            <li className="nav-links__item">
                <Link to="/shop/newCollection">
                    <span>
                        <FormattedMessage id="newcollection" defaultMessage="New Collection" />
                    </span>
                </Link>
            </li>
            <li className="nav-links__item">
                <Link to="/shop/saledProducts">
                    <span>
                        <FormattedMessage id="saledProducts" defaultMessage="Saled Products" />
                    </span>
                </Link>
            </li>
            {linksList}
        </ul>
    );
}

NavLinks.propTypes = {
    /** current locale */
    //  locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks);
