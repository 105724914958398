// react
import React, { Component } from 'react'

// application
import { FormattedMessage }       from 'react-intl'
import departmentsArea            from '../../services/departmentsArea'
import DepartmentsLinks           from './DepartmentsLinks'
import { Menu18x14Svg }           from '../../svg'
import { url }                    from '../../helper'
import { CategoryActionCreators } from '../../store/categories'
import { connect }                from 'react-redux'



class Departments extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dep     : [],
      open    : false,
      fixed   : false,
      area    : null,
      fakeData: [],
    }
  }

  componentDidMount () {
    fetch(`${url}/api/root-categories?locale=${this.props.locale}`)
      .then(res => res.json())
      .then(data => {
        this.setState({
          fakeData: data.categories,
        })
        // window.localStorage.setItem("categories")
        this.props.addCategory(data.categories)
      })

    // console.log('localstorage', window.localStorage.getItem('categories'))

    if (this.state.dep.length === 0) {

      fetch(`${url}/api/categories?locale=${this.props.locale}`)
        .then((response) => response.json())
        .then((res) => {
          // console.log('true data', res)

          this.setState({
            dep: res.categories,
          })
          this.props.addCategory(res.categories)
        })

    } else {
      this.setState({
        dep: this.props.categories.categories,
      })
    }
    document.addEventListener('mousedown', this.handleOutsideClick)

    this.unsubscribeAria = departmentsArea.subscribe((area) => {
      this.setState({
        fixed: !!area,
        area,
      })
    })

    this.setState({
      fixed: !!departmentsArea.area,
      area : departmentsArea.area,
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { fixed, area, open } = this.state

    if (window.location.pathname === '/') {
      const root = this.rootRef
      const content = root.querySelector('.departments__links-wrapper')

      root.classList.remove('departments--transition')
      root.classList.add('departments--fixed', 'departments--opened')
    } else if (prevState.fixed !== fixed) {
      const root = this.rootRef
      const content = root.querySelector('.departments__links-wrapper')

      if (fixed) {
        const areaRect = area.getBoundingClientRect()
        const areaBottom = areaRect.top + areaRect.height + window.scrollY

        root.classList.remove('departments--transition')
        root.classList.add('departments--fixed', 'departments--opened')

        const height = areaBottom - (content.getBoundingClientRect().top + window.scrollY)

        content.style.height = `${height}px`
        content.getBoundingClientRect() // force reflow
      } else {
        root.classList.remove('departments--opened', 'departments--fixed')
        content.style.height = ''
      }
    } else if (!fixed) {
      if (open) {
        const root = this.rootRef

        const content = root.querySelector('.departments__links-wrapper')
        content.getBoundingClientRect() // force reflow
        const startHeight = content.getBoundingClientRect().height

        root.classList.add('departments--transition', 'departments--opened')

        const endHeight = content.getBoundingClientRect().height

        content.style.height = `${startHeight}px`
        content.getBoundingClientRect() // force reflow
        content.style.height = `${endHeight}px`
      } else {
        const root = this.rootRef
        const content = root.querySelector('.departments__links-wrapper')
        const startHeight = content.getBoundingClientRect().height

        content.style.height = `${startHeight}px`

        root.classList.add('departments--transition')
        root.classList.remove('departments--opened')

        content.getBoundingClientRect() // force reflow
        content.style.height = ''
      }
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleOutsideClick)

    this.unsubscribeAria()
  }

  unsubscribeAria = () => {}

  setWrapperRef = (node) => {
    this.rootRef = node
  }

  handleOutsideClick = (event) => {
    if (this.rootRef && !this.rootRef.contains(event.target)) {
      this.setState(() => ({
        open: false,
      }))
    }
  }

  handleButtonClick = () => {
    if (window.location.pathname === '/') {
      this.setState((state) => ({
        open: true,
      }))
    }
    this.setState((state) => ({
      open: !state.open,
    }))
  }

  handleScroll = (e) => {
    // console.log(e, 'koko')
  }

  handleTransitionEnd = (event) => {
    if (this.rootRef && event.propertyName === 'height') {
      this.rootRef.querySelector('.departments__links-wrapper').style.height = ''
      this.rootRef.classList.remove('departments--transition')
    }
  }

  render () {
    return (
      <div className="departments" ref={this.setWrapperRef}>
        <div className="departments__links-wrapper" onTransitionEnd={this.handleTransitionEnd}>
          <DepartmentsLinks fDep={this.state.fakeData} dep={this.state.dep} func={this.handleButtonClick}/>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    locale    : state.locale,
    categories: state.categories,
  }
}
const mapDispatchToProps = {
  addCategory    : CategoryActionCreators.addCategory,
  addFakeCategory: CategoryActionCreators.addCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(Departments)
