// react
import React, { useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// application
import Collapse from "../shared/Collapse";
import { ArrowRoundedRight6x9Svg, IconArrowDropdownbgcSvg, IconArrowDropdownSvg } from "../../svg";
import BlockLoader from "../blocks/BlockLoader";
import { FormattedMessage } from "react-intl";

function WidgetCategories(props) {
    const { categories, location, customFunction, selectedFilters, to } = props;

    const [active, setActiv] = useState(null);
    const [open, setOpen] = useState(false);

    const openFunc = () => {
        setOpen(!open);
    };
    let categoriesList;
    if (categories[0]) {
        categoriesList = categories[0].children.map((category) => {
            const renderCategory = ({ toggle, setItemRef, setContentRef }) => {
                let expander;
                let children;

                if (category.children && category.children.length > 0) {
                    expander = (
                        <button
                            key={category.id}
                            className="widget-categories__expander"
                            type="button"
                            aria-label="Expand"
                            onClick={toggle}
                        >
                            {" "}
                            <IconArrowDropdownbgcSvg />
                        </button>
                    );

                    children = (
                        <div className="widget-categories__subs" ref={setContentRef}>
                            <ul>
                                {category.children.map((sub) => (
                                    <li key={sub.id}>
                                        <span
                                            className={
                                                active === sub.id && Object.keys(selectedFilters).length != 0
                                                    ? "fm-is-active"
                                                    : ""
                                            }
                                            onClick={() => {
                                                setActiv(sub.id);
                                                customFunction(sub.id);
                                            }}
                                        >
                                            <FormattedMessage
                                                id={sub.slug ? sub.slug : "womens-clothing"}
                                                defaultMessage={sub.name}
                                            />
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                }

                return (
                    <li className="widget-categories__item" ref={setItemRef}>
                        <div className="widget-categories__row">
                            <span
                                className={
                                    active === category.id && Object.keys(selectedFilters).length != 0
                                        ? "fm-is-active"
                                        : ""
                                }
                                onClick={() => {
                                    setActiv(category.id);
                                    customFunction(category.id);
                                }}
                            >
                                <FormattedMessage id={category.slug} defaultMessage={category.name} />
                            </span>
                            {expander}
                        </div>
                        {children}
                    </li>
                );
            };

            return <Collapse key={category.id} toggleClass="widget-categories__item--open" render={renderCategory} />;
        });
    } else {
        categoriesList = <BlockLoader />;
    }

    return (
        <div className={`widget-categories categories-box widget-categories--location--${location} widget`}>
            <h4 className="widget__title categories-title-fms">
                <FormattedMessage id="categories " defaultMessage="Categories" />
            </h4>
            <div className="card-divider"></div>
            <ul className="widget-categories__list">{categoriesList}</ul>
        </div>
    );
}

WidgetCategories.propTypes = {
    /**
     * category array
     */
    categories: PropTypes.array,
    /**
     * widget location (default: 'blog')
     * one of ['blog', 'shop']
     */
    location: PropTypes.oneOf(["blog", "shop"]),
};

WidgetCategories.defaultProps = {
    categories: [],
    location: "blog",
};

export default WidgetCategories;
