import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Menu from './Menu'
import { ArrowRoundedRight6x9Svg } from '../../svg'
import { FormattedMessage } from 'react-intl'



function DepartmentsLinks(props) {
  const selectedData = useSelector((state) => state.locale)
  const [offsetTop, setTop] = useState()
  const [left, setLeft] = useState()
  const [display, setDisplay] = useState('block')
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = (e) => {
      setDisplay('none')
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  const scrollHandle = (e) => {
    // setDisplay("none");
  }







  const onMouseEnterHandler = (e) => {
    setDisplay('block')

    const re = e.target.getBoundingClientRect()
    // const chCoord = e.target.nextSibling.getBoundingClientRect()

    // setTop(re.top - chCoord.height / 2)
    setLeft(re.left + e.target.offsetWidth)
  }


  // console.log('props', props)
  let linksList

  if (props.dep[0]) {
    linksList = props.dep[0].children.map((department, index) => {
      let arrow = null
      let submenu = null
      const itemClass = ''
      if (department.children && department.children.length > 0) {
        arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />
      }
      if (department.children && department.children.length > 0) {
        submenu = (
          <div
            key={index}
            style={{
              top: `${210}px`,
              left: `${left}px`,
              
            }}
            onScroll={scrollHandle}
            className={`departments__menu  ${display}`}
          >
            <Menu style={{minHeight: `${517}px`}} items={department.children} onClick={props.func} />
          </div>
        )
      }

      return (
        <li key={index} onMouseEnter={onMouseEnterHandler} className={`departments__item ${itemClass}`}>
          <Link to={`/shop/catalog/${department.slug}`} onClick={props.func}>
            <div className="department_name-fms">
              <FormattedMessage id={department.slug} defaultMessage={department.name} />
            </div>
            {arrow}
          </Link>
          {submenu}
        </li>
      )
    })
  } else {
    linksList = props.fDep.map((department, index) => {
      let arrow = null
      let submenu = null
      const itemClass = ''
      arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />
      return (
        <li key={index} onMouseEnter={onMouseEnterHandler} className={`departments__item ${itemClass}`}>
          <Link to={`/shop/catalog/${department.slug}`} onClick={props.func}>
            <div className="department_name-fms">
              <FormattedMessage id={department.slug} defaultMessage={department.name} />
            </div>
            {arrow}
          </Link>
          {submenu}
        </li>
      )
    })
  }

  return (
    <ul onScroll={scrollHandle} className="departments__links" id="AAA">
      {linksList}
    </ul>
  )
}


export default DepartmentsLinks
