// react
import React           from 'react'
import { useSelector } from 'react-redux'



const Currency = ({ value, symbol }) => {
  const currency = useSelector((state) => state.currency)

  const valueReplaced = currency?.code ? `${value} `.replace(/[^\d.]/g, '') : parseInt(value)
  const formatPrice = valueReplaced.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ',')
  const addCurrCode = currency?.symbol && symbol !== 'am' ? currency.symbol : '֏'

  return (
    <>
      {`${formatPrice} ${addCurrCode}`}
    </>
  )
}

export default Currency
