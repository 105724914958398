// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
import theme from "../../data/theme";
import { useEffect } from "react";
import { url } from "../../helper";
import { useSelector } from "react-redux";
import { useState } from "react";
import BlockLoader from "../blocks/BlockLoader";
import { FormattedMessage } from "react-intl";
import { IconArrowRoundBackSvg, OrderCardSvg } from "../../svg";

export default function AccountPageOrderDetails({ id }) {
    const customer = useSelector((state) => state.customer);
    const [order, setOrder] = useState();

    useEffect(() => {
        const abortController = new AbortController();
        const single = abortController.single;

        if (id.orderId && customer.token) {
            fetch(url + `/api/orders/${id.orderId}?token=` + customer.token, { single: single })
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.data) {
                        setOrder(res.data);
                    }
                })
                .catch((err) => console.error(err));
        }

        return function cleaup() {
            abortController.abort();
        };
    }, [id.orderId]);

    if (!order) {
        return <BlockLoader />;
    }
    const items = order.items.map((item, index) => (
        <div className={"order__details__sub"} key={index}>
            <span>{item.name}</span>
            <span>{item.qty_ordered}</span>
            <span>{item.formated_price}</span>
            <span className="whiteSpace">{order.created_at.substr(0, 10)}</span>
            <span>{order.status}</span>
        </div>
    ));

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>

            <div className="card">
                <span className={"order__number"}>
                    <FormattedMessage id="order" defaultMessage="Պատվեր" /> #{order.id}
                </span>
                <div className="order-header">
                    <div className={"order__details"}>
                        <span>
                            <FormattedMessage id="global.address" defaultMessage="Հասցե" />
                        </span>
                        <span>
                            <FormattedMessage id="termsofshipping" defaultMessage="Առաքման տեսակ" />
                        </span>
                        <span>
                            <FormattedMessage id="paymont.method" defaultMessage="Վճարման մեթոդ" />
                        </span>
                    </div>

                    <div className={"order__details__sub"}>
                        <span> {order.shipping_address.address1}</span>
                        <span>
                            <FormattedMessage id="free.delivory" defaultMessage="Անվճար" />
                        </span>

                        <span className="whiteSpace">
                            {order.payment_title !== "Credit card" ? order.payment_title : <OrderCardSvg />}
                        </span>
                    </div>
                </div>

                <div className="order-detailes-info">
                    <div className="table-responsive-sm  table-responsive-fm">
                        <div className="card-table table">
                            <div className={"order__details"}>
                                <span>
                                    <FormattedMessage id="product" defaultMessage="Ապրանք" />
                                </span>
                                <span>
                                    <FormattedMessage id="quantity" defaultMessage="Քանակ" />
                                </span>
                                <span>
                                    <FormattedMessage id="price" defaultMessage="Գին" />
                                </span>
                                <span>
                                    <FormattedMessage id="date" defaultMessage="Ընդհանուր" />
                                </span>
                                <span>
                                    <FormattedMessage id="status" defaultMessage="Ստատուս" />
                                </span>
                            </div>
                            {items}
                        </div>
                    </div>
                    <div className={"order__amount"}>
                        <Link to="/account/orders" className="btn order-btn-back btn-secondary">
                            <IconArrowRoundBackSvg
                                style={{
                                    marginRight: "12px",
                                }}
                            />{" "}
                            <FormattedMessage id="backToList" defaultMessage="Վերադառնալ" />
                        </Link>
                        <div>
                            <FormattedMessage id="total" defaultMessage="Ընդհանուր" />
                            <span
                                style={{
                                    color: "#E90101",
                                    paddingLeft: "10px",
                                }}
                            >
                                {order.formated_sub_total}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="row mt-3 no-gutters mx-n2">*/}
            {/*    <div className="col-sm-6 col-12 px-2">*/}
            {/*        <div className="card address-card address-card--featured">*/}
            {/*            <div className="address-card__body">*/}
            {/*                <div className="address-card__badge address-card__badge--muted"><FormattedMessage id="address" defaultMessage="Հասցե" /></div>*/}

            {/*                <div className="address-card__row">*/}
            {/*                   {order.shipping_address.address1[0]}*/}
            {/*                </div>*/}
            {/*                <div className="address-card__row">*/}
            {/*                    <div className="address-card__row-title"><FormattedMessage id="phone" defaultMessage="Հեռ։" /></div>*/}
            {/*                    <div className="address-card__row-content">{order.shipping_address.phone}</div>*/}
            {/*                </div>*/}
            {/*                <div className="address-card__row">*/}
            {/*                    <div className="address-card__row-title"><FormattedMessage id="email" defaultMessage="Էլ․ Հասցե" /></div>*/}
            {/*                    <div className="address-card__row-content">{order.shipping_address.email}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">*/}
            {/*        <div className="card address-card address-card--featured">*/}
            {/*            <div className="address-card__body">*/}
            {/*                <div className="address-card__badge address-card__badge--muted"><FormattedMessage id="billingAddress" defaultMessage="Վճարման հասցեն" /></div>*/}

            {/*                <div className="address-card__row">*/}
            {/*                   {order.billing_address.address1[0]}*/}
            {/*                </div>*/}
            {/*                <div className="address-card__row">*/}
            {/*                    <div className="address-card__row-title"><FormattedMessage id="phone" defaultMessage="Հեռ։" /></div>*/}
            {/*                    <div className="address-card__row-content"> {order.billing_address.phone}</div>*/}
            {/*                </div>*/}
            {/*                <div className="address-card__row">*/}
            {/*                    <div className="address-card__row-title"><FormattedMessage id="email" defaultMessage="Էլ․ Հասցե" /></div>*/}
            {/*                    <div className="address-card__row-content"> {order.billing_address.email}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>div*/}
        </React.Fragment>
    );
}
