import React, { useCallback } from 'react';
import classNames from 'classnames';
import Collapse from '../shared/Collapse';
import FilterCheck from '../filters/FilterCheck';
import FilterRange from '../filters/FilterRange';
import { ArrowRoundedDown12x7Svg } from '../../svg';
import { FormattedMessage } from 'react-intl';
import WidgetCategories from "./WidgetCategories";


const CheckFilterHandler = {
    type: 'check',
    serialize: (value) => value.join(','),
    deserialize: (value) => (value ? value.split(',') : []),
    isDefaultValue: (filter, value) => value.length === 0,
    getDefaultValue: () => [],
};

function WidgetFilters(props) {
    const {
        categories,
        dispatch,
        filters,
        values,
        title,
        offcanvas,
    } = props;
    const handleValueChange = useCallback(({ filter, value, remove }) => {
         if(remove){
            dispatch({
                type: 'REMOVE_FILTER_VALUE',
                filter: filter,
                value: value
            });
        }else{
            dispatch({
                type: 'SET_FILTER_VALUE',
                filter: filter,
                value: value  ? CheckFilterHandler.serialize(value) : ''
            });
        }
    }, [dispatch]);

    const handleResetFilters = () => {
        dispatch({ type: 'RESET_FILTERS' });
    };

    const filtersList = [];
    let companent;
    let chekedValue = '';

    for (let filter in filters) {

        if (filter !== 'max_price') {
            let va=filter.replace(' ','_');
            if (values && values[va]) {

                chekedValue = values[va]
            }

            if (filters[filter].option.length > 0) {

                companent = <div key={filter} className="widget-filters__item">
                    <Collapse
                        toggleClass="filter--opened"
                        render={({ toggle, setItemRef, setContentRef }) => (
                            <div className="filter" ref={setItemRef}>
                                <button type="button" className="filter__title" onClick={toggle}>
                                    {filter}
                                    <ArrowRoundedDown12x7Svg className="filter__arrow" />
                                </button>
                                <div className="filter__body" ref={setContentRef}>
                                    <div className="filter__container">
                                          <FilterCheck
                                            filterValues={values}
                                            checked={chekedValue}
                                            data={filters[filter].option}
                                            value={filters[filter].url[0].replace(' ','_')}
                                            onChangeValue={handleValueChange}
                                         />
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                </div>

            } else {

                companent = ''

            }



        } else {

            companent = <FilterRange
                 key={filter}
                data={{ min: 0, max: parseInt(filters[filter]) }}
                value={''}
                filter={filter}
                onChangeValue={handleValueChange}
            />


        }

        filtersList.push(companent)
    }

    filtersList.push(< WidgetCategories categories={categories}/>)


    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    return (
        <div className={classes}>
            {/*<h4 className="widget-filters__title widget__title">{title}</h4>*/}

            <div className="widget-filters__list">
                {filtersList}
            </div>

            <div className="widget-filters__actions d-flex mb-n2">
                <button
                    type="button"
                    className="btn m-auto btn-secondary btn-sm"
                    onClick={handleResetFilters}
                >
                   <FormattedMessage id="reset" defaultMessage="Վերականգնել" />
                </button>
            </div>
        </div>
    );
}


WidgetFilters.defaultProps = {
    offcanvas: 'mobile',
};

export default WidgetFilters;
