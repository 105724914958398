/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
import qs                    from 'query-string'
import { getCategoryBySlug } from '../fake-server/endpoints/categories'
import { url }               from '../helper'
import {
  getDiscountedProducts,
  getLatestProducts,
  getPopularProducts,
  getTopRatedProducts,
}                            from '../fake-server/endpoints/products'



const shopApi = {
  /**
   * Returns array of categories.
   *
   * @param {object?} options
   * @param {number?} options.depth
   *
   * @return {Promise<Array<object>>}
   */
  getCategories: (options = {}) => {
    // if(Object.keys(window.localStorage.getItem("categories").parse()))
    return fetch(`${url}/api/categories?locale=${options.locale}`).then(
      (response) => response.json(),
    )
  },
  geFilters    : (options = {}, lang) => {
    if (options !== 'all') {
      return fetch(
        `${url}/api/category?category_id=${options}&locale=${lang}`,
      ).then((response) => response.json())
    } else {
      return fetch(`${url}/api/category/all?locale=${lang}`).then((response) =>
        response.json(),
      )
    }
  },
  /**
   * Returns category by slug.
   *
   * @param {string} slug
   * @param {object?} options
   * @param {number?} options.depth
   *
   * @return {Promise<object>}
   */
  getCategoryBySlug: (slug, options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/categories/power-tools.json?depth=2
     *
     * where:
     * - power-tools = slug
     * - 2           = options.depth
     */
    return fetch(`${url}/api/categories/slug/${slug}`).then((response) =>
      response.json(),
    )

    // This is for demonstration purposes only. Remove it and use the code above.
    // return getCategoryBySlug(slug, options)
  },
  /**
   * Returns product.
   *
   * @param {string} slug
   *
   * @return {Promise<object>}
   */
  getProductBySlug: (slug, options = {}) => {
    let lang = 'hy'
    let currency = 'AMD'

    if (options.lang) {
      currency = options.currency
      lang = options.lang
    }

    return fetch(
      `${url}/api/products/${slug}?locale=${lang}&currency=${currency}&type=simple`,
    ).then((response) => response.json())
  },
  /**
   * Returns array of related products.
   *
   * @param {string}  slug
   * @param {object?} options
   * @param {number?} options.limit
   *
   * @return {Promise<Array<object>>}
   */
  getRelatedProducts: (slug, options = {}) => {
    let lang = 'hy'
    let currency = 'AMD'
    if (options.lang) {
      currency = options.currency
      lang = options.lang
    }
    return fetch(
      `${url}/api/relation?id=${slug}&locale=${lang}&relation=related_products&currency=${currency}`,
    ).then((response) => response.json())
  },
  /**
   * Return products list.
   *
   * @param {object?} options
   * @param {number?} options.page
   * @param {number?} options.limit
   * @param {string?} options .sort
   * @param {Object.<string, string>?} filters
   *
   * @return {Promise<object>}
   *
   *
   */

  getSeachProducts: (query, options = {}, currency = 'AMD') => {
    let lang = ''
    if (options.lang) {
      lang = options.lang
    }
    // console.log(query, '555555')
    //`${url}/api/products?search=${query}&locale=${lang}&limit=100`
    return fetch(`${url}/api/search?search=${query}&locale=${lang}&currency=${currency.code}&limit=100`)
      .then((response) => response.json())
      .catch((err) => console.error(err))
  },
  getProductsList : (lang, options = {}, filters = {}, serach) => {
    if (options.lang) {
      lang = options.lang
    }
    const params = { ...options }
    params.search = qs.parse(serach).search

    Object.keys(filters).forEach((slug) => {
      params[`${slug}`] = filters[slug]
    })

    let par = ''
    if (qs.stringify(params)) {
      par = '&' + qs.stringify(params)
    }

    return fetch(`${url}/api/products?locale=${lang}`).then((response) =>
      response.json(),
    )
  },
  /**
   * Returns array of featured products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getFeaturedProducts: (options = {}) => {
    let lang = 'hy'
    if (options.lang) {
      lang = options.lang
    }

    return fetch(`${url}/api/products?featured=1&limit=8&locale=${lang}`)
      .then((response) => response.json())
      .catch((err) => console.error(err))
  },
  /**
   * Returns array of latest products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */

  getCrossSaleProducts: (slug, options = {}) => {
    let lang = 'hy'
    let currency = 'AMD'
    if (options.lang) {
      currency = options.currency
      lang = options.lang
    }
    return fetch(`${url}/api/products?locale=${lang}&currency=${currency}`)
      .then((response) => response.json())
      .catch((err) => console.error(err))
  },

  getLatestProducts: (options = {}) => {
    let lang = 'hy'
    let position = ''
    let limit = 10
    if (options.lang) {
      lang = options.lang
    }

    if (options.position) {
      position = `&${options.position}=true`
    }

    return fetch(
      `${url}/api/home/products?locale=${lang}&currency=${options.currency.code}&type=simple${position}&limit=${limit}`,
    )
      .then((response) => response.json())
      .catch((err) => console.error(err))

    // return fetch(`${url}/api/products?new=1&limit=8&locale=${lang}`)
    //     .then((response) => response.json()).catch(err => console.error(err))

    // This is for demonstration purposes only. Remove it and use the code above.
    // return getLatestProducts(options)
  },
  /**
   * Returns an array of top rated products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getTopRatedProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getTopRatedProducts(options)
  },
  /**
   * Returns an array of discounted products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getDiscountedProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.

    return getDiscountedProducts(options)
  },
  /**
   * Returns an array of most popular products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getPopularProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/popular-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    //  .catch(error=>console.error(error));
    // This is for demonstration purposes only. Remove it and use the code above.
    return getPopularProducts(options)
  },
  /**
   * Returns search suggestions.
   *
   * @param {string}  query
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getSuggestions: (query, options = {}) => {
    let lang = 'hy'
    if (options.lang) {
      lang = options.lang
    }
    return fetch(`${url}/api/search?search=${query}&locale=${lang}`)
      .then((response) => response.json())
      .catch((err) => console.error(err))
  },

  /*get multiple requests for products so they can be downloaded asynchronous*/
  getMultipleRequests: (prodId, lang = 'hy', currency = 'AMD') => {
    return Promise.all([
      fetch(`${url}/api/products/attrs/${prodId}`),
      fetch(`${url}/api/products/variants/${prodId}?locale=${lang}&currency=${currency}&type=simple`),
      fetch(`${url}/api/product-configurable-config/${prodId}`),
    ]).then(responses => {
      return Promise.all(responses.map(response => response.json()))
    })
  },

  /*get card token*/

  getCardToken: () => {
    fetch(`${url}/api/checkout/cart/token`)
      .then((response) => response.json())
      .then((res) => {
        if (res.api_token) {
          this.props.AddCartToken(res.api_token)
        }
      })
      .catch((err) => console.error(err))
  },

  getPayments: () => {
    fetch(`${url}/api/payments`)
      .then((response) => response.json())
      .then((res) => {
        // console.log(res.data)
      })
      .catch((err) => console.error(err))
  },
}

export default shopApi
