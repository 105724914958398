import React, { useEffect, useState } from 'react'
import { connect }                    from 'react-redux'
import shopApi                        from '../../api/shop'
import ProductsView                   from '../shop/ProductsView'
import { useSelector, useDispatch }   from 'react-redux'
import { useHistory }                 from 'react-router-dom'



const CategorySearchPage = ({ word, customer }) => {
  const selectedData = useSelector((state) => state.locale)
  const [productsList, setProductsList] = useState([])
  const dispatch = useDispatch()
  const currency = useSelector((state) => state.currency)
  const [selectedFilters, setSelectedFilters] = useState({})
  const options = { limit: 10, lang: selectedData }
  const history = useHistory()
  let a = 0

  useEffect(() => {
    shopApi.getSeachProducts(word, options, currency).then((products) => {
      setProductsList(products)
    })
  }, [word, selectedData, currency])

  useEffect(() => {
    if (productsList.length) {
      history.push('/')
    }
  }, [selectedFilters])

  let updated = []

  if (productsList) {
    updated = productsList.filter((e) => {
      return e.product_id
    })
  }

  return (
    <div className="container search-result-page">
      <div className="row">
        {
          updated === undefined
            ? <div className="block-loader__spinner"/>
            : <div className="col-12">
              <ProductsView
                customer={customer}
                productsList={updated || []}
                options={options}
                filters={[]}
                dispatch={dispatch}
                clearSelectedFilters={setSelectedFilters}
              />
            </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ customer, locale }) => ({
  customer,
  // locale,
})
export default connect(mapStateToProps)(CategorySearchPage)
