import { CUSTOMER_TOKEN, AUTHENTICATED, CUSTOMER_ID, REWARDS, NAME_CUSTOMER } from "./customerActionTypes";

export const setAuth = (token) => ({
    type: AUTHENTICATED,
    payload: token,
});

export const setName = (token) => ({
    type: NAME_CUSTOMER,
    payload: token,
});

export const setToken = (token) => ({
    type: CUSTOMER_TOKEN,
    payload: token,
});

export const setID = (id) => ({
    type: CUSTOMER_ID,
    payload: id,
});

export const setRewards = (rewards) => ({
    type: REWARDS,
    payload: rewards,
});

// export function updateBonusSuccess(bonus) {
//     return {
//         type: UPDATE_BONUSE,
//         payload: bonus
//     };
// }

// export function updateBonus(bonus) {
//     return (dispatch) => {
//         dispatch(updateBonusSuccess(bonus));
//     }
// }
