// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import Visa from "../../images/Visa.png";
import { FormattedMessage } from "react-intl";
import { BanksSvg } from "../../svg";

export default function FooterLinkRight(props) {
    return (
        <div className="site-footer__widget footer-links">
            <ul className="footer-links__list">
                <li>
                    <FormattedMessage id="footeraddress" defaultMessage="Ք․ Երևան, Մյասնիկյան 8" />
                </li>
                <li>
                    <FormattedMessage id="footerphone" defaultMessage="(+374) 60 11 11 11)" />
                </li>
                <li>
                    <FormattedMessage id="footerMail" defaultMessage="info@marketin.am" />
                </li>
                <li>
                    <FormattedMessage id="footerdays" defaultMessage="Երկ․-կիրակի, 09։00-18։00" />
                </li>
                <li
                    style={{
                        marginTop: "10px",
                    }}
                >
                    <div>
                        <BanksSvg />
                    </div>
                </li>
            </ul>
        </div>
    );
}

FooterLinkRight.propTypes = {
    /** widget title */
    // title: PropTypes.node.isRequired,
    /** array of links */
    items: PropTypes.array,
};

FooterLinkRight.defaultProps = {
    items: [],
};
