// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {Helmet} from "react-helmet-async";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {FacebookShareButton} from "react-share";
import {toast} from "react-toastify";
import shopApi from "../../api/shop";
import {colorType} from "../../services/color";
import {cartAddItem} from "../../store/cart";
import {compareAddItem} from "../../store/compare";
import {AddImages} from "../../store/images";
import {AddCartToken} from "../../store/token";
import {wishlistAddItem} from "../../store/wishlist";
import {Check12x9Svg, Wishlist16Svg} from "../../svg";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";

class Product extends Component {
  checkAddProd = false;
  cartProduct = null;
  refs = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      customer: this.props.customer,
      quantity: 1,
      currency: this.props.currancy,
      locale: this.props.locale,
      token: this.props.token,
      compare: this.props.compare,
      product: null,
      productConst: null,
      imagesData: null,
      simpleProduct: null,
      size: null,
      color: null,
      ml: null,
      variants: null,
      goToFirstIndex: null,
      colorType: {},
      sizeType: {},
      attributes: [],
    };
  }

  componentDidMount() {
    if (this.props.product.data.type === "configurable") {
      this.setState({
        product: this.props.product,
        simpleProduct: this.props.product.data,
        imagesData: this.props.product.data.variants[0].images,
      });

      shopApi
        .getMultipleRequests(this.props.product.data.id, this.props.locale, this.props.currency.code)
        .then((res) => {
          if (res) {

            this.props.product.data = {
              ...this.props.product.data,
              attribute: res[2].data,
              index: res[2].data.index,
              regular_price: res[2].data.regular_price,
              variant_images: res[2].data.variant_images,
              variant_prices: res[2].data.variant_prices,
              variants: res[1].data.variants,
            };
            // console.log(this.props.product.data, '9999999999999999')

            let filterVariant = this.props.product.data.variants.filter((e) => {
              return e.id == this.props.product.data.variants[0].id;
            });
            // console.log(filterVariant, 'this.props.product.data.variantsthis.props.product.data.variantsthis.props.product.data.variants')

            if (filterVariant.length > 0) {
              this.props.AddImages(filterVariant[0].images);
            }
            this.selectDef(this.props.product.data, filterVariant[0]);
            // this.setState({
            //   ...this.state,
            //   imagesData   : res[1].data.variants.filter(item => item.id === 6568)[0].base_image.large_image_url,
            // })
            this.setState({
              ...this.state,
              imagesData: res[1].data.variants[0].images,
              simpleProduct: res[1].data.variants[0],
            });
            // if (this.state.simpleProduct && this.state.simpleProduct.sku) {
            //   let colorOptions = this.props.product.data.attribute.attributes[0].options;
            //   let sizeOptions = this.props.product.data.attribute.attributes[1].options;
            //   let findColorItem = colorOptions.find(item => item.id == (this.state.simpleProduct.sku).split("-").splice(-2)[0]).label
            //   let findSizeItem = sizeOptions.find(item => item.id == (this.state.simpleProduct.sku).split("-").splice(-2)[1]).label
            //   console.log(findColorItem)
            //   console.log(findSizeItem)
            //   this.addAttributes("color", findColorItem)
            //   this.addAttributes("size", findSizeItem)
            // }

            let prod = this.state.simpleProduct;
            let attrib = this.state.product.data.attribute.attributes;
            let indexes = this.state.product.data.index;
            // console.log(attrib, "atribs");
            // console.log(indexes, "indexes");
            // console.log(prod.id, "prod.id");
            let addItem = indexes[prod.id];
            // console.log(addItem, "gjgfgfghfihfghihhghihfh");
            let colItem = attrib[0].options.find((item) => item.id == addItem["23"]);
            let sizeItem = attrib[1].options.find((item) => item.id == addItem["24"]);
            this.setState({
              attributes: [
                {
                  attribute_name: "color",
                  option_label: colItem.label,
                },
                {
                  attribute_name: "size",
                  option_label: sizeItem.label,
                },
              ],
            });
            // console.log(this.state.attributes)
          }
        })
        .then((error) => console.log(error));
    } else {
      this.checkAddProd = true;
      this.setState({
        product: this.props.product,
        simpleProduct: this.props.product.data,
        imagesData: this.props.product.data.images,
      });

      this.props.AddImages(this.props.product.data.images);
    }

    if (this.props.cartToken.cartToken === "") {
      shopApi.getCardToken();
    }
  }

  // addAttributes = (name, label) => {

  //   // if (name === "color") {

  //   this.setState({
  //     attributes: [
  //       ...this.state.attributes,
  //       {
  //         attribute_name: name,
  //         option_label: label
  //       }
  //     ]
  //   })
  //   // }
  //   // if (name === "size") {
  //   //   console.log(this.state.attributes, 'attributesattributesattributes');

  //   //   this.setState({
  //   //     attributes: [
  //   //       ...this.state.attributes,
  //   //       {
  //   //         attribute_name: name,
  //   //         option_label: label
  //   //       }
  //   //     ]
  //   //   })
  //   // }
  //   // if (this.state.attributes.length > 2) {
  //   //   if (this.state.attributes[2].name === this.state.attributes[0].name) {
  //   //     this.state.attributes.splice(0,1)
  //   //     this.state.attributes = [...this.state.attributes]
  //   //   }

  //   // }

  //   // console.log()
  // }

  goToFirstIndex = () => {
    this.setState({
      goToFirstIndex: 1,
    });
    setTimeout(() => {
      this.setState({
        goToFirstIndex: null,
      });
    }, 5);
  };

  selectDef = (prod, simple) => {
    // console.log(prod.attribute.attributes, 'asdsad')
    prod.attribute.attributes.forEach((element) => {
      // console.log(element, "element");
      // console.log(prod.index[simple.id][element.id], "prod");
      // console.log(element.code, "code");
      this.setState({[element.code]: {id: prod.index[simple.id][element.id], code: element.code}});
      // if (element.code === 'color') this.setState({ color: prod.index[simple.id][element.id] })
      // if (element.code === 'size') {
      //     this.setState({ size: { id: prod.index[simple.id][element.id], code: element.code } })
      // }
    });
  };

  items = [
    {
      type: "facebook",
      icon: <FacebookShareButton url={window.location.href}>{/* <Fb /> */}</FacebookShareButton>,
    },

    {type: "instagram", icon: "INSTA"},
    // { type: 'youtube', icon: <You /> },
  ].map((item, i) => (
    <div className="mr-3" key={i}>
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        {item.icon}
      </a>
    </div>
  ));

  createMarkup(item) {
    return {__html: item};
  }

  cartValidFunc = async () => {
    let attrCheck = {};
    let attr = {};
    this.state.product.data.attribute.forEach((e) => {
      attr = Object.assign(attr, {[e.code]: document.getElementsByName(e.code)});
      attrCheck = Object.assign(attrCheck, {[e.code]: [false, e.id]});
    });

    for (let attrItem in attr) {
      for (let i of attr[attrItem]) {
        if (i.checked === true) {
          attrCheck[attrItem][0] = true;
        }
      }
    }

    for (let attrItem in attrCheck) {
      let item = document.getElementById(`attr_${attrCheck[attrItem][1]}`);

      if (attrCheck[attrItem][0] === false) {
        item.style.display = "block";
      } else {
        item.style.display = "none";
      }
    }
    await "";
  };

  handleChangeQuantity = (quantity) => {
    this.setState({quantity});
  };

  handleChangeStyle = (event, code, id) => {
    // let itemList = [...document.getElementsByClassName(code + "_fm")];
    let itemList = document.getElementsByClassName(code + "_fm")
    let itemRadio = document.getElementById(code + id);

    for (let e of itemList) {
      e.style.borderColor = "#929292";
    }

    let item = document.getElementById(event.target.value + code);
    item.style.borderColor = "#FFD34E";

    event.target.checked = true;
  };

  sortObj = (obj) => {
    return Object.keys(obj)
      .sort()
      .reduce(function (result, key) {
        // console.log(obj[key], "porpoproporopoproporporpprorproprrprorporporpoprprororpr0147410147410--")
        result[key] = Number(obj[key]);
        return result;
      }, {});
  };

  changeImage = (product) => {
    let data;
    for (let i in this.state.product.data.variant_images) {
      if (i === product[0]) {
        data = this.state.product.data.variant_images[i];
        break;
      }
    }
    this.props.AddImages(data);
  };

  handleChange = (product, type) => {
    let prod = this.state.product.data;
    let valueId = {};
    let attrCheck = {};
    let attr = {};
    let check = true;
    let prodId = null;

    this.props.product.data.attribute.attributes.forEach((e) => {
      attr = Object.assign(attr, {[e.code]: [...document.getElementsByName(e.code)]});
      attrCheck = Object.assign(attrCheck, {[e.code]: [false, e.id]});
    });

    for (let attrItem in attr) {
      for (let i of attr[attrItem]) {
        if (i.checked) {
          valueId = Object.assign(valueId, {[attrCheck[attrItem][1]]: Number(i.value)});
          attrCheck[attrItem][0] = true;
        }
      }
    }

    for (let attrItem in attrCheck) {
      if (attrCheck[attrItem][0] === false) {
        check = false;
        break;
      }
    }

    valueId = JSON.stringify(this.sortObj(valueId));
    // console.log(valueId, "valueIDDDDDDD")

    for (let item in prod.index) {
      // console.log(item, "iririririririririririr9897879797979*")
      let elem = JSON.stringify(this.sortObj(prod.index[item]));
      // console.log(elem,"elelelellelelelelelelelelelelelellelelel")
      // console.log(elem == valueId,"??????????????????????????????????????????????????????")
      if (elem == valueId) {
        prodId = item;
      }
    }
    // console.log(prodId, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
    if (prodId) {
      let item = prod.variants.filter((e) => e.id === Number(prodId));
      // console.log(item, "itemtieitieitieitietitieiteiteitieitietieiteiteitietieieieiteieiti")
      
      this.cartProduct = item[0];
      if (this.cartProduct?.special_price) {
        this.setState({simpleProduct: this.cartProduct, imagesData: this.cartProduct.images});
        this.selectDef(this.state.product.data, this.cartProduct);
      } else {
        this.setState({simpleProduct: this.cartProduct, imagesData: this.cartProduct.images});
        this.selectDef(this.state.product.data, this.cartProduct);
      }
    }

    if (check === false) {
      for (let attrItem in attrCheck) {
        let item = document.getElementById(`attr_${attrCheck[attrItem][1]}`);
        // item.style.display = 'none'
      }
      this.checkAddProd = true;
    }

    this.state.product.data.attribute.attributes.forEach((element, i) => {
      if (element.code !== type && element.options.length !== 1)
        element.options.forEach((option, index) => {
          let item = document.getElementById(option.id + element.code);
          for (let elem = 0; elem < product.length; elem++) {
            let fill = option.products.filter((es) => es === product[elem]);
            if (fill.length === 0) item.style.opacity = "0";
            else {
              item.style.opacity = "1";
              break;
            }
          }
        });
    });
  };

  // makeHtml = (attr, e) => {
  //     return (
  //         <>
  //         <span className="other-list__input">
  //             <span className="other-radio__body">
  //                 <input
  //                     id={attr.code + e.id}
  //                     className="other-radio__input"
  //                     type="radio"
  //                     name={attr.code}
  //                     value={e.id}
  //                     checked={this.state[attr.code].id === e.id ? true : ''}
  //                     onChange={(target) => {
  //                         this.handleChangeStyle(target, attr.code, e.id)
  //                         this.handleChange(e.products, attr.code, e.id)
  //                     }}
  //                 />
  //             </span>
  //         </span>
  //             <span className="other-list__title">{e.label}</span>
  //         </>
  //     )
  // }

  badges = [];
  priceBege = Math.round(
    `${100 - (parseInt(this.props.product.data.old_price) * 100) / parseInt(this.props.product.data.price)}`
  );
  priceSale = ` ${this.priceBege} %`;

  render() {
    const {
      // product,
      layout,
      wishlistAddItem,
      compareAddItem,
      AddCartToken,
      cartAddItem,
    } = this.props;
    const {quantity, customer, compare, product, imagesData, simpleProduct, attribute} = this.state;
    // console.log(simpleProduct,"simpleProduct");
    const wishListStorage = JSON.parse(localStorage.getItem("state"));
    const emptyImage = [
      {
        large_image_url:
          " https://api.marketin.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
        medium_image_url:
          " https://api.marketin.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
        small_image_url:
          " https://api.marketin.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
        original_image_url:
          " https://api.marketin.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
      },
    ];
    if (product) {
      if (wishListStorage.wishlist.length) {
        if (wishListStorage.wishlist.filter((e) => e.id === product.data.id).length)
          Object.assign(product.data, {wishlist: true});
        else {
          Object.assign(product.data, {wishlist: false});
        }
      } else {
        Object.assign(product.data, {wishlist: false});
      }
    }
    let prices;

    if (this.props.product.data.old_price) {
      this.badges.push(
        <div className="badges-fms pointed io">
          <span>{this.priceSale}</span>
        </div>
      );
    } else if (this.props.product.data.old_price && this.props.product.data.new === 1) {
      this.badges.push(
        <div className="badges-fms pointed io">
          <span>{this.priceSale}</span>
          {/* <Bedge1 /> */}
        </div>
      );
    }
    if (this.props.product.data.new === 1) {
      this.badges.push(<div className="badges-fms io">{/* <Bedge1 /> */}</div>);
    }

    // console.log(product,"product");
    return (
      <>
        {product ? (
          <div>
            <Helmet>
              <title>{this.state.simpleProduct.name}</title>
              <meta
                name="description"
                content={
                  this.state.simpleProduct.description
                    ? this.state.simpleProduct.description.replace(/(<([^>]+)>)/gi, "")
                    : ""
                }
              />
              <meta name="name" content={this.state.simpleProduct.name ? this.state.simpleProduct.name : ""}/>
              <meta property="og:url" content={window.location}/>
              <meta property="og:title" content={this.state.simpleProduct.name}/>
              <meta property="og:image" content={this.state.simpleProduct.base_image.large_image_url}/>
            </Helmet>

            <div className={`product product--layout--${layout}`}>
              <div className="product__content">
                <ProductGallery
                  ref="productGallery"
                  layout={layout}
                  images={imagesData.length >= 1 ? imagesData : emptyImage}
                  badges={this.badges[0]}
                  toFirst={this.state.goToFirstIndex}
                />

                <div className="product__info">
                  <h1
                    className="product__name"
                    id="prod_name"
                    dangerouslySetInnerHTML={this.createMarkup(simpleProduct.name)}
                  />
                  <div className={"d-flex align-items-center"}>
                    <div className="product__shop__syn">
                      <span className="shop_name-fms">
                        <FormattedMessage id="shop" defaultMessage="Խանութ։"/>
                      </span>
                      {product.data.shop_name}
                    </div>
                  </div>
                  <div className="product__sku">
                    <FormattedMessage id="productcode" defaultMessage=" Ապրանքի կոդ: "/>
                    <span>{simpleProduct.sku}</span>
                  </div>
                  <div className="product__prices">
                    <div>
                      {/*<div className="cash_title">*/}
                      {/*    <FormattedMessage id="cash" defaultMessage="Կանխիկ գին"/>*/}
                      {/*</div>*/}
                      {simpleProduct.special_price ? (
                        <>
                          <span className="product-card__old-price">
                            <Currency value={simpleProduct.formatted_price}/>
                          </span>
                          <span className="product-card__new-price">
                            <Currency value={simpleProduct.formatted_special_price}/>
                          </span>
                        </>
                      ) : (
                        <span>
                          <Currency value={simpleProduct.formatted_price}/>
                        </span>
                      )}
                    </div>
                    <ul className="product__meta">
                      <li className="product__meta-availability">
                        {/*{product.data.in_stock*/}
                        {/*    ? <FormattedMessage id="availability"*/}
                        {/*                        defaultMessage="Հասանելիություն"/>*/}
                        {/*    : <FormattedMessage id="unavailable"*/}
                        {/*                        defaultMessage="Անհասանելի"/>}*/}
                        {/*{' '}*/}
                        {simpleProduct.in_stock ? (
                          <span className="text-success">
                            <FormattedMessage id="instock" defaultMessage="Առկա է"/>
                          </span>
                        ) : (
                          <span className="text-danger">
                            <FormattedMessage id="outOfStock" defaultMessage="Առկա չէ"/>
                          </span>
                        )}{" "}
                      </li>
                    </ul>
                    <div className="product__short_description">
                      <p dangerouslySetInnerHTML={this.createMarkup(simpleProduct.short_description)}/>
                    </div>
                    {/*<div className="product__wishlist-compare">*/}
                    {/*  <AsyncAction*/}
                    {/*    action={() => {*/}
                    {/*      return wishlistAddItem(*/}
                    {/*        product.data,*/}
                    {/*        customer.token ? customer.token : false,*/}
                    {/*        product.wishlist,*/}
                    {/*      ).then((e) => {*/}
                    {/*        if (e === false)*/}
                    {/*          toast.success(*/}
                    {/*            <FormattedMessage*/}
                    {/*              id="please.login"*/}
                    {/*              defaultMessage="`Please login or register in order to add to favorites.`"*/}
                    {/*            />,*/}
                    {/*          )*/}
                    {/*      })*/}
                    {/*    }}*/}
                    {/*    render={({ run, loading }) => (*/}
                    {/*      <button*/}
                    {/*        type="button"*/}
                    {/*        data-toggle="tooltip"*/}
                    {/*        data-placement="right"*/}
                    {/*        title="Wishlist"*/}
                    {/*        onClick={run}*/}
                    {/*        className={classNames('btn btn-sm btn-light btn-svg-icon', {*/}
                    {/*          'btn-loading': loading,*/}
                    {/*        })}*/}
                    {/*      >*/}
                    {/*        <Wishlist16Svg/>*/}
                    {/*      </button>*/}
                    {/*    )}*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>

                  {/*<div className="product__sidebar">*/}
                  {/*  {simpleProduct.cost*/}
                  {/*    ? <div className="ml-4">*/}
                  {/*      <div className="cash_title">*/}
                  {/*        <Link>*/}
                  {/*          <FormattedMessage id="credit" defaultMessage="Credit"/>*/}
                  {/*        </Link>*/}
                  {/*      </div>*/}
                  {/*      {*/}
                  {/*        simpleProduct.cost*/}
                  {/*          ? <span>*/}
                  {/*          <Currency value={parseInt(simpleProduct.cost)}/> {' '}*/}
                  {/*        </span>*/}
                  {/*          : ''*/}
                  {/*      }*/}
                  {/*    </div>*/}
                  {/*    : ''*/}
                  {/*  }*/}
                  {/*</div>*/}
                  <div className={"product_attributes"}>
                    <div className="product__meta">
                      {product && product.data && product.data.attribute ? (
                        product.data.attribute.attributes.map((attr, i) => {
                          const label = (
                            <div key={i}>
                              <p>{attr.label}</p>
                            </div>
                          );
                          if (attr.code === "size") {
                            // console.log(this.state.size, "sizzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
                            return (
                              <div key={i} className="attr_fms">
                                {label}
                                {attr.code !== "color" && attr.options.length && !attr.options[0].swatch_value && (
                                  <div className="other-list-main-fms">
                                    {attr.options.map((e, i) => {
                                      return (
                                        <label
                                          key={i}
                                          className={`other-list__item ${attr.code}_fm attr-border-fm`}
                                          id={e.id + attr.code}
                                        >
                                          <span className="other-list__input">
                                            <span className="other-radio__body">
                                              <input
                                                id={attr.code + e.id}
                                                className="other-radio__input"
                                                type="radio"
                                                name={attr.code}
                                                value={e.id}
                                                checked={this.state.size && this.state.size.id == e.id}
                                                onChange={(target) => {
                                                  // this.addAttributes(attr.code, e.label)
                                                  this.handleChangeStyle(target, attr.code, e.id);
                                                  this.handleChange(e.products, attr.code, e.id);
                                                  this.goToFirstIndex();
                                                }}
                                              />
                                            </span>
                                          </span>
                                          <span className="other-list__title">{e.label}</span>
                                        </label>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (attr.code === "ml") {
                            return (
                              <div key={i} className="attr_fms">
                                {label}
                                {attr.code !== "color" && attr.options.length && !attr.options[0].swatch_value && (
                                  <div className="other-list-main-fms">
                                    {attr.options.map((e, i) => {
                                      return (
                                        <label
                                          key={i}
                                          className={`other-list__item ${attr.code}_fm attr-border-fm`}
                                          id={e.id + attr.code}
                                        >
                                          <span className="other-list__input">
                                            <span className="other-radio__body">
                                              <input
                                                id={attr.code + e.id}
                                                className="other-radio__input"
                                                type="radio"
                                                name={attr.code}
                                                value={e.id}
                                                checked={this.state.ml && this.state.ml.id == e.id}
                                                onChange={(target) => {
                                                  // this.addAttributes(attr.code, e.label)
                                                  this.handleChangeStyle(target, attr.code, e.id);
                                                  this.handleChange(e.products, attr.code, e.id);
                                                  this.goToFirstIndex();
                                                }}
                                              />
                                            </span>
                                          </span>
                                          <span className="other-list__title">{e.label}</span>
                                        </label>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            );
                          } else {
                            return (
                              <div key={i} className="attr_color_fms">
                                {label}
                                {attr.options && attr.options.length && (
                                  <div className="color-attr_fm">
                                    {attr.options.map((e, i) => (
                                      <div key={i} className="filter-color__item" id={e.id + attr.code}>
                                        <span
                                          className={classNames("filter-color__check input-check-color", {
                                            "input-check-color--white": colorType(e.swatch_value) === "white",
                                            "input-check-color--light": colorType(e.swatch_value) === "light",
                                          })}
                                          style={{color: `${e.swatch_value}`}}
                                        >
                                          <label className="input-check-color__body">
                                            <input
                                              id={attr.code + e.id}
                                              className="input-check-color__input"
                                              name={attr.code}
                                              type="radio"
                                              value={e.id}
                                              checked={this.state[attr.code]?.id == e.id}
                                              onChange={(target) => {
                                                // this.addAttributes(attr.code, e.label)
                                                this.handleChange(e.products, attr.code);
                                                this.changeImage(e.products);
                                                this.goToFirstIndex();
                                              }}
                                            />

                                            <span className="input-check-color__box"/>
                                            <Check12x9Svg className="input-check-color__icon"/>
                                            <span className="input-check-color__stick"/>
                                          </label>
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            );
                          }
                        })
                      ) : this.props.product.data.type === "configurable" ? (
                        <>
                          <div className="alternative-variants-block">
                            <p>Color</p>
                            <div className="animate-variants"/>
                          </div>
                          <div className="bottom-animate-variant">
                            <p>Size</p>
                            <div className="animate-variants"/>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <form className="product__options">
                    <div className="form-group product__option">
                      {/*<label htmlFor="product-quantity" className="product__option-label"><FormattedMessage*/}
                      {/*    id="qty" defaultMessage="Քանակ"/></label>*/}
                      <div className="product__actions">
                        <div className="product__actions-item">
                          <InputNumber
                            id="product-quantity"
                            aria-label="Quantity"
                            className="product__quantity"
                            size="lg"
                            min={1}
                            value={quantity}
                            onChange={this.handleChangeQuantity}
                          />
                        </div>
                        <div className="product__actions-item product__actions-item--addtocart">
                          {this.state.simpleProduct ? (
                            <>
                              {/* {console.log(this.state.simpleProduct, "asdf")} */}
                              <AsyncAction
                                action={() => {
                                  if (this.state.simpleProduct.images[0]?.path) {
                                    this.state.simpleProduct.images = [this.state.simpleProduct?.images[0]?.path];
                                  }

                                  // let attrib = this.state.product.data.attribute.attributes;
                                  // let indexes = this.state.product.data.index;
                                  // console.log(attrib, "atribs")
                                  // console.log(indexes, "indexes")
                                  // console.log(prod.id, "prod.id")
                                  // let addItem = indexes[prod.id];
                                  // let colItem = attrib[0].options.find((item) => item.id == addItem["23"]);
                                  // let sizeItem = attrib[1].options.find((item) => item.id == addItem["24"]);
                                  // let x = [
                                  //   {
                                  //     attribute_name: "color",
                                  //     option_label: colItem.label,
                                  //   },
                                  //   {
                                  //     attribute_name: "size",
                                  //     option_label: sizeItem.label,
                                  //   },
                                  // ];
                                  // console.log("addItem", addItem)
                                  // console.log("colItem", colItem)
                                  // console.log("sizeItem", sizeItem)

                                  return cartAddItem(
                                    this.state.simpleProduct,
                                    [],
                                    quantity,
                                    this.state.token,
                                    this.state.customer,
                                    this.state.locale
                                  );
                                }}
                                render={({run, loading}) => (
                                  <>
                                    {product.data.type === "configurable" ? (
                                      <>

                                        <button
                                          type="button"
                                          onClick={() => {
                                            run();
                                            window.fbq("track", "AddToCart")
                                          }}
                                          disabled={!product?.data?.attribute}
                                          className={classNames("btn btn-primary", {
                                            "btn-loading": loading,
                                          })}
                                        >
                                          <FormattedMessage id="addtocart " defaultMessage="Ավելացնել քարտին"/>
                                        </button>
                                      </>
                                    ) : (
                                      <>

                                        <button
                                          type="button"
                                          onClick={() => {
                                            run();
                                            window.fbq("track", "AddToCart")
                                          }}
                                          disabled={product?.data?.attribute || !product.data.qty ? true : false}
                                          className={classNames("btn btn-primary", {
                                            "btn-loading": loading,
                                          })}
                                        >
                                          <FormattedMessage id="addtocart " defaultMessage="Ավելացնել քարտին"/>
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                              />
                            </>
                          ) : (
                            <AsyncAction
                              action={() => {
                                let prod = this.state.simpleProduct ? this.state.simpleProduct : product.data;

                                if (prod.images[0]?.path) {
                                  prod.images = [prod?.images[0]?.path];
                                }

                                let attrib = this.state.product.data.attribute.attributes;
                                let indexes = this.state.product.data.index;
                                // console.log(attrib, "atribs")
                                // console.log(indexes, "indexes")
                                // console.log(prod.id, "prod.id")
                                let addItem = indexes[prod.id];
                                let colItem = attrib[0].options.find((item) => item.id == addItem["23"]);
                                let sizeItem = attrib[1].options.find((item) => item.id == addItem["24"]);
                                let x = [
                                  {
                                    attribute_name: "color",
                                    option_label: colItem.label,
                                  },
                                  {
                                    attribute_name: "size",
                                    option_label: sizeItem.label,
                                  },
                                ];
                                // console.log("addItem", addItem)
                                // console.log("colItem", colItem)
                                // console.log("sizeItem", sizeItem)

                                return cartAddItem(
                                  prod,
                                  [],
                                  quantity,
                                  this.state.token,
                                  this.state.customer,
                                  this.state.locale,
                                  x
                                );
                              }}
                              render={({run, loading}) => (
                                <>
                                  <button
                                    type="button"
                                    onClick={run}
                                    disabled={product?.data?.attribute || !product.data.qty ? true : false}
                                    className={classNames("btn btn-primary", {
                                      "btn-loading": loading,
                                    })}
                                  >
                                    <FormattedMessage id="addtocart " defaultMessage="Ավելացնել քարտին"/>
                                  </button>
                                </>
                              )}
                            />
                          )}
                        </div>
                      </div>
                      {/*    <div*/}
                      {/*        className="product__actions-item product__actions-item--compare mt-3">*/}
                      {/*<span className="text-success_fms">*/}
                      {/*    {product.data.in_stock ? (*/}
                      {/*        <FormattedMessage id="instock" defaultMessage="Առկա է"/>*/}
                      {/*    ) : (*/}
                      {/*        <FormattedMessage id="outOfStock" defaultMessage="Առկա չէ"/>*/}
                      {/*    )}{' '}*/}
                      {/*</span>*/}
                      {/*        <span className="sku_fms">*/}
                      {/*    <FormattedMessage id="Sku" defaultMessage="SKU"/>:*/}
                      {/*</span>*/}
                      {/*        <span className="sku_fms ml-1">{simpleProduct.sku}</span>*/}
                      {/*    </div>*/}
                      <div className="product__actions-item product__actions-item--compare mt-3 d-flex">
                        <div className="d-flex mr-2">{this.items[0]}</div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="product-null-fms"/>
        )}
      </>
    );
  }
}

Product.propTypes = {
  /** product object */
  product: PropTypes.object.isRequired,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
  layout: "standard",
};

const mapDispatchToProps = {
  AddCartToken,
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
  AddImages,
};

export default connect(
  (state) => ({
    locale: state.locale,
    currency: state.currency,
    cartToken: state.cartToken,
    token: state.cartToken,
    customer: state.customer,
    compare: state.compare,
  }),
  mapDispatchToProps
)(Product);
