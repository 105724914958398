// react
import React from "react";

// third-party
import { FormattedMessage } from "react-intl";
import DropdownLanguage from "./DropdownLanguage";
import DropdownCurrency from "./DropdownCurrency";
import { ContactUs1Svg } from "../../svg";

function TopbarMobile() {
    const links = [
        {
            title: <FormattedMessage id="topbar.contact.withus" defaultMessage="Հետադարձ կապ" />,
            url: "/footer/ContactWithUs.jsx",
        },
    ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link mobile-topbar__item-fms">
            <a href="/footer/ContactWithUs.jsx" className="mobile-topbar__item-fms">
                <div className="contact-icon-fms">
                    {" "}
                    <ContactUs1Svg />
                </div>{" "}
                {item.title}
            </a>
        </div>
    ));

    return (
        <div className="site-header__topbar topbar-mobile-fms">
            <div className="topbar__container container">
                <div className="mobile-topbar__row-fms topbar__row">
                    <div className="first-row-topbar-fms">
                        <div className="topbar__item">
                            <DropdownLanguage />

                            <DropdownCurrency />
                        </div>

                        <div className="topbar__spring" />
                        {linksList}
                    </div>


                    {
                    
                    //hide  nice shopping box
                    
                    /* <div className="nice-shoping-fms">
                        {" "}
                        <FormattedMessage id="topbar.niceshoping" defaultMessage="Մաղթում ենք Ձեզ հաճելի գնումներ :)" />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default TopbarMobile;
