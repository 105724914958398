// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import departmentsAria from "../../services/departmentsArea";
import languages from "../../i18n";
import StroykaSlick from "../shared/StroykaSlick";
import { url } from "../../helper";
import Departments from "../header/Departments";
import WidgetCategories from "../widgets/WidgetCategories";
import { FormattedMessage } from "react-intl";
import { SlickLeft } from "../../svg";
import { SlickRight } from "../../svg";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
}

const slickSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
   
};

class BlockSlideShow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dep: [],
            categories: [],
        };
    }

    departmentsAreaRef = null;

    media = window.matchMedia("(min-width: 992px)");

    componentDidMount() {
        // fetch(`${url}/api/categories`)
        //     .then((response) => response.json())
        //     .then((res) => {
        //         if (res.status) {
        //             this.setState({
        //                 categories: res.categories,
        //             });
        //         }
        //     });
        // ?locale=${this.props.lang}
        fetch(`${url}/api/sliders`)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    dep: res,
                });
            });

        if (this.media.addEventListener) {
            this.media.addEventListener("change", this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener("change", this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { withDepartments } = this.props;
        let sliderImgUrl;

        if (this.state.dep[0]) {
            sliderImgUrl = `${url}/storage/${this.state.dep[0].path}`;
        }

        const blockClasses = classNames("block-slideshow block", {
            "block-slideshow--layout--full": !withDepartments,
            "block-slideshow--layout--with-departments": withDepartments,
        });

        const slides = this.state.dep.map((slide, index) => {
            const image = "themes/velocity/assets/images/headphones.png";
            const openInNewTab = (url) => {
                let newWindow;
                if (url == "") {
                    newWindow = null;
                } else {
                    newWindow = window.open(url, "_blank", "noopener,noreferrer");
                }
            };
            return (
                <div key={index} className="block-slideshow__slide">
                   
                    {sliderImgUrl ? (
                        <img
                            onClick={() => openInNewTab(`${slide.slider_path}`)}
                            className="block-slideshow__slide-image block-slideshow__slide-image--desktop cursor_slide"
                            src={`${url + "/storage/" + slide.path}`}
                        />
                    ) : (
                        <img
                            onClick={() => openInNewTab(`${slide.slider_path}`)}
                            className="block-slideshow__slide-image block-slideshow__slide-image--mobile "
                            src={{ sliderImgUrl}}
                        />
                    )}

                    <div className="container">
                        <div className="block-slideshow__slide-content">
                            {/* <div
                                className="block-slideshow__slide-title"
                                dangerouslySetInnerHTML={{ __html: slide.title }}
                            /> */}
                            <div
                                className="block-slideshow__slide-text"
                                dangerouslySetInnerHTML={{ __html: slide.content }}
                            />
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        <div className="col-3 d-lg-block mm2 m2-fms">
                            <Departments />
                            {/* <WidgetCategories categories={this.state.categories} /> */}
                        </div>
                        <div className={"col-12 col-lg-9"}>
                            {withDepartments && (
                                <div className="absolute col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                            )}

                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>{slides}</StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
