
// import ReactPixel from 'react-facebook-pixel';


// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };


// ReactPixel.init("112381151239685",advancedMatching,options);





export const url = {
  home: () => '/',

  catalog: () => '/shop/catalog',

  category: (category) => `/shop/catalog/${category.slug}`,

  // product: (product) => `/shop/products/${product.product_id || product.id}`,
  product: (product) => `/shop/products/${product.product_id || product.id}/${product.parent_id == null ? -1 : product.parent_id}`,
}


export function getCategoryParents (category) {
  return category.parent ? [...getCategoryParents(category.parent), category.parent] : []

}


// export function PageViev(){

//   ReactPixel.pageView();
// }


// test product checking
// export const changeLinks = (link, lang) => {
//   switch (link) {
//     case 'electronics-home-appliances':
//       return link = 'household-technique-and-equipment'
//     case 'perfume-cosmetics-care-products':
//       return link = 'care-products'
//     case 'kids-world':
//       return link = 'toys'
//     case 'office-stationery-supplies':
//       return link = 'stationery'
//     case 'juicesdrinks-alcoholic-beverages-1':
//       return link = lang === 'en' ? 'juicesdrinks-alcoholic-beverages-1' : 'drinks'
//     case 'jewelry-accessories':
//       return link = lang === 'ru' ? 'jewelry-accessories1' : 'jewelry-accessories'
//     case 'clothes-accessories':
//       return link = lang === 'ru' ? 'clothes' : 'clothes-accessories'
//     case 'mens-shoes':
//       return link = 'mens-shoes-1'
//     case 'mens-slippers':
//       return link = 'mens-slippers-1'
//     case 'mens-blouse':
//       return link = 'mens-blouse-2'
//     case 'baby-clothes':
//       return link = 'baby-clothes-2'
//     case 'childrens-slippers':
//       return link = 'childrens-slippers-1'
//     case 'childrens-jackets':
//       return link = 'childrens-jackets-1'
//     case 'childrens-blouses':
//       return link = 'childrens-blouses-2'
//     case 'childrens-sportswear':
//       return link = 'childrens-sportswear-2'
//     default:
//       return link
//   }
// }
