import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";
import { url } from "../../helper";
import theme from "../../data/theme";
import { useHistory } from "react-router-dom";
import AccountRegister from "./AccountRegister";
import Register from "./Register";
import RegisterPhys from "./RegisterPhys";
import PageHeader from "../shared/PageHeader";

export default function AccountPageRegister() {
    const [input, Setinput] = useState({});

    const [checked, setCheked] = useState(true);
    const history = useHistory();

    const breadcrumb = [
        { title: <FormattedMessage id="topbar.home" defaultMessage="Գլխավոր" />, url: "" },
        { title: <FormattedMessage id="topbar.myAccount" defaultMessage="Իմ հաշիվը" />, url: "" },
    ];

    const checkEmail = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return "true";
        }
        // SetError( [...error,[{'pass':'not equal'}]])
        return "false";
    };

    const comfirmPss = () => {
        return false;
    };

    const register = (event) => {
        event.preventDefault();

        checkEmail();
        comfirmPss();

        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: input.email,
                password: input.password,
                password_confirmation: input.confirm_password,
            }),
        };

        fetch(url + "/api/customer/register", options)
            .then((responce) => responce.json())
            .then((res) => {
                if (res.message == "Your account has been created successfully.") {
                    history.push("/");
                } else {
                    if (res.email.length) {
                        alert(res.email[0]);
                    }

                    if (res.password.length) {
                        alert(res.password[0]);
                    }
                }
            })
            .catch((err) => console.error(err, "asdasdasd"));
    };
    const handleChange = (e) => {
        input[e.target.name] = e.target.value;
        Setinput(input);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>
            <PageHeader breadcrumb={breadcrumb} />
            <div className="block">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <div className="choose_fm">
                                        <label className="radio_fm">
                                            <FormattedMessage id="reg.phys" defaultMessage="Գնորդ" />
                                            <input
                                                type="radio"
                                                name="radio"
                                                onClick={() => {
                                                    setCheked(true);
                                                }}
                                                checked={checked}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="radio_fm">
                                            <FormattedMessage id="reg.loy" defaultMessage="Վաճառող" />
                                            <input
                                                type="radio"
                                                name="radio"
                                                onClick={() => {
                                                    setCheked(false);
                                                }}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    {checked ? <Register /> : <RegisterPhys />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
