// react
import React, { Component } from "react";

// third-party
import PropTypes from "prop-types";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { ScrollContext } from "react-router-scroll-4";

// application
//import languages from '../i18n';
import { localeChange } from "../store/locale";

// pages
import Layout from "./Layout";
import HomePageOne from "./home/HomePageOne";
import { url } from "../helper";

const direction = "ltr";

class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "",
            code: "",
        };
    }

    componentDidMount() {
        fetch(`${url}/api/translations`)
            .then((res) => res.json())
            .then((resp) => {
                this.setState({ language: resp });
            });

        setTimeout(() => {
            const preloader = document.querySelector(".site-preloader");

            preloader.addEventListener("transitionend", (event) => {
                if (event.propertyName === "opacity") {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add("site-preloader__fade");
        }, 500);
    }
    shouldUpdateScroll = (prevRouterProps, { location }) =>
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname;
    render() {
        const { locale } = this.props;
        if (!this.state.language) {
            return true;
        }
        const { messages } = this.state.language[locale];
        //  const { messages, direction } = this.state.languages[locale];
        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                            <Switch>
                                <Route
                                    path="/"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={HomePageOne} />
                                    )}
                                />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
