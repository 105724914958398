// react
import React from "react";

// third-party
import classNames from "classnames";
import { Link, matchPath, Redirect, Switch, Route } from "react-router-dom";
// application
import PageHeader from "../shared/PageHeader";
// page
import {
    LogoutSvg,
    InvoiceSvg,
    IconlocationOnSvg,
    IconAccountBoxSvg,
    GroupCustomer1Svg,
    IconProfileBonus,
} from "../../svg/index";
import { connect } from "react-redux";
import { setName } from "../../store/customer";
import AccountPageAddresses from "./AccountPageAddresses";
import AccountPageDashboard from "./AccountPageDashboard";
import AccountPageEditAddress from "./AccountPageEditAddress";
import AccountPageOrderDetails from "./AccountPageOrderDetails";
import AccountPageOrders from "./AccountPageOrders";
import AccountPageProfile from "./AccountPageProfile";
import AccountPageBonuses from "./AccountPageBonuses";

import { useEffect } from "react";
import AccountPageNewAddresses from "./AccountPageNewAddresses";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { url } from "../../helper";
import Header from "../header/Header";

function AccountLayout(props) {
    const customer = useSelector((state) => state.customer);
    const [address, setAddress] = useState();
    const dispatch = useDispatch();
    const { match, location } = props;
    const history = useHistory();

    useEffect(() => {
        const abortController = new AbortController();
        const single = abortController.single;
        if (customer.token) {
            fetch(url + "/api/customer/get?token=" + customer.token, { single: single })
                .then((responce) => responce.json())
                .then((res) => {
                    if (res) {
                        setAddress(res.data);
                    }
                })
                .catch((err) => console.error(err));
        }

        return function cleaup() {
            abortController.abort();
        };
    }, [customer.token]);
    if (!address) {
        return "";
    }
    const logout = (e) => {
        e.preventDefault();
        dispatch({ type: "AUTHENTICATED", payload: false });
        dispatch({ type: "CUSTOMER_TOKEN", payload: "" });
        dispatch({ type: "REWARDS", payload: false });

        fetch(`${url}/api/customer/logout?token=${customer.token}`).catch((error) =>
            alert("Server Error , contact with administrator" + error)
        );
        history.push("/");
        props.setName("");
    };
    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: "" },
        { title: <FormattedMessage id="topbar.myAccount" defaultMessage="Իմ հաշիվը" />, url: "" },
    ];

    const links = [
        // {   title: <FormattedMessage id="topbar.myAccount" defaultMessage="Իմ հաշիվը" />    , url: 'dashboard' },
        {
            icon: <IconAccountBoxSvg />,
            title: <FormattedMessage id="account.editprofile" defaultMessage="Խմբագրել Հաշիվը" />,
            url: "profile",
        },
        {
            icon: <InvoiceSvg />,
            title: <FormattedMessage id="account.orderhistory" defaultMessage="Իմ Պատվերները" />,
            url: "orders",
        },
        // {
        //     icon: <IconlocationOnSvg />,
        //     title: <FormattedMessage id="account.address" defaultMessage="Հասցե" />,
        //     url: "addresses",
        // },
        {
            icon: <IconProfileBonus />,
            title: <FormattedMessage id="accumulated.bonuses" defaultMessage="Accumulated Bonuses" />,
            url: "bonuses",
        },
        { icon: <LogoutSvg />, title: <FormattedMessage id="account.logout" defaultMessage="Ելք" />, url: "logout" },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames("account-nav__item", {
            "account-nav__item--active": isActive,
        });

        return (
            <>
                <li key={link.url} className={classes}>
                    {link.url === "logout" ? (
                        <a href="/account/logout" onClick={logout}>
                            {link.icon}
                            <span>
                                <FormattedMessage id="account.logout" defaultMessage="Ելք" />
                            </span>
                        </a>
                    ) : (
                        <Link to={url}>
                            {link.icon} <span>{link.title}</span>
                        </Link>
                    )}
                </li>
            </>
        );
    });

    return (
        <React.Fragment>
            <div className="block m-top-80">
                <div className="container">
                    <div className="row justify-constent-space-betwin login_block_mobile_fms">
                        <div className="col-12 col-lg-3 d-flex cart-data_fms">
                            <div className="account-nav flex-grow-1">
                                <div className={"customer__info d-flex "}>
                                    <div className="icon-div-fms">
                                        <GroupCustomer1Svg />
                                    </div>
                                    <div className={"customer__info__name"}>
                                        <span>{address.name || "Անուն Ազգանուն"} </span>
                                        <span>{address.email || "anun123gmail.com"}</span>
                                    </div>
                                </div>

                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 m-top-80 cart-adress_fms">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={AccountPageDashboard} />
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile} />
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route exact path={`${match.path}/bonuses`} component={AccountPageBonuses} />
                                <Route
                                    exact
                                    path={`${match.path}/orders/:orderId`}
                                    render={(props) => <AccountPageOrderDetails id={props.match.params} />}
                                />
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />
                                <Route exact path={`${match.path}/addresses/new`} component={AccountPageNewAddresses} />
                                <Route
                                    exact
                                    path={`${match.path}/addresses/:addressId`}
                                    render={(props) => <AccountPageEditAddress id={props.match.params} />}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    setName,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountLayout);
