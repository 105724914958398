import React, { useEffect, useState } from "react";

import FidemLogo from "../../images/FidemLogo.png";
import MobileFooterTerms from "./MobileFooterTerms";
import MobileFooterAboutUs from "./MobileFooterAboutUs";
import MobileFooterContactUs from "./MobileFooterContactUs";
import FooterNewsletter from "./FooterNewsletter";
import { useSelector } from "react-redux";
import { LogoFSvg } from "../../svg";
import FooterContacts from "./FooterContacts";


export default function MobileFooter() {
    const selectedData = useSelector((state) => state.locale);
    const [navLinks, SetNavLinks] = useState();
    useEffect(() => {
        fetch(`https://api.marketin.am/api/cms/menus?locale=${selectedData}`)
            .then((response) => response.json())
            .then((res) => {
                SetNavLinks(res.data?.filter((e) => e.name != "Blog"));
            });
    }, [selectedData]);

    let FooterContact;
    if (navLinks != undefined) {
        navLinks.sort(function (a, b) {
            var keyA = a.position,
                keyB = b.position;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }
    if (navLinks) {
        FooterContact = navLinks.map((item, index) => {
            if (item.type === "footer" && item.status) {

                return (
                    <li key={index} className="footer-contacts-li">
                        <FooterContacts id={item.id} page_id={item.page_id} url_key={item.url_key} name={item.name} />
                    </li>
                );
            }
        });
    }
    let FilteredFooterContact;
    if (FooterContact) {
        FilteredFooterContact = FooterContact.filter(function (x) {
            return x !== undefined;
        });
    }
    if (!FilteredFooterContact) {
        return null;
    }
    return (
        <div className="mobile-footer">
            <div className="container">
                <div className="mobile-footer-container">
                    <div className="mobile-footer-dropdowns-list">
                        <div className="mobile-footer-dropdown">
                            <MobileFooterTerms footercont={FilteredFooterContact.splice(0, 5)} />
                        </div>

                        <div className="mobile-footer-dropdown">
                            <MobileFooterAboutUs footercont={FilteredFooterContact} />
                        </div>
                        <div className="mobile-footer-dropdown">
                            <MobileFooterContactUs />
                        </div>
                    </div>

                    <div className="mobile-footer-newsletter">
                        <FooterNewsletter />
                    </div>
                    
                </div>

                {/* <div className="mobile-footer-fidem">
                    <span>Design and Development by</span>
                    <a target="_blank" href="https://www.fidem.am/">
                        <div className="line-footer-fidesvg">
                            <LogoFSvg className="line-footer-fidesvg" />{" "}
                        </div>
                    </a>
                </div> */}
            </div>

           
        </div>
    );
}
