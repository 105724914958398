// react
import React, { useState, useEffect } from "react";
import { url } from "../../helper";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PageHeader from "../shared/PageHeader";

function StoresPage() {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        fetch(`${url}/api/sellers`)
            .then((response) => response.json())
            .then((res) => setBrands(res));
    }, []);

    let brandsList;

    // let fakeData = [
    //     {id:1, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:2, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:3, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:4, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:5, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:6, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:7, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:8, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:9, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:10, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:11, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:12, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    //     {id:13, shop_title:"Dalma Garden Mall", logo:"", description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam animi"},
    // ]

    if (brands.length) {
        brandsList = brands.map((item) => (
            <div key={item.id} className="brand-card col-md-4 col-sm-6 col-6 col-lg-3">
                <div className="brand-inner">
                    <Link to={"/stores/" + item.url}>
                        <div className="brand-img-body">
                            <img className="brand-img" src={url + "/storage/" + item.logo} alt="brand image" />
                        </div>
                        <div className="brand-name">{item.shop_title}</div>
                        <div className="brand-description">
                            {item.description && item.description.replace(/<\/?[^>]+(>|$)/g, "")}
                        </div>
                    </Link>
                </div>
            </div>
        ));
    }
    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: "" },
        { title: <FormattedMessage id="stores" defaultMessage="Stores" />, url: "" },
    ];
    return (
        <React.Fragment>
            <PageHeader breadcrumb={breadcrumb} />
            <div className="container px-4 pb-4">
                <div className="row">{brandsList}</div>
            </div>
        </React.Fragment>
    );
}

export default StoresPage;
