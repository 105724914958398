// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// application
import NavLinks from "./NavLinks";
import NavPanel from "./NavPanel";
// import Topbar from "./Topbar";
import { LogoMarketin } from "../../svg";
import IndicatorSearch from "./IndicatorSearch";
import IndicatorCart from "./IndicatorCart";
import IndicatorAccount from "./IndicatorAccount";
import Search from "./Search";
import { useRef } from "react";

function Header(props) {
  const { layout, name } = props;
  let bannerSection;
  let searchRef = useRef();

  if (layout === "default") {
    bannerSection = (
      <div className="site-header__middle container">
        <div className="site-header__logo">
          <Link to="/">
            <LogoMarketin />
          </Link>
        </div>
        <div className="site-header__search">
          <Search inputRef={searchRef} context="header" />
        </div>

        {/*<Search />*/}
        <div className="site-header__phone">
          <IndicatorCart />
          {<IndicatorAccount />}
          {name ? name : ""}
        </div>
      </div>
    );
  }

  return (
    <>
     
      <div className="site-header">
        {bannerSection}
        <div className="site-header__nav-panel">
          <NavPanel layout={layout} />
        </div>
      </div>
    </>
  );
}

Header.propTypes = {
  layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
  layout: "default",
};

const mapStateToProps = (state) => ({
  name: state.customer.nameCostmer,
});

export default connect(mapStateToProps)(Header);
