// react
import React from "react";

// third-party
import { FormattedMessage } from "react-intl";
import DropdownLanguage from "./DropdownLanguage";
import DropdownCurrency from "./DropdownCurrency";

function Topbar() {
    const links = [{ title: <FormattedMessage id="topbar.contact.withus" defaultMessage="Կապ մեզ հետ" />, url: "" }];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            <a href="/footer/ContactWithUs.jsx">{item.title}</a>
        </div>
    ));

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    <div className="topbar__item">
                        <DropdownLanguage />

                        <DropdownCurrency />
                    </div>

                    <div className="topbar__spring" />

                    <div
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        {" "}
                        {/* <FormattedMessage
                            id="topbar.niceshoping"
                            defaultMessage="Մաղթում ենք Ձեզ հաճելի գնումներ :)"
                        />{" "} */}
                    </div>
                    {linksList}
                </div>
            </div>
        </div>
    );
}

export default Topbar;
