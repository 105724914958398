import React from "react";
import HomeLeft from "../../images/home-left.webp";
import HomeRight from "../../images/home-right.webp";
import { Link } from "react-router-dom";
import { url } from "../../helper";

function BlockHomeBaner(props) {
    const openInNewTab = (url) => {
        let newWindow;
        if (url == "null") {
            newWindow = null;
        } else {
            newWindow = window.open(url, "_blank", "noopener,noreferrer");
        }
    };

    return (
        <div className="homeCenterImages">
            {props.id == 1 ? (
                <div className="block-banner__body" onClick={() => openInNewTab(`${props?.link}`)}>
                    <div className="banners-firstLine-fms">
                        <div className="banner-text" dangerouslySetInnerHTML={{ __html: props.content }}></div>
                    </div>
                    <img className="banner-img-fms" src={url + "/storage/" + props.url} alt="" />
                </div>
            ) : (
                <div className="block-banner__body" onClick={() => openInNewTab(`${props?.link}`)}>
                    <div className="second-banner-titles-fms">
                        <div
                            className="banner-text second-banner-fms"
                            dangerouslySetInnerHTML={{ __html: props.content }}
                        ></div>
                    </div>
                    <img className="banner-img-fms" src={url + "/storage/" + props.url} alt="" />
                </div>
            )}
        </div>
    );
}

export default BlockHomeBaner;

// {slide.slider_path != "" ? (
//     <div
//         onClick={() => openInNewTab(`${slide.slider_path}`)}
//         className="block-slideshow__slide-image block-slideshow__slide-image--desktop cursor_slide"
//         style={{
//             backgroundImage: `url(${url + "/storage/" + slide.path})`,
//         }}
//     />
// ) : (
//     <div
//         onClick={() => openInNewTab(`${slide.slider_path}`)}
//         className="block-slideshow__slide-image block-slideshow__slide-image--mobile "
//         style={{
//             backgroundImage: `url(${url + "/storage/" + slide.path})`,
//         }}
//     />
// )}
