// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// application
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import ReactGA from "react-ga";
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import MobileFooter from "./footer/MobileFooter";

// pages



import AccountLayout from "./account/AccountLayout";
import AccountPageLogin from "./account/AccountPageLogin";
import AccountForgotPassword from "./account/AccountForgotPassword";
import BlogPageCategory from "./blog/BlogPageCategory";
import BlogPagePost from "./blog/BlogPagePost";
import PageCart from "./shop/ShopPageCart";
import PageCheckout from "./shop/ShopPageCheckout";
import PageWishlist from "./shop/ShopPageWishlist";
import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPageOrderSuccess from "./shop/ShopPageOrderSuccess";
import ShopPageProduct from "./shop/ShopPageProduct";
import CategorySearchPage from "./shop/CategorySearchPage";
import NewCollection from "./blocks/NewCollection";
import SaledProducts from "./blocks/SaledProducts";
import SearchedProducts from "./shop/SearchedProducts";
import StoresPage from "./shop/StoresPage";

import SitePageNotFound from "./site/SitePageNotFound";

import SiteCustomPage from "./site/SiteCustomPage";
import ThankPage from "./site/ThankPage";

import { url } from "../helper";
// data stubs
import theme from "../data/theme";
import AccountPageRegister from "./account/AccountPageRegister";
import StoreInnerPage from "./shop/StoreInnerPage";

import ContactWithUs from "./footer/ContactWithUs";
import MobileLinks from "./mobile/MobileLinks";
import MobileHeaderSecond from "./mobile/MobileHeaderSecond";
import ThankForMessagePage from "./site/ThankForMessagePage";
import ThankForRegistration from "./site/ThankForRegistration";
import { LogoFSvg } from "../svg";


// Topbar

import Topbar from "./header/Topbar";
import TopbarMobile from "./header/TopbarMobile";

function Layout(props) {
    const { match, headerLayout, homeComponent } = props;
    const customer = useSelector((state) => state.customer);
    const cartToken = useSelector((state) => state.cartToken);
    const dispatch = useDispatch();

    const [googleCode, setGoogleCode] = useState("");

    useEffect(() => {
        if (googleCode) {
            fetch(`${url}/api/googlecode`)
                .then((res) => res.json)
                .then((responce) => {
                    if (responce.code) {
                        ReactGA.initialize(responce.code);
                        ReactGA.pageview(window.location.pathname);
                        setGoogleCode(responce.code);

                    }
                });
        }
    }, [googleCode]);

    useEffect(() => {
        const abortController = new AbortController();
        const { single } = abortController;

        if (cartToken.cartToken === "") {
            fetch(`${url}/api/checkout/cart/token`, { single })
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.api_token) {
                        dispatch({ type: "CART_TOKEN", payload: res.api_token });
                    }
                })
                .catch((err) => console.error(err));
        }
        return function cleaup() {
            abortController.abort();
        };
    }, [cartToken.cartToken === ""]);

    useEffect(() => {
        const abortController = new AbortController();
        const { single } = abortController;

        if (customer.token) {
            const { exp } = jwt_decode(customer.token);

            if (Date.now() >= exp * 1000) {
                dispatch({ type: "AUTHENTICATED", payload: false });
                dispatch({ type: "CUSTOMER_TOKEN", payload: "" });
            }
        }

        return function cleaup() {
            abortController.abort();
        };
    }, [customer.token]);



    return (
        <React.Fragment>
            <ToastContainer autoClose={1500} progress={undefined} pauseOnHover={false} />
            <TopbarMobile />
            <MobileMenu />

            <div className="site">
                <header className="site__header header_osition_in_site d-lg-none">
                    <MobileHeader />
                </header>

                <div>
                    <Topbar  />
                </div>

                <header className="site__header header_osition_in_site d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch  >
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        {/*
                        // Shop
                        */}
                        <Redirect exact from="/shop" to="/shop/catalog" />

                        <Route
                            exact
                            path="/shop/catalog"
                            render={(props) => (
                                <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />
                        <Route exact path="/contact-us" render={(props) => <ContactWithUs {...props} />} />
                        <Route
                            exact
                            path="/footer/ContactWithUs.jsx"
                            render={(props) => <ContactWithUs {...props} />}
                        />

                        <Route
                            exact
                            path="/shop/catalog/:categorySlug"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    categorySlug={props.match.params.categorySlug}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/catalog/search/:word"
                            render={(props) => <CategorySearchPage {...props} word={props.match.params.word} />}
                        />
                        <Route
                            exact
                            // path="/shop/products/:productSlug"
                            path="/shop/products/:productSlug/:parentId"
                            render={(props) => (
                                <ShopPageProduct
                                    {...props}
                                    layout="standard"
                                    productSlug={props.match.params.productSlug}
                                    parentId={props.match.params.parentId}
                                />
                            )}
                        />

                        <Route exact path="/shop/newCollection" component={NewCollection} />
                        <Route exact path="/shop/saledProducts" component={SaledProducts} />
                        <Route exact path="/shop/cart" component={PageCart} />
                        <Route exact path="/checkout" component={PageCheckout} />
                        {/* <Route exact path="/shop/checkout/success" component={ShopPageOrderSuccess} /> */}
                        {/* <Route exact path="/shop/wishlist" component={PageWishlist} /> */}
                        {/* <Route exact path="/shop/compare" component={PageCompare} /> */}
                        {/* <Route */}
                        {/*    exact */}
                        {/*    path="/blog" */}
                        {/*    render={(props) => ( */}
                        {/*        <BlogPageCategory {...props} layout="grid" sidebarPosition="end" /> */}
                        {/*    )} */}
                        {/* /> */}

                        {/* <Route */}
                        {/*    exact */}
                        {/*    path="/blog/:blogID" */}
                        {/*    render={(props) => ( */}
                        {/*        <BlogPagePost */}
                        {/*            id={props.match.params} */}
                        {/*            {...props} */}
                        {/*            layout="classic" */}
                        {/*            sidebarPosition="end" /> */}
                        {/*    )} */}

                        {/* /> */}

                        {/*
                        // Account
                        */}
                        {!customer.authenticated && !customer.token ? (
                            <Route exact path="/account/login" component={AccountPageLogin} />
                        ) : (
                            <Route path="/account" component={AccountLayout} />
                        )}

                        {!customer.authenticated && !customer.token ? (
                            <Route exact path="/account/register" component={AccountPageRegister} />
                        ) : (
                            <Route path="/account" component={AccountLayout} />
                        )}
                        {/*
                        // Site
                        */}
                        <Route exact path="/forgot/password" component={AccountForgotPassword} />
                     
                        <Route
                            exact
                            path="/page/:pageID"
                            render={(props) => <SiteCustomPage {...props} id={props.match.params} />}
                        />
                        <Route
                            exact
                            path="/stores/:url"
                            render={(props) => <StoreInnerPage {...props} storeId={props.match.params.url} />}
                        />
                        <Route path="/stores" component={StoresPage} />
                        <Route
                            exact
                            path="/blog/:blogSlug"
                            render={(props) => <BlogPagePost {...props} blogSlug={props.match.params.blogSlug} />}
                        />
                        <Route
                            exact
                            path="/blog"
                            render={(props) => <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />}
                        />
                        <Route path="/thanks" {...props} component={ThankPage} />
                        <Route path="/thanksforMessage" {...props} component={ThankForMessagePage} />
                        <Route path="/thanks-for-registration" {...props} component={ThankForRegistration} />

                        {/*
                        // Page Not Found
                        */}
                        {/* <Route component={SitePageNotFound} /> */}
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                    <MobileFooter />
                    <div className="line-footer">
                        <span className="line-footer-fidem">Design and Development by</span>
                        <a target="_blank" href="https://www.fidem.am/">
                            <div className="line-footer-fidesvg">
                                <LogoFSvg className="line-footer-fidesvg" />{" "}
                            </div>
                        </a>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: "default",
};

export default Layout;
