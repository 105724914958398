// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
// data stubs
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { url } from "../../helper";

import Content from "./Content";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PageHeader from "../shared/PageHeader";
const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SiteCustomPage(props) {
    const history = useHistory();
    const selectedData = useSelector((state) => state.locale);
    const { id } = props;
    const [content, setContent] = useState();

    // useEffect(() => {
    //     let canceled = false;
    //
    //     fetch('https://api.megalight.am/api/page/' + id.pageID+'?locale='+selectedData)
    //         .then(response => response.json())
    //         .then(res => {
    //             if(res=== undefined || res.length == 0){
    //                 history.push('/');
    //             }else{
    //                 setContent(res)
    //             }
    //
    //         })
    //
    // }, [id,selectedData])

    useEffect(() => {
        let canceled = false;

        fetch(`${url}/api/cms/pages?id=${id.pageID}&locale=${selectedData}`)
            .then((response) => response.json())
            .then((res) => {
                if (res === undefined || res.length == 0) {
                    history.push("/");
                } else {
                    setContent(res);
                }
            });
    }, [id]);
    let breadcrumb;
    if (content?.data[0]?.url_key == "bonus-system") {
        breadcrumb = [
            { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: "" },
            { title: <FormattedMessage id="bonus.system" defaultMessage="Bonus system" />, url: "" },
        ];
    } else if (content?.data[0].url_key == "ogtagorcman-paymanner") {
        breadcrumb = [
            { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: "" },
            { title: <FormattedMessage id="privacy.policy" defaultMessage="Privacy Policy" />, url: "" },
        ];
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Custom page`}</title>
                <meta name="description" content="Custom description" />
            </Helmet>
            <PageHeader breadcrumb={breadcrumb} />
            {/* <Content text={content} {...props} /> */}
            <div className="container">
                <div className="about-us__text">
                    {/* {content ? content.data[0].page_title : ""} */}
                    <div
                        className="dangerous-html-page"
                        dangerouslySetInnerHTML={{ __html: content ? content.data[0].html_content : "" }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default SiteCustomPage;
