import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { url } from "../../helper";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

export default function RegisterPhys(props) {
    const intialValues = {
        type: "company",
        want_to_be_seller: true,
        company_name: "",
        vat_id: "",
        address1: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        position: "manager",
        country: "AM",
        state: "Yerevan",
        city: "Prospekt",
        postcode: "8100",
        //url: "",
    };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    const submitForm = () => {
        let options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
        };

        fetch(`${url}/api/customer/register`, options)
            .then((responce) => responce.json())
            .then((res) => {
                if (res.message == "Your account has been created successfully.") {
                    history.push("/thanks-for-registration");
                    // props.clicked("login", true);
                    // props.close("sign", false);
                } else {
                    const keys = Object.keys(res.errors);
                    const errors = keys.reduce((prev, next) => {
                        return {
                            ...prev,
                            [next]: res.errors[next],
                        };
                    }, {});
                    setFormErrors(errors);
                }
            })
            .catch((err) => console.error(err));
    };

    // const handleChangeUrl = (name, value) => {
    //     setFormValues({ ...formValues, [name]: value });

    //     setTimeout(() => {
    //         fetch(`${url}/marketplace/seller/url`, {
    //             method: "POST",
    //             headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({ url: formValues.url }),
    //         })
    //             .then((response) => response.json())
    //             .then((res) => {
    //                 console.log(res, "[][][][][");
    //             });
    //     }, 250);
    // };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.company_name) {
            errors.company_name = "This field is required";
        }

        if (!values.position) {
            errors.position = "This field is required";
        }

        if (!values.address1) {
            errors.address1 = "This field is required";
        }

        if (!values.vat_id) {
            errors.vat_id = "This field is required";
        }

        if (!values.first_name) {
            errors.first_name = "This field is required";
        }

        if (!values.last_name) {
            errors.last_name = "This field is required";
        }

        if (!values.phone) {
            errors.phone = "This field is required";
        }

        if (!values.password_confirmation || values.password != values.password_confirmation) {
            errors.password_confirmation = "Passwords do not match";
        }

        if (!values.email || !regex.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.password || values.password.length < 6) {
            errors.password = (
                <FormattedMessage
                    id="account.error.password.required"
                    defaultMessage="Password must be more than 6 characters"
                />
            );
        }
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    return (
        <div className="\login_content_fm">
            <form action="" className="log_ss_fm" onSubmit={handleSubmit}>
                <div className="form-group">
                    <FormattedMessage id="reg.company" defaultMessage="Կազմակերպության անուն">
                        {(placeholder) => (
                            <input
                                name="company_name"
                                type="text"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.company_name}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.company_name && (
                        <span className="error red-fms">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.company_name} />
                        </span>
                    )}
                </div>

                <div className="form-group">
                    <FormattedMessage id="reg.tin" defaultMessage="ՀՎՀՀ">
                        {(placeholder) => (
                            <input
                                name="vat_id"
                                type="text"
                                className="form-control form-control"
                                value={formValues.vat_id}
                                onChange={handleChange}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>

                <div>
                    {formErrors.vat_id && (
                        <span className="error ">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.vat_id} />
                        </span>
                    )}
                </div>

                <div className="form-group">
                    <FormattedMessage id="global.address" defaultMessage="Հասցե">
                        {(placeholder) => (
                            <input
                                name="address1"
                                type="text"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.address1}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>

                <div>
                    {formErrors.address1 && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.address1} />
                        </span>
                    )}
                </div>
                <div className="form-group">
                    <FormattedMessage id="name" defaultMessage="Անուն">
                        {(placeholder) => (
                            <input
                                name="first_name"
                                type="text"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.first_name}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.first_name && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.position} />
                        </span>
                    )}
                </div>
                <div>
                    {/* {formErrors.first_name && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.first_name} />
                        </span>
                    )} */}
                </div>
                <div className="form-group">
                    <FormattedMessage id="lname" defaultMessage="Ազգանուն">
                        {(placeholder) => (
                            <input
                                name="last_name"
                                type="text"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.last_name}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.last_name && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.last_name} />
                        </span>
                    )}
                </div>
                <div className="form-group">
                    <FormattedMessage id="topbar.email" defaultMessage="էլ․ Հասցե">
                        {(placeholder) => (
                            <input
                                name="email"
                                id="header-signin-email"
                                type="email"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.email}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.email && (
                        <span className="error">
                            <FormattedMessage id="account.error.email" defaultMessage={formErrors.email} />
                        </span>
                    )}
                </div>
                <div className="form-group">
                    <FormattedMessage id="reg.phone" defaultMessage="Հեռախոսահամար">
                        {(placeholder) => (
                            <input
                                name="phone"
                                type="text"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.phone}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.phone && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.phone} />
                        </span>
                    )}
                </div>
                <div className="form-group">
                    <FormattedMessage id="topbar.password" defaultMessage="Գաղտնաբառ">
                        {(placeholder) => (
                            <input
                                name="password"
                                id="header-signin-password"
                                type="password"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.password}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {" "}
                    {formErrors.password_confirmation && (
                        <span className="error">
                            <FormattedMessage
                                id="account.error.required"
                                defaultMessage={formErrors.password_confirmation}
                            />
                        </span>
                    )}
                </div>
                <div className="form-group">
                    <FormattedMessage id="password.confirmation" defaultMessage="Հաստատել գաղտնաբառը">
                        {(placeholder) => (
                            <input
                                name="password_confirmation"
                                id="header-signin-password"
                                type="password"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.password_confirmation}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>
                {/* <div>
                    {formErrors.password_confirmation && (
                        <span className="error">
                            <FormattedMessage
                                id="account.error.required"
                                defaultMessage={formErrors.password_confirmation}
                            />
                        </span>
                    )}
                </div> */}
                {/* <div className="form-group">
                    <FormattedMessage id="topbar.password" defaultMessage="Կրկնել գաղտնաբառ">
                        {(placeholder) => (
                            <input
                                name="url"
                                id="header-signin-password"
                                type="text"
                                className="form-control form-control"
                                onChange={(e) => handleChangeUrl("url", e.target.value)}
                                value={formValues.url}
                                placeholder="url"
                            />
                        )}
                    </FormattedMessage>
                </div> */}
                <div>
                    {formErrors.password && (
                        <span className="error">
                            <FormattedMessage id="account.error.password" defaultMessage={formErrors.password} />
                        </span>
                    )}
                </div>
                <button onClick={() => submitForm} type="submit" className="btn btn-primary btn__custom">
                    <FormattedMessage id="topbar.register" defaultMessage="Գրանցում" />
                </button>
            </form>
        </div>
    );
}
