import { ADD_CATEGORIES, ADD_FAKE_CATEGORIES } from './categoryActionsType'



const initialState = {
  categories    : [],
  fakeCategories: [],
}
export default function categoryReducer (state = initialState, action) {
  switch (action.type) {
    case ADD_CATEGORIES:
      return { ...state, categories: action.payload }
    case ADD_FAKE_CATEGORIES:
      return { ...state, fakeCategories: action.payload }
    default:
      return state
  }
}
