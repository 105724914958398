import { toast } from 'react-toastify';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM } from './wishlistActionTypes';



const translations = {
    product: {
        hy: 'Ապրանք',
        ru: 'товар',
        en: "Product"
    },
    added: {
        hy: 'Ավելացվել է ցանկալիների ցուցակում',
        ru: 'добавлен в список желаний',
        en: "Product added to wish list"
    }

}
export function wishlistAddItemSuccess(product, locale) {
    toast.success(` ${translations.product[locale]}  ${product.name ? product.name : ''}  ${translations.added[locale]}`);

    return {
        type: WISHLIST_ADD_ITEM,
        product,
    };
}

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

export function wishlistAddItem(product, locale) {
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(wishlistAddItemSuccess(product, locale));
                resolve();
            }, 500);
        })
    );
}

export function wishlistRemoveItem(productId) {
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(wishlistRemoveItemSuccess(productId));
                resolve();
            }, 500);
        })
    );
}