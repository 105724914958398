import React from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ArrowRoundedDown9x6Svg, BanksSvg } from "../../svg";
import Visa from "../../images/Visa.png";

export default function MobileFooterContactUs() {
    const [storeOpen, setStoreOpen] = useState("none");

    function storeToggle() {
        if (storeOpen == "none") {
            setStoreOpen("block");
        } else {
            setStoreOpen("none");
        }
    }

    return (
        <div className="site-footer__widget p-0">
            <div className="footer-title" onClick={() => storeToggle()}>
                <span className="footer-title-fms">
                    {" "}
                    <FormattedMessage id="topbar.contact.withus" defaultMessage="Հետադարձ կապ" />
                </span>
                <span className="mobile-footer-arrow">
                    {" "}
                    <ArrowRoundedDown9x6Svg />{" "}
                </span>
            </div>

            <div style={{ display: storeOpen }} className="mobile-footer-content">
                <div className="footer-link-text">
                    <FormattedMessage id="footeraddress" defaultMessage="Ք․ Երևան, Մյասնիկյան 8" />
                </div>

                <div className="footer-link-text">
                    <FormattedMessage id="footerphone" defaultMessage="(+374) 60 11 11 11)" />
                </div>

                <div className="footer-link-text">
                    <FormattedMessage id="footerMail" defaultMessage="info@marketin.am" />
                </div>

                <div className="footer-link-text">
                    <FormattedMessage id="footerdays" defaultMessage="Երկ․-կիրակի, 09։00-18։00" />
                </div>

                <div style={{ marginTop: "12px" }}>
                    <div>
                        {" "}
                        <BanksSvg />
                    </div>
                </div>
            </div>
        </div>
    );
}
