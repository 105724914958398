import { AUTHENTICATED, CUSTOMER_TOKEN, CUSTOMER_ID, REWARDS, NAME_CUSTOMER } from "./customerActionTypes";

const initialState = {
    authenticated: false,
    token: "",
    rewards: "",
    customerId: "",
    nameCostmer: "",
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                authenticated: action.payload,
            };

        case CUSTOMER_TOKEN:
            return {
                ...state,
                token: action.payload,
            };

        case CUSTOMER_ID:
            return {
                ...state,
                customerId: action.payload,
            };

        case REWARDS:
            return {
                ...state,
                rewards: action.payload,
            };
        case NAME_CUSTOMER:
            return {
                ...state,
                nameCostmer: action.payload,
            };
        default:
            return state;
    }
};

export default customerReducer;
