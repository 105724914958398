// react
import React, { useCallback, useReducer } from "react";
import { connect } from "react-redux";
// application
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import ProductsView from "../shop/ProductsView";
import { url } from "../../helper";
import { buildQuery, reducer } from "../shop/ShopPageCategory";
import WidgetFilters from "../widgets/WidgetFilters";
import { FormattedMessage } from "react-intl";
import FilterRange from "../filters/FilterRange";
import categories from "../../data/blogWidgetCategories";
import WidgetCategories from "../widgets/WidgetCategories";
import { ArrowFilterSvg } from "../../svg";
import PageHeader from "../shared/PageHeader";

import { Helmet } from "react-helmet-async";
import { CategoryActionCreators } from "../../store/categories";
import { useLocation } from "react-router-dom";

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};
    // console.log(query.page,"QUUUUUUUUUUUUUER");


    if (typeof query.category_id === "string") {
        optionValues.category_id =
            typeof query.category_id === "number" ? parseInt(query.category_id) : query.category_id;
    }
    if (typeof query.search === "string") {
        optionValues.search = query.search;
    }
    if (typeof query.page === "string") {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);

    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}
function init(state) {
    const [options, filters] = parseQuery(window.location.search);
    return { ...state, options, filters };
}

// console.log(init().options.page,"iii");

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};


function NewCollection(props) {
    

   
 

    

    const [fill, Setfill] = useState([]);
   
    const location = useLocation()
    const [newProducts, setNewProducts] = useState();
    const [page, setPage] = useState(init().options.page || 1);
    const locale = useSelector((state) => state.locale);
    const customer = useSelector((state) => state.customer);
    const [state, idispatch] = useReducer(reducer, initialState, init);
    const dispatch = useDispatch();
    const [selectedFilters, setSelectedFilters] = useState({});
    const currency = useSelector((state) => state.currency);
    const [maxPrice, setmaxPrice] = useState(null);
    const [minPrice, setminPrice] = useState(null);
    const [dep, setdep] = useState();
    const [isActive, setActive] = useState("false");
    const categories = useSelector((state) => state.categories);
    const { categoriess } = props;

    let content;
    // useEffect(() => {
    //     fetch(`${url}/api/categories`)
    //         .then((response) => response.json())
    //         .then((res) => {
    //             // dispatch(CategoryActionCreators.addCategory(res.categories));
    //             setdep(res.categories);
    //         });
    // }, []);

    useEffect(() => {
        let filters = "";
        if (selectedFilters["price"]) {
            filters += `&price=${selectedFilters["price"]}`;
        }
        if (selectedFilters["category_id"]) {
            filters += `&category_id=${selectedFilters["category_id"]}`;
        }
        fetch(`${url}/api/products?new=1&page=${page}&currency=${currency.code}&limit=24${filters}&locale=${locale}`)
            .then((res) => res.json())
            .then((res) => {
            //   console.log(res, "res in poxoxooxoxoxoxoxoxoxooxoxoxox")
                setNewProducts(res);
                maxPrice === null && setmaxPrice(res.data[res.data.length - 2].maxPrice);
                minPrice === null && setminPrice(res.data[res.data.length - 1].minPrice);
            });
    }, [currency.code, selectedFilters, page]);

    useEffect(() => {
        const query = buildQuery(state.options, state.filters)
        const location = `${window.location.pathname}${query ? '?' : ''}${query}`
        window.history.replaceState(null, '', location)
      }, [state.options, state.filters])
    

    const handleToggle = () => {
        setActive(!isActive);
    };
    let productsView;

    if (newProducts) {
        productsView = (
            <ProductsView
                customer={customer}
                productsList={newProducts}
                options={state.options}
                filters={fill}
                dispatch={idispatch}
                page={page}
                setPage={setPage}
            />
        );
    }

    if (!newProducts) {
        productsView = <div className="block-loader__spinner" />;
    }

    const filtersPrice = () => {
        return (
            <div className="widget-filters__list filters-item">
              {console.log(maxPrice, "max priceshhjhjhhjh")}
                <p>
                    <FormattedMessage id="price-filter" defaultMessage="Price" />
                </p>
                <FilterRange
                    key={"Max Price"}
                    data={{
                        min: 0,
                        max: maxPrice ? maxPrice : "",
                    }}
                    value={selectedFilters.price !== undefined ? selectedFilters.price : ""}
                    filters={selectedFilters}
                    onChangeValue={setSelectedFilters}
                    title={"Գին"}
                    currency={currency.code}
                />
            </div>
        );
    };
    function customFunction(id) {
        setSelectedFilters({
            ...selectedFilters,
            category_id: id,
        });
    }
    const handleResetFilters = useCallback(() => {
        setSelectedFilters({});
        idispatch({ type: "RESET_FILTERS" });
    }, [idispatch]);

    content = (
        <div className="products-view__empty">
            <div className="products-view__empty-title">
                <FormattedMessage id="noMatching" defaultMessage="Համապատասխան իրեր չկան" />{" "}
            </div>
            <div className="products-view__empty-subtitle">
                <FormattedMessage id="resetting" defaultMessage="Փորձեք զրոյացնել զտիչները" />
            </div>
            <button type="button" className="btn btn-primary btn-sm" onClick={handleResetFilters}>
                <FormattedMessage id="resetFilters" defaultMessage="Վերականգնել ֆիլտրերը" />
            </button>
        </div>
    );
    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: "" },
        { title: <FormattedMessage id="newcollection" defaultMessage="New collection" />, url: "" },
    ];

    return (
        <React.Fragment>
            <Helmet>{/* <title>{`Login — ${theme.name}`}</title> */}</Helmet>
            <PageHeader breadcrumb={breadcrumb} />
            <div className="container mt-4">
                <div className="row">
                    <div className="filter-icon-fms" onClick={handleToggle}>
                        <div className="filter-fms ">
                            <span className="filter-title-fms">
                                <FormattedMessage id="filtr" defaultMessage="Ֆիլտրել" />
                            </span>
                            <ArrowFilterSvg />
                        </div>
                    </div>

                    <div
                        className={
                            isActive
                                ? "col-xl-3 col-lg-3 col-md-12 store-filters-container"
                                : "col-xl-3 col-lg-3 col-md-12 store-filters-container-on"
                        }
                    >
                        <div className="filters-container">
                            <div className="block-sidebar__item">
                                <WidgetCategories
                                    categories={categoriess.categories}
                                    selectedFilters={selectedFilters}
                                    customFunction={customFunction}
                                />
                            </div>

                            {filtersPrice()}
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-12">
                        <div className="shop-layout__content">
                            <div className="block">
                                {newProducts && newProducts.data.length <= 2 ? content : productsView}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    categoriess: state.categories,
});

// const mapDispatchToProps = {
//     cartRemoveItem,
// };

export default connect(mapStateToProps)(NewCollection);
