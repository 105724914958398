// react
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../shared/PageHeader';
import BlogPost from './BlogPost';
import theme from '../../data/theme';
import { useEffect, useState } from 'react';
import { url } from '../../helper';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

export default function BlogPagePost(props) {
    const { layout, sidebarPosition, blogSlug } = props;
    let content;
    let bogOne;
    const [blog, setBlog] = useState();
    const [redirect, setRedirect] = useState();
    const selectedData = useSelector(state => state.locale);

    useEffect(() => {
        let isCancelled = false;
        fetch(`${url}/api/cms/blog/${blogSlug}?locale=${selectedData}`)
            .then(responce => responce.json())
            .then(responce => {
                if (responce.data)
                    setBlog(responce.data)
                else
                    setRedirect(true)
            })

        return () => {
            isCancelled = true;
        };
    }, [blogSlug, selectedData]);

    if (redirect) {
        return <Redirect to={'/404'} />
    }

    content = (
        <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9 col-xl-8">
                <BlogPost blog={blog} layout={layout} />
            </div>
        </div>
    );

    const breadcrumbs = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: '' },
        { title: <FormattedMessage id="blog" defaultMessage="Բլոգ" />, url: '/blog' },
        { title: <FormattedMessage id="news" defaultMessage="Նորություններ" />, url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Blog Post Page — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumbs} />

            <div className="container">{content}</div>

        </React.Fragment>
    );
}

BlogPagePost.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

BlogPagePost.defaultProps = {
    layout: 'classic',
    sidebarPosition: 'start',
};
