// react
import React, { useEffect, useState } from "react";
// application
import FooterContacts from "./FooterContacts";
import FooterNewsletter from "./FooterNewsletter";
import ToTop from "./ToTop";

import FidemLogo from "../../images/FidemLogo.png";
import FooterLinkCenter from "./FooterLinkCenter";
// import * as PropTypes from "prop-types";
import FooterLinkRight from "./FooterLinkRight";
import { useSelector } from "react-redux";
import { LogoFSvg } from "../../svg";
import SocialLinks from "../shared/SocialLinks";
import FacebookLiveChat from "./FacebookLiveChat";


export default function Footer() {
    
    const selectedData = useSelector((state) => state.locale);
    const [navLinks, SetNavLinks] = useState();
    useEffect(() => {
        fetch(`https://api.marketin.am/api/cms/menus?locale=${selectedData}`)
            .then((response) => response.json())
            .then((res) => {
                SetNavLinks(res.data);
            });
    }, [selectedData]);

    let FooterContact;
    if (navLinks != undefined) {
        navLinks.sort(function (a, b) {
            var keyA = a.position,
                keyB = b.position;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    }
    if (navLinks) {
        FooterContact = navLinks.map((item, index) => {
            if (item.type == "footer" && item.status) {
                return (
                    <li key={index} className="footer-contacts-li">
                        <FooterContacts id={item.id} page_id={item.page_id} url_key={item.url_key} name={item.name} />
                    </li>
                );
            }
        });
    }
    let FilteredFooterContact;
    if (FooterContact) {
        FilteredFooterContact = FooterContact.filter(function (x) {
            return x !== undefined;
        });
    }
    if (!FilteredFooterContact) {
        return null;
    }

    return (
        <div className="site-footer-fms">
            <div className="container">
                <div className="footer-wrap">
                    <div>
                        <div className="site-footer__widget footer-contacts">
                            <ul className="footer-contacts__contacts">{FilteredFooterContact.splice(0, 5)}</ul>
                            <SocialLinks />
                        </div>
                    </div>
                    <div>
                        <div className="site-footer__widget footer-contacts">
                            <ul className="footer-contacts__contacts">{FilteredFooterContact}</ul>
                        </div>
                    </div>
                    <div>
                        <FooterLinkRight />
                    </div>

                    <FooterNewsletter />
                    <FacebookLiveChat />
                </div>

                <div className="line"></div>
            </div>
            <ToTop />

           
        </div>
    );
}
