import React from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ArrowRoundedDown9x6Svg } from "../../svg";
import SocialLinks from "../shared/SocialLinks";

export default function MobileFooterTerms(props) {
    const [storeOpen, setStoreOpen] = useState("none");

    function storeToggle() {
        if (storeOpen == "none") {
            setStoreOpen("block");
        } else {
            setStoreOpen("none");
        }
    }

    return (
        <div className="site-footer__widget p-0">
            <div className="footer-title" onClick={() => storeToggle()}>
                <span className="footer-title-fms">
                    <FormattedMessage id="termsAndConditions" defaultMessage="Պայմաններ և Դրույթներ" />
                </span>
                <span className="mobile-footer-arrow">
                    {" "}
                    <ArrowRoundedDown9x6Svg />{" "}
                </span>
            </div>

            <div style={{ display: storeOpen }} className="mobile-footer-content">
                <ul className="footer-contacts__contacts">{props.footercont}</ul>

                {/* <Link to="" className="footer-link-text">
                    <FormattedMessage id="termsOfPayment" defaultMessage="Վճարման պայմաններ" />
                </Link>

                <Link to="" className="footer-link-text">
                    <FormattedMessage id="termsOfSell" defaultMessage="Վաճառքի պայմաններ" />
                </Link>

                <Link to="" className="footer-link-text">
                    <FormattedMessage id="termsOfUse" defaultMessage="Օգտագործման պայմաններ" />
                </Link> */}

                <div className="footer-link-text">
                    <SocialLinks />
                </div>
            </div>
        </div>
    );
}
