// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { cartRemoveItem, cartUpdateQuantities } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";
import { urlLink } from "../../helper";

// data stubs
import theme from "../../data/theme";
import { FormattedMessage } from "react-intl";

class ShopPageCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            cartToken: this.props.cartToken,
            customer: this.props.customer,
            currency: this.props.currency,
        };
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);
        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity, cartItem) => {
        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity, cartItem });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value && x.value !== "";
            }).length > 0
        );
    }

    renderItems() {
        const { cart, cartRemoveItem } = this.props;

        return cart.items.map((item, index) => {
            let image;
            let options;
            let id;

            if (item.product.images && item.product.images.length > 0) {
                // id = cart.cartItems[0]?.cartItemId;

                image = (
                    <div className="product-image">
                        <Link to={url.product(item.product)} className="product-image__body">
                            <img
                                className="product-image__img"
                                src={urlLink + "/storage/" + item.product.images[0]}
                                alt=""
                            />
                        </Link>
                    </div>
                );
            }

            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <AsyncAction
                    action={() =>
                        cartRemoveItem(
                            item.id,
                            cart.cartItems[0]?.cartItemId,
                            this.state.cartToken,
                            this.state.customer
                        )
                    }
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            let totalPrice = `${String(item.total).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ",")} ${"֏"}`;
            // let totalPrice = <Currency value={`${item.total} ${this.state.currency.code}`} />;
            const price = `${item.price}`.indexOf(".") != -1 ? item.price.split(".")[0] : item.price;
            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image cart-image-fms">{image}</td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={url.product(item.product)} className="cart-table__product-name">
                            {item.product.name}
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--price cart-price-fms ">
                        <span className="cart--fms">
                            {" "}
                            <FormattedMessage id="price" defaultMessage="Price" />
                        </span>
                        <span className="cart-titles-fms">
                            {String(price).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ",")} {"֏"}
                        </span>
                    </td>
                    <td className="cart-table__column cart-table__column--quantity cart-quantity-fms">
                        <span className="cart--fms">
                            {" "}
                            <FormattedMessage id="quantity" defaultMessage="Quantity" />
                        </span>
                        <span className="cart-titles-fms">
                            <InputNumber
                                onChange={(quantity) =>
                                    this.handleChangeQuantity(item, quantity, cart.cartItems[index].cartItemId)
                                }
                                value={this.getItemQuantity(item)}
                                min={1}
                            />
                        </span>
                    </td>
                    <td className="cart-table__column cart-table__column--total cart-total-fms">
                        <span className="cart--fms">
                            {" "}
                            <FormattedMessage id="total" defaultMessage="Total" />
                        </span>
                        <span className="cart-titles-fms">{totalPrice}</span>
                    </td>
                    <td className="cart-table__column cart-table__column--remove cart-total-fms">{removeButton}</td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>
                            <FormattedMessage id="subtotal" defaultMessage="Subtotal" />
                        </th>
                        <td>
                            {String(cart?.subtotal).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ",")}
                            {"AMD"}
                        </td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body" />
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart, cartUpdateQuantities } = this.props;
        const { quantities } = this.state;

        const updateCartButton = (
            <AsyncAction
                action={() =>
                    cartUpdateQuantities(quantities, cart.cartItems, this.state.customer, this.state.cartToken)
                }
                render={({ run, loading }) => {
                    const classes = classNames("btn btn-primary cart__update-button", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes} disabled={!this.cartNeedUpdate()}>
                            <FormattedMessage id="update.cart" defaultMessage=" Update Cart" />
                        </button>
                    );
                }}
            />
        );

        let subTotalPrice = ` ${String(cart?.subtotal).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ",")}
        ${"֏"}`;
        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image"></th>
                                <th className="cart-table__column cart-table__column--product cart-price-fms">
                                    <FormattedMessage id="product" defaultMessage="Product" />
                                </th>
                                <th className="cart-table__column cart-table__column--price cart-th-fms">
                                    <FormattedMessage id="price" defaultMessage="Price" />
                                </th>
                                <th className="cart-table__column cart-table__column--quantity cart-th-fms">
                                    <FormattedMessage id="quantity" defaultMessage="Quantity" />
                                </th>
                                <th className="cart-table__column cart-table__column--total cart-total-fms">
                                    <FormattedMessage id="total" defaultMessage="Total" />
                                </th>
                                <th className="cart-table__column cart-table__column--remove " aria-label="Remove">
                                    <FormattedMessage id="delete" defaultMessage=" Delete" />
                                </th>
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>
                    <div className="cart__actions">
                        <div className="cart__buttons">
                            <Link to="/" className="btn btn-light">
                                <FormattedMessage id="continue.shopping" defaultMessage="Continue Shopping" />
                            </Link>
                            {updateCartButton}
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4 shop-cart-fms">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5 ">
                            <div
                                className="card"
                                style={{
                                    backgroundColor: "#EFF1F2",
                                    border: "1px solid #CCCCCC",
                                }}
                            >
                                <div className="card-body card-mob-fms">
                                    {/* <h3 className="card-title">Cart Totals</h3> */}
                                    <table className="cart__totals">
                                        <tfoot className="cart__totals-footer">
                                            <tr className="cart_total_tr_fm">
                                                <th>
                                                    <FormattedMessage id="total" defaultMessage="Total" />
                                                </th>

                                                <td>{subTotalPrice}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <Link
                                to="/checkout"
                                className="btn btn-primary btn-xl btn-block cart__checkout-button btn-chek-fm"
                            >
                                <FormattedMessage id="proceed.checkout" defaultMessage="Proceed to checkout" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: "" },
            { title: <FormattedMessage id="shopping.cart" defaultMessage="Shopping Cart" />, url: "" },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">
                                <FormattedMessage
                                    id="shopping.cart.empty"
                                    defaultMessage="Your shopping cart is empty!"
                                />
                            </div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    <FormattedMessage id="continue" defaultMessage="Continue" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Shopping Cart — ${theme.name}`}</title>
                </Helmet>

                <PageHeader
                    header={<FormattedMessage id="shopping.cart" defaultMessage="Shopping Cart" />}
                    breadcrumb={breadcrumb}
                />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    customer: state.customer,
    cartToken: state.cartToken,
    currency: state.currency,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
