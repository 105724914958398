// react
import React from 'react';

// third-party
import classNames from 'classnames';

// application
import { Check9x7Svg } from '../../svg';




function FilterCheck(props) {
    const { data, value, onChangeValue ,filterValues} = props;

    const updateValue = (data,newValue,remove) => {
        
        onChangeValue({ filter: data, value: newValue ,remove:remove});
    };
   

   
    const handleChange = (event,value) => {

  
        if (event.target.checked) {
             updateValue(value,  [event.target.value] , false );
        }
        if (!event.target.checked ) {
             updateValue(value,event.target.value,true);
        }
    };


    let fillVal; 
      if(filterValues[value] !==undefined){ 
        fillVal=  filterValues[value].split(",");
      }
        

    const itemsList = data.map((item ,index) => {
        let count;
        const itemClasses = classNames('filter-list__item', {
            // 'filter-list__item--disabled': item.count === 0,
        });
         return (
            <label key={index} className={itemClasses}>
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                    
                        <input
                            className="input-check__input"
                            type="checkbox"
                            value={item}
                       
                             checked={  fillVal ? fillVal.includes(item) : ''}
                            // disabled={checked ===item}
                            onChange={(e)=>handleChange(e,value)}
                        />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
                <span className="filter-list__title">{item}</span>
                 
            </label>
        );
    });

    return (
        <div className="filter-list">
            <div className="filter-list__list">
                {itemsList}
            </div>
        </div>
    );
}



export default FilterCheck;
