// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
// application
import MobileLinks from "./MobileLinks";
import { Cross20Svg } from "../../svg";
import { currencyChange } from "../../store/currency";
import { localeChange } from "../../store/locale";
import { mobileMenuClose } from "../../store/mobile-menu";
import { FormattedMessage } from "react-intl";
import { url } from "../../helper";
import NavLinksNew from "../header/NavLinksNew";
import { Link } from "react-router-dom";
import AppLink from "../shared/AppLink";

const lang = [
  {
    type: "button",
    label: <FormattedMessage id="languages" defaultMessage="Լեզուներ" />,
    children: [
      { type: "button", label: "English", data: { type: "language", locale: "en" } },
      { type: "button", label: "Russian", data: { type: "language", locale: "ru" } },
      { type: "button", label: "Հայերեն", data: { type: "language", locale: "hy" } },
    ],
  },
];

function MobileMenu(props) {
  const { mobileMenuState, closeMobileMenu, changeLocale } = props;
  // console.log(mobileMenuState,"CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC");
  const selectedData = useSelector((state) => state.locale);
  const pages = <FormattedMessage id="pages" defaultMessage="Էջեր" />;
  const category = <FormattedMessage id="categoies.s" defaultMessage="Ապրանքատեսականի" />;
  const locale = useSelector((state) => state.locale);

  const classes = classNames("mobilemenu", {
    "mobilemenu--open": mobileMenuState.open,
  });

  const handleItemClick = (item) => {
    if (item.data) {
      if (item.data.type === "language") {
        changeLocale(item.data.locale);
        closeMobileMenu();
      }
    }
    if (item.type === "link") {
      closeMobileMenu();
    }
  };

  const [categories, setCatgoies] = useState();
  const [navLinks, SetNavLinks] = useState();

  useEffect(() => {
    // old version of code
    // fetch(`${url}/api/categories`)
    fetch(`${url}/api/categories?locale=${locale}`)
      .then((response) => response.json())
      .then((res) => {
        setCatgoies([{ label: category, children: res.categories }]);
      });
  }, []);

  useEffect(() => {
    fetch(`${url}/api/cms/menus?locale=${selectedData}`)
      .then((response) => response.json())
      .then((res) => {
        SetNavLinks([{ label: pages, childs: res.data?.filter((e) => e.name != "Blog" && e.type != "footer") }]);
      });
  }, [selectedData]);

  let NewItems = categories && categories[0].children[0].children;

  return (
    <>
      <div className={classes}>
        <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
        <div className="mobilemenu__body">
          <div className="mobilemenu__header">
            <div className="mobilemenu__title">
              <FormattedMessage id="menu" defaultMessage=" Menu" />
            </div>
            <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
              <Cross20Svg />
            </button>
          </div>
          <div className="mobilemenu__content">
            <div className="mobile_item-link-fms" style={{backgroundColor:"#161616",paddingTop:15}}>
              <Link to="/stores" className="mobile-links__item-link" onClick={() => closeMobileMenu()}>
                <span style={{color:"white"}}>
                  <FormattedMessage id="stores" defaultMessage="Stores" />
                </span>
              </Link>
            </div>
            <MobileLinks links={NewItems} onItemClick={closeMobileMenu} />
            {navLinks !== undefined ? <MobileLinks links={navLinks} onItemClick={closeMobileMenu} /> : ""}

            <div className="mobile_item-link-fms">
              <Link className="mobile-links__item-link " to="/blog" onClick={() => closeMobileMenu()}>
                <span>
                  <FormattedMessage id="blogs" defaultMessage="Blogs" />
                </span>
              </Link>
            </div>

            {/* Transfer static items from MobileLinks.jsx component */}


            <div className="mobile_item-link-fms ">
              <Link to="/shop/newCollection" className="mobile-links__item-link " onClick={() => closeMobileMenu()}>
                <span>
                  <FormattedMessage id="newcollection" defaultMessage="New Collection" />
                </span>
              </Link>
            </div>
            <div className="mobile_item-link-fms ">
              <Link to="/shop/saledProducts" className="mobile-links__item-link" onClick={() => closeMobileMenu()}>
                <span>
                  <FormattedMessage id="saledProducts" defaultMessage="Saled Products" />
                </span>
              </Link>
            </div>


            {/* //////////////////////////////////////////////////////////// */}

            {/* <MobileLinks links={lang} onItemClick={handleItemClick} /> */}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
  closeMobileMenu: mobileMenuClose,
  changeLocale: localeChange,
  changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
