// react
import React from "react";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";

// // third-party
// import { Helmet } from "react-helmet-async";

// // data stubs
// import theme from "../../data/theme";
// import { url } from "../../helper";
import { FormattedMessage } from "react-intl";

export default function AccountPageBonuses() {
    const customer = useSelector((state) => state.customer);
    return (
        <div className="profile-user-bonuses">
            {customer.rewards > 0 ? (
                <FormattedMessage id="accumulated.bonuses" defaultMessage="You'r accumulated bonuses is     " />
            ) : (
                <FormattedMessage id="accumulated.not.bonuses" defaultMessage={`You have not accumulated bonuses`} />
            )}

            <span className="profile-user-bonuses-fms">{customer.rewards}</span>
        </div>
    );
}
