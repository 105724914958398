// react
import React from "react";
import { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import Fb from "../../images/fb.png";
import Youtube from "../../images/youtube.png";
import twitter from "../../images/twitter.png";
import { url } from "../../helper";
import theme from "../../data/theme";

function SocialLinks(props) {
    const { shape, className } = props;
    const [social, getSocial] = useState();
    let items;
    useEffect(() => {
        fetch(`${url}/api/social`)
            .then((res) => res.json())
            .then((data) => {
                if (data && data) getSocial(data);
            });
    }, []);

    const classes = classNames(className, "social-links", {
        "social-links--shape--circle": shape === "circle",
        "social-links--shape--rounded": shape === "rounded",
    });

    // const items = [
    //     { type: "facebook", url: theme.fb, icon: <img src={Fb} alt="" /> },
    //     { type: "twitter", url: theme.fb, icon: <img src={twitter} alt="" /> },
    //     { type: "instagram", url: theme.instagram, icon: <img src={Youtube} alt="" /> },
    // ].map((item) => (
    //     <li key={item.type} className="social-links__item">
    //         <a
    //             className={`social-links__link social-links__link--type--${item.type}`}
    //             href={item.url}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //         >
    //             {item.icon}
    //         </a>
    //     </li>
    // ));

    if (social) {
        items = social.map((item, index) => {
            return (
                <div key={index} className="social-items-fms">
                    <div className="social-links__item">
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                            <img src={`${url}/storage/icons/${item.icon}`} />
                        </a>
                    </div>
                </div>
            );
        });
    }

    return (
        <div>
            <ul className="social-links__list">{items}</ul>
        </div>
    );
}

SocialLinks.propTypes = {
    /**
     * rating value
     */
    shape: PropTypes.oneOf(["circle", "rounded"]),
    className: PropTypes.string,
};
SocialLinks.defaultProps = {
    shape: null,
};

export default SocialLinks;
