// react
import React, { useEffect, useReducer, useState, useCallback, useMemo } from 'react'

// third-party
import PropTypes                from 'prop-types'
import queryString              from 'query-string'
import { connect, useSelector } from 'react-redux'
import { Helmet }               from 'react-helmet-async'
import { FormattedMessage }     from 'react-intl'

// application
import { useLocation, useHistory, useParams } from 'react-router-dom'
import BlockLoader                            from '../blocks/BlockLoader'
import CategorySidebar                        from './CategorySidebar'
import CategorySidebarItem                    from './CategorySidebarItem'
import PageHeader                             from '../shared/PageHeader'
import ProductsView                           from './ProductsView'
import shopApi                                from '../../api/shop'
import WidgetFilters                          from '../widgets/WidgetFilters'
import { sidebarClose }                       from '../../store/sidebar'
import categories                             from '../../data/blogWidgetCategories'
import WidgetCategories                       from '../widgets/WidgetCategories'

// data stubs
import theme   from '../../data/theme'
// import { url } from '../../services/utils';
import { url } from '../../helper'

import { getProductsList } from '../../fake-server/endpoints/products'

import bgImage from '../../images/slideHome.png'


// console.log(window.location, "seeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
function parseQueryOptions (location) {

  
  const query = queryString.parse(location)
  const optionValues = {}
  // console.log(query.page,"QUUUUUUUUUUUUUER");
  

  if (typeof query.category_id === 'string') {
    optionValues.category_id =
      typeof query.category_id === 'number' ? parseInt(query.category_id) : query.category_id
  }
  if (typeof query.search === 'string') {
    optionValues.search = query.search
  }
  if (typeof query.page === 'string') {
    optionValues.page = parseFloat(query.page)
  }
  if (typeof query.limit === 'string') {
    optionValues.limit = parseFloat(query.limit)
  }
  if (typeof query.sort === 'string') {
    optionValues.sort = query.sort
  }
// console.log("option values", option.values)
  return optionValues
}




export function parseQueryFilters (location) {
  const query = queryString.parse(location)
  const filterValues = {}

  Object.keys(query).forEach((param) => {
    const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/)

    if (!mr) {
      return
    }

    const filterSlug = mr[1]

    filterValues[filterSlug] = query[param]
  })

  return filterValues
}


export function parseQuery (location) {
  return [parseQueryOptions(location), parseQueryFilters(location)]
}


 export function buildQuery (options, filters) {
  const params = {}

  if (options.page !== 1) {
    params.page = options.page
  }

  if (options.limit !== 6) {
    params.limit = options.limit
  }

  if (options.search !== '') {
    params.search = options.search
  }
  params.category_id = options.category_id

  if (options.sort !== 'default') {
    params.sort = options.sort
  }

  Object.keys(filters)
    .filter((x) => x !== 'category' && !!filters[x])
    .forEach((filterSlug) => {
      params[`filter_${filterSlug}`] = filters[filterSlug]
    })

  return queryString.stringify(params, { encode: false })
}


const initialState = {
  init: false,
  /**
   * Indicates that the category is loading.
   */
  categoryIsLoading: true,
  /**
   * Category object.
   */
  category: null,
  /**
   * Indicates that the products list is loading.
   */
  productsListIsLoading: true,
  /**
   * Products list.
   */
  productsList: null,
  /**
   * Products list options.
   *
   * options.page:  number - Current page.
   * options.limit: number - Items per page.
   * options.sort:  string - Sort algorithm.
   */
  options: {},
  /**
   * Products list filters.
   *
   * filters[FILTER_SLUG]: string - filter value.
   */
  filters: {},
}


export function reducer (state, action) {
  switch (action.type) {
    case 'FETCH_CATEGORY_SUCCESS':
      return {
        ...state,
        init             : true,
        categoryIsLoading: false,
        category         : action.category,
      }
    case 'FETCH_PRODUCTS_LIST':
      return { ...state, productsListIsLoading: true }
    case 'FETCH_PRODUCTS_LIST_SUCCESS':
      return { ...state, productsListIsLoading: false, productsList: action.productsList }
    case 'SET_OPTION_VALUE':
      return {
        ...state,
        options: { ...state.options, page: 1, [action.option]: action.value },
      }
    case 'SET_FILTER_VALUE':
      return {
        ...state,
        options: { ...state.options, page: 1 },
        filters: {
          ...state.filters,
          [action.filter]:
            state.filters[action.filter] && action.filter !== 'max_price'
              ? state.filters[action.filter] + (action.value ? `,${action.value}` : '')
              : action.value,
        },
      }

    case 'REMOVE_FILTER_VALUE':
      let dot = state.filters[action.filter].split(',')
      const index = dot.indexOf(action.value)
      if (index > -1) {
        dot.splice(index, 1)
      }
      dot = dot.join(',')

      return {
        ...state,
        options: { ...state.options, page: 1 },
        filters: { ...state.filters, [action.filter]: dot },
      }
    case 'RESET_FILTERS':
      return { ...state, options: { ...state.options, page: 1 }, filters: {} }
    case 'RESET':
      return state.init ? initialState : state
    default:
      throw new Error()
  }
}


function init (state) {
  const [options, filters] = parseQuery(window.location.search)
  // console.log('options', options, filters)
  return { ...state, options, filters }
}



const styless = {
  minHeight         : '250px',
  backgroundImage   : `url(${bgImage})`,
  backgroundPosition: 'center',
  backgroundRepeat  : 'no-repeat',
  backgroundSize    : 'cover',
  display           : 'flex',
  justifyContent    : 'center',
  alignItems        : 'center',
}

const Shop = {
  hy: 'Խանութ',
  en: 'Shop',
  ru: 'Магазин',
}


function ShopPageCategory (props) {
  const { categorySlug, columns, viewMode, sidebarPosition } = props

  const history = useHistory()
  const location = useLocation()
  const offcanvas = columns === 3 ? 'mobile' : 'always'
  const [state, dispatch] = useReducer(reducer, initialState, init)
  const selectedData = useSelector((state) => state.locale)
  const customer = useSelector((state) => state.customer)
  const categ = useSelector((state) => state.categories.categories)
  const [fill, Setfill] = useState([])
  const [currentCategoryObject, setCurrentCategoryObject] = useState(null)
  const getFilters = getProductsList()
  const [categories, setCategories] = useState([])
  const locale = useSelector((state) => state.locale)
  const [filtersData, setFilters] = useState()
  const [selectedFilters, setSelectedFilters] = useState({})
  const [dep, setdep] = useState()
  // const [currentPage,setCurrentPage] = useState()
  const [page, setPage] = useState(init().options.page || 1)
 

  const currency = useSelector((state) => state.currency)
  const filterTitle = <FormattedMessage id="filters" defaultMessage="Ֆիլտրեր"/>
  let content

  useEffect(() => {
    if(categ.length > 0) {
      setdep(categ)
      setCategories(categ)
    } else {
      shopApi.getCategories({ locale })
        .then((response) => {
          setdep(response.categories)
          setCategories(response.categories)
        })
    }

  }, [])


  function customFunction (id) {
    setSelectedFilters({
      ...selectedFilters,
      category_id: id,
    })
  }


  const handleResetFilters = useCallback(() => {
    setSelectedFilters({})
    dispatch({ type: 'RESET_FILTERS' })
  }, [dispatch])


  function getCatalogBySlug (array) {
    // need to do optimization
    for (let i = 0; i < array.length; i++) {
      if (array[i].slug === categorySlug) {
        return array[i]
      } else {
        const secondLevel = array[i].children
        for (let j = 0; j < secondLevel.length; j++) {
          if (secondLevel[j].slug === categorySlug) {
            return secondLevel[j]
          } else {
            const thirdLevel = secondLevel[j].children
            for (let k = 0; k < thirdLevel.length; k++) {
              if (thirdLevel[k].slug === categorySlug) {
                return thirdLevel[k]
              } else {
                const fourthLevel = thirdLevel[k].children
                for (let s = 0; s < fourthLevel.length; s++) {
                  if (fourthLevel[s].slug === categorySlug) {
                    return fourthLevel[s]
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  // useEffect(() => {
  //   fetch(`${url}/api/filters`)
  //     .then((responce) => responce.json())
  //     .then((e) => {
  //       if (e) {
  //         setFilters(e)
  //       } else {
  //         setFilters(e)
  //       }
  //     })
  // }, [])
  const query = queryString.parse(location)
  useEffect(() => {
    if (currentCategoryObject) {
      // console.log('currentCategoryObject', currentCategoryObject)
      dispatch({ type: 'FETCH_PRODUCTS_LIST' })
      fetch(
        `${url}/api/products?locale=${locale}&category_id=${
          selectedFilters?.category_id || currentCategoryObject?.id
        }&limit=24&page=${page}&currency=${currency.code}`,
      )
        .then((res) => res.json())
        .then((productsList) => {

   
          // console.log(current_page,"PRODUIST");
          // setCurrentPage(productsList.current_page)
          dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList })
        })
    }
  }, [selectedFilters?.category_id, page, currentCategoryObject?.id, selectedData, currency])

  useEffect(() => {
    if (dep) {
      let cat = getCatalogBySlug(dep[0].children)

      setCurrentCategoryObject(cat)
    }
  }, [categorySlug, dep])

  let Slug = ''

  if (state.category) {
    Slug = state.category.translations.find((item) => item.locale === selectedData).name
  }

  const head = useMemo(
    () => (
      <div style={styless} className="container-fluid">
        <h2>{Slug || Shop[selectedData]}</h2>
      </div>
    ),
    [state.category, selectedData],
  )

  useEffect(() => {
    const query = buildQuery(state.options, state.filters)
    const location = `${window.location.pathname}${query ? '?' : ''}${query}`
    window.history.replaceState(null, '', location)
  }, [state.options, state.filters])

  // Replace current url.
  // useEffect(() => {
  //
  //     const query = buildQuery(state.options, state.filters);
  //     const location = `${window.location.pathname}${query ? '?' : ''}${query}`;
  //     window.history.replaceState(null, '', location);
  //       history.replace({
  //             // pathname: '/shop/catalog',
  //            search: query
  //         })
  //
  // }, [state.options, state.filters]);

  // Load category.

  // useEffect(() => {
  //     let request;
  //     let canceled = false;
  //
  //     // dispatch({ type: 'RESET', categorySlug });
  //     dispatch({ type: 'RESET', paramSlug });
  //
  //
  //
  //     // if (categorySlug) {
  //     if (paramSlug) {
  //         // request = shopApi.getCategoryBySlug(categorySlug);
  //         request = shopApi.getCategoryBySlug(paramSlug);
  //     } else {
  //         request = Promise.resolve(null);
  //     }
  //
  //     request.then((category) => {
  //         if (canceled) {
  //             return;
  //         }
  //         dispatch({ type: 'FETCH_CATEGORY_SUCCESS', category });
  //     });
  //
  //     return () => {
  //         canceled = true;
  //     };
  // }, [dispatch, paramSlug]);

  // Load products.

  if (state.productsListIsLoading && !state.productsList) {
    return <BlockLoader/>
  }

  console.log(state);

  const productsView = (
    <ProductsView
      isLoading={state.productsListIsLoading}
      customer={customer}
      productsList={state.productsList.products || state.productsList}
      length={state.productsList.length}
      options={state.options}
      filters={fill}
      dispatch={dispatch}
      layout={viewMode}
      // grid={`grid-${columns}-${columns > 3 ? 'full' : 'sidebar'}`}
      offcanvas={offcanvas}
      page={page}
      setPage={setPage}
    />
  )

  const sidebarComponent = (
    <WidgetCategories categories={dep} selectedFilters={selectedFilters} customFunction={customFunction}/>
  )

  if (state.productsList.data.length === 0) {
    content = (
      <div className="products-view__empty">
        <div className="products-view__empty-title">
          <FormattedMessage id="noMatching" defaultMessage="Համապատասխան իրեր չկան"/>{' '}
        </div>
        <div className="products-view__empty-subtitle">
          <FormattedMessage id="resetting" defaultMessage="Փորձեք զրոյացնել զտիչները"/>
        </div>
        <button type="button" className="btn btn-primary btn-sm" onClick={handleResetFilters}>
          <FormattedMessage id="resetFilters" defaultMessage="Վերականգնել ֆիլտրերը"/>
        </button>
      </div>
    )
  } else {
    const sidebar = <div className="shop-layout__sidebar">{sidebarComponent}</div>
    content = (
      <React.Fragment>
        <div className="container mt-5">
          <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
            {sidebarPosition === 'start' && sidebar}
            <div className="shop-layout__content">
              <div className="block category-flex-fm">{productsView}</div>
            </div>
            {sidebarPosition === 'end' && sidebar}
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Shop Category Page — ${theme.name}`}</title>
        <meta name="keywords" content={categorySlug || 'Category'}/>
        <meta name="description" content={categorySlug || 'Category'}/>
        <meta property="og:title" content={theme.name}/>
        <meta property="og:description" content={categorySlug || 'Category'}/>
        <meta
          property="og:image"
          content="https://api.marketin.am/cache/large/product/163/0CR5Q3MvAQD7YOQCMI74Tqo4fbUh3jjCXlBI4Jae.png"
        />
      </Helmet>

      {/* <PageHeader breadcrumb={breadcrumb} /> */}

      {content}
    </React.Fragment>
  )
}


ShopPageCategory.defaultProps = {
  columns        : 3,
  viewMode       : 'grid',
  sidebarPosition: 'start',
}

const mapStateToProps = (state) => ({
  sidebarState: state.sidebar,
  page        : state.category,
})

const mapDispatchToProps = () => ({
  sidebarClose,
})

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory)
