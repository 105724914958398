import {
    EMPTY_CART,
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_DELETE_ALL,
} from "./cartActionTypes";

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find(
                (itemOption) => itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            );

            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
}

//subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);

function addItem(state, product, options, quantity, CartItemID) {
    const cartItems = CartItemID?.items.map((pr) => {
        return {
            cartItemId: pr.id,
            productID: pr.product.id,
        };
    });

    const itemIndex = findItemIndex(state.items, product, options);

    let newItems;
    let { lastItemId } = state;

    //  product.CartItems=CartItems

    const price =
        product.special_price == "0.0000" || product.special_price == null ? product.price : product.special_price;

    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [
            ...state.items,
            {
                id: lastItemId,

                product: JSON.parse(JSON.stringify(product)),
                options: JSON.parse(JSON.stringify(options)),
                price: price,
                total: price * quantity,
                quantity,
            },
        ];
    } else {
        const item = state.items[itemIndex];
        const price = item.special_price == null ? item.price : item.special_price;

        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * price,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        lastItemId,
        subtotal,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
        cartItems: cartItems,
    };
}

function removeItem(state, itemId, item) {
    const { items, cartItems } = state;

    const newCartItems = cartItems.filter((it) => it.cartItemId !== item);
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);
    return {
        ...state,
        items: newItems,
        cartItems: newCartItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function removeAllItems(state) {
    return {
        ...state,
        items: [],
        quantity: 0,
        subtotal: 0,
        total: 0,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.extraLines);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
        };
    }

    return state;
}

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    subtotal: 0,
    extraLines: [
        // shipping, taxes, fees, .etc
        {
            type: "shipping",
            title: "Shipping",
            price: 25,
        },
        {
            type: "tax",
            title: "Tax",
            price: 0,
        },
    ],
    total: 0,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case EMPTY_CART:

        case CART_ADD_ITEM:
            return addItem(state, action.product, action.options, action.quantity, action.cartItems);

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemId, action.item);

        case CART_DELETE_ALL:
            return removeAllItems(state);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.quantities);

        default:
            return state;
    }
}
