// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import Indicator from "./Indicator";
import { Cart20Svg, Cross10Svg } from "../../svg";
import { cartRemoveItem } from "../../store/cart";
import { url } from "../../services/utils";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { urlLink } from "../../helper";

function IndicatorCart(props) {
    const cartToken = useSelector((state) => state.cartToken);
    const customer = useSelector((state) => state.customer);
    const { cart, cartRemoveItem } = props;
    const [open, setOpen] = useState(false);
    const currency = useSelector((state) => state.currency);

    let dropdown;
    let totals;

    const items = cart.items.map((item) => {
        // let options;
        let image;
        let cartItemId;

        for (var i = 0; i < cart.items.length; i++) {
            if (cart.items[i].productID === item.product.id) {
                cartItemId = cart.items[i].cartItemId;
                break;
            }
        }

        // if (item.product.images && item.product.images.length) {
        if (true) {
            let im = item.product.images[0]
                ? urlLink + "/storage/" + item.product.images[0]
                : item.product.base_image.medium_image_url;

            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(item.product)} className="dropcart-image product-image__body">
                        <img className="product-image__img" src={im} alt="no image" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id, cart.cartItems[0]?.cartItemId, cartToken, customer)}
                render={({ run, loading }) => {
                    const classes = classNames("dropcart__product-remove btn btn-light btn-sm btn-svg-icon", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        let price;
        if (item.product.formated_price) {
            price = item.product.formated_price;
        } else if (item.product.special_price == null) {
            price = item.product.formatted_price;
        } else if (item.product.special_price !== "0.0000") {
            price = item.product.formatted_special_price;
        } else if (item.product.special_price == "0.0000") {
            price = item.product.formatted_price;
        }

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item.product)}>{item.product.name}</Link>
                    </div>
                    {/* {options} */}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {"  "}
                        <span className="dropcart__product-price">
                            {String(price).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ",")}
                        </span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {

       
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">{items}</div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th>
                                    <FormattedMessage id="total" defaultMessage="Ընդհանուր" />{" "}
                                </th>
                                <td>
                                    {String(cart?.subtotal).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ",")}
                                    {"֏"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/cart" onClick={() => setOpen(!open)}>
                        <FormattedMessage id="viewcart" defaultMessage="Զամբյուղը" />{" "}
                    </Link>
                    <Link className="btn btn-primary" to="/checkout" onClick={
                        () => {
                            setOpen(!open);
                            window.fbq('track', 'CheckoutPage')
                        }
                        
                        
                        
                        }>
                        <FormattedMessage id="checkout" defaultMessage="Վճարում" />{" "}
                    </Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    <FormattedMessage id="cartEmpty" defaultMessage="Ձեր զամբյուղը դատարկ է!" />
                </div>
            </div>
        );
    }

    const func = (bool) => {
        setOpen(bool);
    };

    return (
        <Indicator
            url="/shop/cart"
            func={func}
            openEd={open}
            open={open}
            dropdown={dropdown}
            value={cart.quantity}
            icon={<Cart20Svg />}
        />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);

// useEffect(() => {
//     fetch(urlLink + `/api/checkout/cart?api_token=${cartToken.cartToken}&currency=${currency.code}`)
//         .then((responce) => responce.json())
//         .then((res) => {
//             if (res) {
//                 setProductList(res?.data?.items);
//             }
//         });
// }, [cart, currency]);

// const items = cart.items.map((item) => {
//     // let options;
//     let image;
//     let cartItemId;

//     for (var i = 0; i < cart?.cartItems?.length; i++) {
//         if (cart.cartItems[i].productID === item.product.id) {
//             cartItemId = cart.cartItems[i].cartItemId;
//             break;
//         }
//     }
//     console.log(item, "ppp");
//     // if (item.product.images && item.product.images.length) {
//     if (true) {
//         let im = item.product.images[0]
//             ? urlLink + "/storage/" + item.product.images[0]
//             : item.product.base_image.medium_image_url;

//         image = (
//             <div className="product-image dropcart__product-image">
//                 <Link to={url.product(item.product)} className="dropcart-image product-image__body">
//                     <img className="product-image__img" src={im} alt="no image" />
//                 </Link>
//             </div>
//         );
//     }

//     const removeButton = (
//         <AsyncAction
//             action={() => cartRemoveItem(item.id, cartItemId, cartToken, customer)}
//             render={({ run, loading }) => {
//                 const classes = classNames("dropcart__product-remove btn btn-light btn-sm btn-svg-icon", {
//                     "btn-loading": loading,
//                 });

//                 return (
//                     <button type="button" onClick={run} className={classes}>
//                         <Cross10Svg />
//                     </button>
//                 );
//             }}
//         />
//     );

//     // let price;
//     // if (productList.formated_price) {
//     //     price = productList.formated_price;
//     // } else if (productList.special_price == null) {
//     //     price = productList.formatted_price;
//     // } else if (productList.special_price !== "0.0000") {
//     //     price = productList.formatted_special_price;
//     // } else if (productList.special_price == "0.0000") {
//     //     price = productList.formatted_price;
//     // }

//     return (
//         <div key={item.id} className="dropcart__product">
//             {image}
//             <div className="dropcart__product-info">
//                 <div className="dropcart__product-name">
//                     <Link to={url.product(item.product)}>{item.product.name}</Link>
//                 </div>
//                 {/* {options} */}
//                 <div className="dropcart__product-meta">
//                     <span className="dropcart__product-quantity">{item.quantity}</span>
//                     {" × "}
//                     <span className="dropcart__product-price">
//                         {/* {productList.map((price) => {
//                             return <Currency value={price.product.formatted_price} />;
//                         })} */}

//                         {/*  */}
//                     </span>
//                 </div>
//             </div>
//             {removeButton}
//         </div>
//     );
// });

// let totalCurrencyCode;
//     if (cart.items.length && cart.items[0].product.formatted_price[0] == "A") {
//         totalCurrencyCode = "AMD";
//     } else if (cart.items.length && cart.items[0].product.formatted_price[0] == "U") {
//         totalCurrencyCode = "USD";
//     } else if (cart.items.length && cart.items[0].product.formatted_price[0] == "R") {
//         totalCurrencyCode = "RUB";
//     } else {
//         totalCurrencyCode = "";
//     }
//     let totalPrice = String(cart?.subtotal).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ",");
