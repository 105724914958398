import React from "react";
import { FormattedMessage } from "react-intl";

function BlockHeaderCustom(props) {
  return (
    <div className={props.wbc ? "block-header without__bg__color" : "block-header"}>
      <h3 className="block-header__title__custom">
        <FormattedMessage id={props.id} defaultMessage={props.title || "Product"} />
      </h3>
    </div>
  );
}

export default BlockHeaderCustom;
