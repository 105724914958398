// react
import React, { useState, useEffect, useReducer } from "react";
import { url } from "../../helper";

import ProductsView from "./ProductsView";
import { useSelector } from "react-redux";
import { reducer } from "./ShopPageCategory";
import queryString from "query-string";
import { connect } from "react-redux";
import FilterRange from "../filters/FilterRange";
import { ArrowDown } from "../../svg";
import { FormattedMessage } from "react-intl";
import PageHeader from "../shared/PageHeader";
import { url as RedirectUrl } from "../../services/utils";
function StoreInnerPage(props) {
  const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
  };

   function buildQuery (options, filters) {
    const params = {}

    if (options.page !== 1) {
      params.page = options.page
    }

    if (options.limit !== 6) {
      params.limit = options.limit
    }

    if (options.search !== '') {
      params.search = options.search
    }
    params.category_id = options.category_id

    if (options.sort !== 'default') {
      params.sort = options.sort
    }

    Object.keys(filters)
      .filter((x) => x !== 'category' && !!filters[x])
      .forEach((filterSlug) => {
        params[`filter_${filterSlug}`] = filters[filterSlug]
      })

    return queryString.stringify(params, { encode: false })
  }

  function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.category_id === "string") {
      optionValues.category_id =
        typeof query.category_id === "number" ? parseInt(query.category_id) : query.category_id;
    }
    if (typeof query.search === "string") {
      optionValues.search = query.search;
    }
    if (typeof query.page === "string") {
      optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
      optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
      optionValues.sort = query.sort;
    }

    return optionValues;
  }

  function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
      const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

      if (!mr) {
        return;
      }

      const filterSlug = mr[1];

      filterValues[filterSlug] = query[param];
    });

    return filterValues;
  }

  function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
  }

  function init(state) {
    const [options, filters] = parseQuery(window.location.search);
    return { ...state, options, filters };
  }
  const customer = useSelector((state) => state.customer);
  const currency = useSelector((state) => state.currency);
  const locale = useSelector((state) => state.locale);
  const [fill, Setfill] = useState([]);
  const [filters, setFilters] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState, init);

  const [page, setPage] = useState(init()?.options.page || 1);
  const { storeId, isSidebarOpen } = props;
  const [brandProducts, setBrandProducts] = useState();
  const [store, setStore] = useState();
  const [maxPrice, setmaxPrice] = useState(null);
  const [minPrice, setminPrice] = useState();
  const [isOpen, setIsOpen] = useState("fm-closed");
  const [isOpenFilter, setIsOpenFilter] = useState("fm-closedFilter");
  const [isOpenFilterSize, setIsOpenFilterSize] = useState("fm-closedFilter");
  const [isOpenFilterAlt, setIsOpenFilterAlt] = useState("fm-closedFilter");
  const [isOpenArrow, setIsOpenArrow] = useState(false);
  const [isOpenArrowColor, setIsOpenArrowColor] = useState(false);
  const [isOpenArrowSize, setIsOpenArrowSize] = useState(false);
  const [isOpenArrowALt,setIsOpenArrowAlt] = useState(false)
  function fmopen() {
    if (isOpen == "fm-closed") {
      setIsOpen("fm-Open");
    } else {
      setIsOpen("fm-closed");
    }
  }
  function fmopenFilters(e) {
    if (e == "color") {
      setIsOpenArrowColor(!isOpenArrowColor);
      if (isOpenFilter == "fm-closedFilter") {
        setIsOpenFilter("fm-OpenFilter");
      } else {
        setIsOpenFilter("fm-closedFilter");
      }
    } else if (e == "size") {
      setIsOpenArrowSize(!isOpenArrowSize);
      if (isOpenFilterSize == "fm-closedFilter") {
        setIsOpenFilterSize("fm-OpenFilter");
      } else {
        setIsOpenFilterSize("fm-closedFilter");
      }
    } else {
      setIsOpenArrowAlt(!isOpenArrowALt)
      if (isOpenFilterAlt == "fm-closedFilter") {
        setIsOpenFilterAlt("fm-OpenFilter");
      } else {
        setIsOpenFilterAlt("fm-closedFilter");
      }
    }
  }

  let products;

  useEffect(() => {
    fetch(`${url}/api/filters`)
      .then((respones) => respones.json())
      .then((res) => setFilters(res.data.filter));
  }, [storeId]);

  useEffect(() => {
    const query = buildQuery(state.options, state.filters)
    const location = `${window.location.pathname}${query ? '?' : ''}${query}`
    window.history.replaceState(null, '', location)
  }, [state.options, state.filters])


  useEffect(() => {
    // fetch(`${url}/api/filters`)
    //     .then((responce) => responce.json())
    //     .then((e) => {
    //         if (e.data) {
    //             setFilters(e.data.filter);
    //         } else {
    //             setFilters(e.filter);
    //         }

    let filters = "";
    // if (selectedFilters["color"]) {
    //   filters += `&color=${selectedFilters["color"]}`;
    // }
    // if (selectedFilters["size"]) {
    //   filters += `&size=${selectedFilters["size"]}`;
    // }
    // if (selectedFilters["price"]) {
    //   filters += `&price=${selectedFilters["price"]}`;
    // }
    // if (selectedFilters["cleaningproducts"]) {
    // //   console.log(selectedFilters["cleaningproducts"], "QWWEE");
    //   filters += `&cleaningproducts=${selectedFilters["cleaningproducts"]}`;
    // }

    for (const [key, value] of Object.entries(selectedFilters)) {

      filters += `&${key}=${value}`;
    }
;
    fetch(
      `${url}/api/market/products?slug=${storeId}&locale=${locale}&currency=${currency.code}&limit=24&page=${page}&type=simple${filters}`
    )
      .then((res) => res.json())
      .then((res) => {
        //   console.log(res, "res in new collection")
        setBrandProducts(res[0]);
        setStore(res[1]);
        setmaxPrice(res[2].maxPrice);

        setminPrice(res[3].minPrice);

        setBrandProducts(res[0]);
        setStore(res[1]);
        setmaxPrice(res[2].maxPrice);
        setminPrice(res[3].minPrice);
      });
  }, [storeId, currency.code, locale, selectedFilters, page]);

  // console.log(brandProducts,"BAREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEv");
  if (brandProducts) {
    products = (
      <ProductsView
        customer={customer}
        productsList={brandProducts}
        options={state.options}
        filters={fill}
        dispatch={dispatch}
        clearSelectedFilters={setSelectedFilters}
        page={page}
        setPage={setPage}
      />
    );
  }
  if (!brandProducts) {
    products = <div className="block-loader__spinner" />;
  }

  function handleAddFilterId(name, id) {
    const strName = `${name}`.toLowerCase();
    // console.log(name,"prive");
    if (selectedFilters[strName]) {
      const oldSelected = selectedFilters[strName];
      if (oldSelected == id) {
        delete selectedFilters[strName];
        setSelectedFilters({
          ...selectedFilters,
        });
      } else {
        setSelectedFilters({
          ...selectedFilters,
          [strName]: id,
        });
      }
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [strName]: id,
      });
    }

    // console.log(selectedFilters,"selectedFilters");
  }
  function cases(cases, options) {
    // console.log(selectedFilters,"AAAAAAAAAA");
    // console.log(cases,"CCCCCCCCCC");
    // console.log(selectedFilters[cases.toLowerCase()],"BBBBBBBBBB");
    switch (cases) {
      case "color":
        return options.map((e, i) => {
          // console.log(e,"qwewqeqweqwqwe");
          const active = selectedFilters[cases] == e.id;
          return (
            <div
              className={`checkbox ${cases}  ${active ? "active" : ""} `}
              style={{ background: `${e.swatch_value}` }}
              onClick={() => handleAddFilterId(cases, e.id)}
            >
              {active ? <span>&#10003;</span> : null}
            </div>
          );
        });
        break;
      case "size":
        return options.map((e, i) => {
          const active = selectedFilters[cases] == e.id;

          // console.log(e.admin_name.length,"e.admin_name.lengthe.admin_name.lengthe.admin_name.length")
          return e.admin_name.length > 3 ? (
            <div
              className={`checkboxbig ${cases} ${active ? "active-size" : ""}`}
              onClick={() => handleAddFilterId(cases, e.id)}
            >
              {e.admin_name}
            </div>
          ) : (
            <div
              className={`checkbox ${cases} ${active ? "active-size" : ""}`}
              onClick={() => handleAddFilterId(cases, e.id)}
            >
              {e.admin_name}
            </div>
          );
        });
        break;
      default:
        return options.map((e, i) => {

          const active = selectedFilters[cases.toLowerCase()] == e.id;
          // console.log(e,"AAAAAAAAAAAAAQQQQQQQQQQQQQQVVVVVVVVVVVVVVVVVV");
          // console.log(active,"active");
          // console.log(selectedFilters,"alo");
          return (
            <div
              className={`checkbox ${cases}  ` }
              onClick={() => handleAddFilterId(cases, e.id)}

            >
              <div >
              <img width={20} height={20}  style={{border: active ? "1px solid red " :"none"}} className={`checkbox ${cases}`} src={`http://api.marketin.am/storage/${e.swatch_value}`} alt="" />

              </div>

              {/* {active ? <span style={{color:"yellow",fontSize:"25px",fontWeight:900,zIndex:9999}}>&#10003;</span> : null} */}
            </div>
          );
        });
    }
  }

  ///console.log(currency.code);
  const filtersHtml = () => {

    console.log(filters,"AAAAAAAAAAAAAAAAAAAAAAAAAAAA");
    return (
      filters &&
      filters.map((e, i) => {

        const name = `${e.name}`.toLowerCase();
        return (
          <div key={i} className={`filters-item filters-item${e.code}`} onClick={() => {}}>
            <p
              onClick={() => {
                fmopenFilters(e.code);
              }}
            >
              <FormattedMessage id={e.code} defaultMessage={e.name} />{" "}
              {e.code == "color" ? (

                <span className="store-fillters-fms">
                  <ArrowDown className={isOpenArrowColor ? "d-block rott_fms " : "d-block"} />{" "}
                </span>
              ) : e.code =="size" ? (
                <span className="store-fillters-fms">
                  <ArrowDown className={isOpenArrowSize ? "d-block rott_fms " : "d-block"} />{" "}
                </span>
              ):
              <span className="store-fillters-fms">
                  <ArrowDown className={isOpenArrowALt ? "d-block rott_fms " : "d-block"} />{" "}
                </span>
              }
            </p>
            {e.code == "color" ? (
              <div className={`filters-item__options ${isOpenFilter} `}>{cases(e.code, e.options)}</div>
            ) : (
              (e.code == "size" ? (
                <div className={`filters-item__options ${isOpenFilterSize} `}>{cases(e.code, e.options)}</div>
              ) : (
                <div className={`filters-item__options ${isOpenFilterAlt} `}>{cases(e.code, e.options)}</div>
              ))
            )}
          </div>
        );
      })
    );
  };
  // const [isOpen, setIsOpen] = useState("fm-closed");
  // const [isOpenArrow, setIsOpenArrow] = useState(false);

  // function fmopen() {
  //     if (isOpen == "fm-closed") {
  //         setIsOpen("fm-Open ");
  //     } else {
  //         setIsOpen("fm-closed");
  //     }
  // }

  const filtersPrice = () => {
    return (
      <div className="widget-filters__list filters-item">
        <p>
          <FormattedMessage id="price-filter" defaultMessage="Price" />
        </p>
        <FilterRange
          key={"Max Price"}
          data={{ min: 0, max: maxPrice ? maxPrice : "" }}
          value={selectedFilters.price !== undefined ? selectedFilters.price : ""}
          filters={selectedFilters}
          onChangeValue={setSelectedFilters}
          title={"Գին"}
          currency={currency.code}
        />
      </div>
    );
  };
  
  const breadcrumb = [
    { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: RedirectUrl.home() },

    {
      // title: <FormattedMessage id="l" defaultMessage={store.shop_title} />,
      // url: `/stores/${product.data.shop_id}`,
    },
    // { title: product.data.name, url: RedirectUrl.product(product.data) },
  ];

  

  return (
    <div className="container ">
      <PageHeader breadcrumb={breadcrumb} />
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-12 store-filters-container">
          {store ? (
            <div
              className="store-container"
              onClick={() => {
                fmopen();
                setIsOpenArrow(!isOpenArrow);
              }}
            >
              <img src={`http://api.marketin.am/storage/${store.logo}`} alt="" />
              <h2>{store.shop_title}</h2>
              {store.description && (
                <>
                  <p className={isOpen} dangerouslySetInnerHTML={{ __html: store.description }}></p>
                  {store.description.length > 60 && (
                    <div className="arrow-conteiner-fm">
                      <ArrowDown className={isOpenArrow ? "d-block rott_fms " : "d-block"} />
                    </div>
                  )}
                </>
              )}
            </div>
          ) : null}
          <div className="filters-container">
            {filtersHtml()}
            {filtersPrice()}
          </div>
        </div>
        <div className="col-xl-9 col-lg-9 col-md-12">{products}</div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ sidebar }) => ({
  isSidebarOpen: sidebar.open,
});
export default connect(mapStateToProps)(StoreInnerPage);
