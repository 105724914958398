// react
import React from "react";
import { Link } from "react-router-dom";
// data stubs

import { FormattedMessage } from "react-intl";

export default function FooterContacts(props) {
    const { id, page_id, name, url_key } = props;
    let urlcat = url_key ? url_key : "/page/" + page_id;

    return (
        <div>
            <Link to={urlcat}>
                <FormattedMessage id={`${name}`.toLowerCase().replace(/ /g, ".")} defaultMessage={name} />
            </Link>
        </div>
    );
}
