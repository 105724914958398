// react
import React, { useCallback, useReducer } from "react";

// application
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import ProductsView from "../shop/ProductsView";
import { url } from "../../helper";
import { reducer } from "../shop/ShopPageCategory";
import WidgetFilters from "../widgets/WidgetFilters";
import { FormattedMessage } from "react-intl";
import FilterRange from "../filters/FilterRange";
import WidgetCategories from "../widgets/WidgetCategories";
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import { connect } from "react-redux";
function SaledProducts(props) {
    function init(state) {
        const [options, filters] = parseQuery(window.location.search);
        return { ...state, options, filters };
    }

    function parseQuery(location) {
        return [parseQueryOptions(location), parseQueryFilters(location)];
    }

    function parseQueryOptions(location) {
        const query = queryString.parse(location);
        const optionValues = {};

        if (typeof query.category_id === "string") {
            optionValues.category_id =
                typeof query.category_id === "number" ? parseInt(query.category_id) : query.category_id;
        }

        function parseQuery(location) {
            return [parseQueryOptions(location), parseQueryFilters(location)];
        }

        function parseQueryOptions(location) {
            const query = queryString.parse(location);
            const optionValues = {};

            if (typeof query.category_id === "string") {
                optionValues.category_id =
                    typeof query.category_id === "number" ? parseInt(query.category_id) : query.category_id;
            }
            if (typeof query.search === "string") {
                optionValues.search = query.search;
            }
            if (typeof query.page === "string") {
                optionValues.page = parseFloat(query.page);
            }
            if (typeof query.limit === "string") {
                optionValues.limit = parseFloat(query.limit);
            }
            if (typeof query.sort === "string") {
                optionValues.sort = query.sort;
            }

            return optionValues;
        }
        if (typeof query.limit === "string") {
            optionValues.limit = parseFloat(query.limit);
        }
        if (typeof query.sort === "string") {
            optionValues.sort = query.sort;
        }

        return optionValues;
    }

    function parseQueryFilters(location) {
        const query = queryString.parse(location);
        const filterValues = {};

        Object.keys(query).forEach((param) => {
            const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

            if (!mr) {
                return;
            }

            const filterSlug = mr[1];

            filterValues[filterSlug] = query[param];
        });

        return filterValues;
    }

    const initialState = {
        init: false,
        /**
         * Indicates that the category is loading.
         */
        categoryIsLoading: true,
        /**
         * Category object.
         */
        category: null,
        /**
         * Indicates that the products list is loading.
         */
        productsListIsLoading: true,
        /**
         * Products list.
         */
        productsList: null,
        /**
         * Products list options.
         *
         * options.page:  number - Current page.
         * options.limit: number - Items per page.
         * options.sort:  string - Sort algorithm.
         */
        options: {},
        /**
         * Products list filters.
         *
         * filters[FILTER_SLUG]: string - filter value.
         */
        filters: {},
    };

    const [fill, Setfill] = useState([]);

    const [saledProducts, setSaledProducts] = useState();
    const [page, setPage] = useState(1);
    const locale = useSelector((state) => state.locale);
    const currency = useSelector((state) => state.currency);
    const customer = useSelector((state) => state.customer);
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [maxPrice, setmaxPrice] = useState(null);
    const [minPrice, setminPrice] = useState(null);
    const [dep, setdep] = useState();
    const [selectedFilters, setSelectedFilters] = useState({});
    const categories = useSelector((state) => state.categories);
    const { categoriess } = props;

    // useEffect(() => {
    //     fetch(`${url}/api/categories`)
    //         .then((response) => response.json())
    //         .then((res) => {
    //             setdep(res.categories);
    //         });
    // }, []);

    useEffect(() => {
        let filters = "";
        if (selectedFilters["price"]) {
            filters += `&price=${selectedFilters["price"]}`;
        }
        if (selectedFilters["category_id"]) {
            filters += `&category_id=${selectedFilters["category_id"]}`;
        }

        fetch(
            `${url}/api/z/prods?savings=true&page=${page}&limit=24&locale=${locale}&currency=${currency.code}${filters}`
        )
            .then((res) => res.json())
            .then((res) => {
                setSaledProducts(res);
                maxPrice === null && setmaxPrice(res.data[res.data.length - 2].maxPrice);
                minPrice === null && setminPrice(res.data[res.data.length - 1].minPrice);
            });
    }, [locale, currency, selectedFilters, page]);

    let productsView;

    if (saledProducts) {
        productsView = (
            <ProductsView
                customer={customer}
                productsList={saledProducts}
                options={state.options}
                filters={fill}
                dispatch={dispatch}
                page={page}
                setPage={setPage}
            />
        );
    }
    if (!saledProducts) {
        productsView = <div className="block-loader__spinner" />;
    }
    const filtersPrice = () => {
        return (
            <div className="widget-filters__list filters-item">
                <p>
                    <FormattedMessage id="price-filter" defaultMessage="Price" />
                </p>
                <FilterRange
                    key={"Max Price"}
                    data={{
                        min: 0,
                        max: maxPrice ? maxPrice : "",
                    }}
                    value={selectedFilters.price !== undefined ? selectedFilters.price : ""}
                    filters={selectedFilters}
                    onChangeValue={setSelectedFilters}
                    title={"Գին"}
                    currency={currency.code}
                />
            </div>
        );
    };
    function customFunction(id) {
        setSelectedFilters({
            ...selectedFilters,
            category_id: id,
        });
    }
    let content;
    const handleResetFilters = useCallback(() => {
        setSelectedFilters({});
        dispatch({ type: "RESET_FILTERS" });
    }, [dispatch]);
    content = (
        <div className="products-view__empty">
            <div className="products-view__empty-title ">
                <FormattedMessage id="noMatching" defaultMessage="Համապատասխան իրեր չկան" />{" "}
            </div>
            <div className="products-view__empty-subtitle">
                <FormattedMessage id="resetting" defaultMessage="Փորձեք զրոյացնել զտիչները" />
            </div>
            <button type="button" className="btn btn-primary btn-sm" onClick={handleResetFilters}>
                <FormattedMessage id="resetFilters" defaultMessage="Վերականգնել ֆիլտրերը" />
            </button>
        </div>
    );
    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: "" },
        { title: <FormattedMessage id="saledProducts" defaultMessage="Sales" />, url: "" },
    ];
    return (
        <React.Fragment>
            <Helmet>{/* <title>{`Login — ${theme.name}`}</title> */}</Helmet>
            <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-12 store-filters-container">
                        <div className="filters-container">
                            <div className="block-sidebar__item">
                                <WidgetCategories
                                    selectedFilters={selectedFilters}
                                    categories={categoriess.categories}
                                    customFunction={customFunction}
                                />
                            </div>
                            {filtersPrice()}
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-12">
                        <div className="shop-layout__content">
                            <div className="block">
                                {saledProducts && Object.keys(saledProducts.data).length <= 2 ? content : productsView}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    categoriess: state.categories,
});

export default connect(mapStateToProps)(SaledProducts);
