import React from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ArrowRoundedDown9x6Svg } from "../../svg";

export default function MobileFooterAboutUs(props) {
    const [storeOpen, setStoreOpen] = useState("none");

    function storeToggle() {
        if (storeOpen == "none") {
            setStoreOpen("block");
        } else {
            setStoreOpen("none");
        }
    }

    return (
        <div className="site-footer__widget p-0">
            <div className="footer-title" onClick={() => storeToggle()}>
                <span className="footer-title-fms">
                    {" "}
                    <FormattedMessage id="aboutUs" defaultMessage="Մեր մասին" />
                </span>
                <span className="mobile-footer-arrow">
                    {" "}
                    <ArrowRoundedDown9x6Svg />{" "}
                </span>
            </div>

            <div style={{ display: storeOpen }} className="mobile-footer-content">
                <ul className="footer-contacts__contacts">{props.footercont}</ul>
                {/* <Link className="footer-link-text" to="">
                    <FormattedMessage id="contactUs" defaultMessage="Հետադարձ կապ" />
                </Link>

                <Link to="" className="footer-link-text">
                    <FormattedMessage id="aboutStores" defaultMessage="Խանութների մասին" />
                </Link>


                <Link to="" className="footer-link-text">
                    <FormattedMessage id="userAgreement" defaultMessage="Օգտագործողի համաձայնագիր" />
                </Link> */}
            </div>
        </div>
    );
}
