import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { url } from "../../helper";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

export default function Register(props) {
    const intialValues = { email: "", password: "", first_name: "", last_name: "", password_confirmation: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const locale = useSelector((state) => state.locale);
    const history = useHistory();

    const submitForm = () => {
        let options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
        };

        fetch(`${url}/api/customer/register`, options)
            .then((responce) => responce.json())
            .then((res) => {
                if (res.message == "Your account has been created successfully.") {
                    history.push("/thanks-for-registration");
                } else {
                    const keys = Object.keys(res.errors);

                    const errors = keys.reduce((prev, next) => {
                        return {
                            ...prev,
                            [next]: res.errors[next],
                        };
                    }, {});
                    setFormErrors(errors);
                }
            })
            .catch((err) => console.error(err));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.first_name) {
            errors.first_name = "This field is required";
        }

        if (!values.last_name) {
            errors.last_name = "This field is required";
        }

        // if (!values.phone) {
        //     errors.phone = "This field is required";
        // }

        if (!values.password_confirmation || values.password != values.password_confirmation) {
            errors.password_confirmation = "Passwords do not match";
        }

        if (!values.email || !regex.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.password || values.password.length < 6) {
            errors.password = (
                <FormattedMessage
                    id="account.error.password.required"
                    defaultMessage="Password must be more than 6 characters"
                />
            );
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    return (
        <div className="login_content_fm">
            <form action="" onSubmit={handleSubmit}>
                <div className="form-group">
                    <FormattedMessage id="name" defaultMessage="Անուն">
                        {(placeholder) => (
                            <input
                                name="first_name"
                                type="text"
                                className="form-control form-control"
                                onChange={handleChange}
                                value={formValues.first_name}
                                placeholder={placeholder}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.first_name && (
                        <span className="error red-fms">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.first_name} />
                        </span>
                    )}
                </div>

                <div className="form-group">
                    <FormattedMessage id="lname" defaultMessage="Ազգանուն">
                        {(placeholder) => (
                            <input
                                name="last_name"
                                type="text"
                                className="form-control form-control"
                                onChange={handleChange}
                                placeholder={placeholder}
                                value={formValues.last_name}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.last_name && (
                        <span className="error red-fms">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.last_name} />
                        </span>
                    )}
                </div>

                <div className="form-group">
                    <FormattedMessage id="topbar.email" defaultMessage="էլ․ Հասցե">
                        {(placeholder) => (
                            <input
                                id="header-signin-email"
                                name="email"
                                type="email"
                                className="form-control form-control"
                                onChange={handleChange}
                                placeholder={placeholder}
                                value={formValues.email}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.email && (
                        <span className="error red-fms">
                            {formErrors.email == "The email has already been taken." ? (
                                <FormattedMessage
                                    id="err.email.taken"
                                    defaultMessage="The email has already been taken."
                                />
                            ) : (
                                <FormattedMessage id="account.error.required" defaultMessage={formErrors.email} />
                            )}
                        </span>
                    )}
                </div>

                <div className="form-group">
                    <FormattedMessage id="topbar.password" defaultMessage="Գաղտնաբառ">
                        {(placeholder) => (
                            <input
                                id="header-signin-password"
                                name="password"
                                type="password"
                                className="form-control form-control"
                                onChange={handleChange}
                                placeholder={placeholder}
                                value={formValues.password}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.password && (
                        <span className="error red-fms">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.password} />
                        </span>
                    )}
                </div>
                <div className="form-group">
                    <FormattedMessage id="password.confirmation" defaultMessage="Հաստատել գաղտնաբառը">
                        {(placeholder) => (
                            <input
                                id="header-signin-password"
                                name="password_confirmation"
                                type="password"
                                className="form-control form-control"
                                onChange={handleChange}
                                placeholder={placeholder}
                                value={formValues.password_confirmation}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div>
                    {formErrors.password_confirmation && (
                        <span className="error red-fms">
                            <FormattedMessage
                                id="error.password.dont.match"
                                defaultMessage={formErrors.password_confirmation}
                            />
                        </span>
                    )}
                </div>
                <button
                    // onClick={submitForm}
                    type="submit"
                    className="btn btn-primary btn__custom"
                >
                    <FormattedMessage id="topbar.register" defaultMessage="Գրանցում" />
                </button>
            </form>
        </div>
    );
}
