// react
import React, { useMemo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import shopApi from "../../api/shop";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockProducts from "../blocks/BlockProducts";
import BlockSlideShow from "../blocks/BlockSlideShow";
import { FormattedMessage } from "react-intl";
import theme from "../../data/theme";
import { useSelector } from "react-redux";
import WidgetCategories from "../widgets/WidgetCategories";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockHomeBaner from "../blocks/BlockHomeBaner";
import BlockBrandsCarousel from "../blocks/BlockBrandsCarousel";
import Zara from "../../images/Zara.png";
import Leopard from "../../images/Leopard.png";
import PB from "../../images/P&B.png";
import Bossini from "../../images/Bossini.png";
import Bershka from "../../images/Bershka.png";
import currencyReducer from "../../store/currency";
import { url } from "../../helper";

function HomePageOne() {
  const selectedData = useSelector((state) => state.locale);
  const currency = useSelector((state) => state.currency);
  const customer = useSelector((state) => state.customer);
  const [first, setFirst] = useState();
  const [second, setSecond] = useState();
  const [third, setThird] = useState();
  const [fourth, setFourth] = useState();
  const [fifth, setFifth] = useState();
  const [sixth, setSixth] = useState();
  const [seventh, setSeventh] = useState();
  const [eighth, setEighth] = useState();
  const [ninth, setNinth] = useState();
  const [tenth, setTenth] = useState();
  const [eleventh, setEleventh] = useState();
  const [twelfth, setTwelfth] = useState();
  const [thirteenth, setThirteenth] = useState();
  const [banner, GetBanner] = useState();

  const [featured, Setfeatured] = useState();
  const newCollection = <FormattedMessage id="newcollection" defaultMessage="ՆՈՐ ՏԵՍԱԿԱՆԻ" />;
  const saleCollection = <FormattedMessage id="saleCollection" defaultMessage="ԶԵՂՉՎԱԾ ՏԵՍԱԿԱՆԻ" />;

  // const brands = [
  //     { id: 1, url: Zara },
  //     { id: 2, url: Leopard },
  //     { id: 3, url: PB },
  //     { id: 4, url: Bossini },
  //     { id: 5, url: Bershka },
  //     { id: 6, url: Zara },
  // ]

  useEffect(() => {
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_first" }).then((res) => {
      setFirst(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_second" }).then((res) => {
      setSecond(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_third" }).then((res) => {
      setThird(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_fourth" }).then((res) => {
      setFourth(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_fifth" }).then((res) => {
      setFifth(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_sixth" }).then((res) => {
      setSixth(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_seventh" }).then((res) => {
      setSeventh(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_eighth" }).then((res) => {
      setEighth(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_ninth" }).then((res) => {
      setNinth(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_tenth" }).then((res) => {
      setTenth(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_eleventh" }).then((res) => {
      setEleventh(res.data);
    });
    shopApi.getLatestProducts({ lang: selectedData, currency: currency, position: "position_twelfth" }).then((res) => {
      setTwelfth(res.data);
    });
    shopApi
      .getLatestProducts({ lang: selectedData, currency: currency, position: "position_thirteenth" })
      .then((res) => {
        setThirteenth(res.data);
      });
  }, [selectedData, currency.code]);

  useEffect(() => {
    // shopApi.getFeaturedProducts({ lang: selectedData }).then(res => Setfeatured(res));
  }, [selectedData]);

  useEffect(() => {
    fetch(`${url}/api/banners`)
      .then((response) => response.json())
      .then((res) => {
        if (res) GetBanner(res);
      });
  }, []);

  function banners() {
    return banner.map((e, i) => {
      return <BlockHomeBaner key={i} url={e ? e.path : ""} content={e.content} id={e.id} link={e.link} />;
    });
  }
  // console.log(first?.length > 2, "kbdkkgs");
  return (
    <React.Fragment>
      <Helmet>
        <title>{` ${theme.name}`}</title>
       
      </Helmet>
      {useMemo(
        () => (
          <BlockSlideShow lang={selectedData} />
        ),
        [selectedData]
      )}
      {/* {useMemo(() => <BlockBrandsCarousel products={brands} rows={1} />, [bestsellers, selectedData,])} */}
      {useMemo(
        () => (
          <BlockProductsCarousel products={first} id="position_1" />
        ),
        [first]
      )}

      {/* 'first' before was bestsellers */}
      {banner && <div className="first-banner-fms container">{banners()}</div>}
      {/* {useMemo(
                () => (
                    <BlockHomeBaner url={banner && banner.length && banner[0] ? banner[0].path : ""} />
                ),
                [first]
            )} */}
      {useMemo(
        () => (
          <BlockProductsCarousel products={second} id="position_2" />
        ),
        [second]
      )}
      {useMemo(
        () => (
          <BlockProductsCarousel products={third} id="position_3" />
        ),
        [third]
      )}
      {useMemo(
        () => fourth && fourth.length > 2 && <BlockProductsCarousel products={fourth} id="position_4" />,
        [fourth]
      )}
      {useMemo(() => fifth && fifth.length > 2 && <BlockProductsCarousel products={fifth} id="position_5" />, [fifth])}
      {useMemo(() => sixth && sixth.length > 2 && <BlockProductsCarousel products={sixth} id="position_6" />, [sixth])}
      {useMemo(
        () => seventh && seventh.length > 2 && <BlockProductsCarousel products={seventh} id="position_7" />,
        [seventh]
      )}
      {useMemo(
        () => eighth && eighth.length > 2 && <BlockProductsCarousel products={eighth} id="position_8" />,
        [eighth]
      )}
      {useMemo(() => ninth && ninth.length > 2 && <BlockProductsCarousel products={ninth} id="position_9" />, [ninth])}
      {useMemo(() => tenth && tenth.length > 2 && <BlockProductsCarousel products={tenth} id="position_10" />, [tenth])}
      {useMemo(
        () => eleventh && eleventh.length > 2 && <BlockProductsCarousel products={eleventh} id="position_11" />,
        [eleventh]
      )}
      {useMemo(
        () => twelfth && twelfth.length > 2 && <BlockProductsCarousel products={twelfth} id="position_12" />,
        [twelfth]
      )}
      {useMemo(
        () => thirteenth && thirteenth.length > 2 && <BlockProductsCarousel products={thirteenth} id="position_13" />,
        [thirteenth]
      )}
    </React.Fragment>
  );
}

export default HomePageOne;
