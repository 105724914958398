export default {
    name: 'Marketin',
    fullName: 'Marketin',

    fb: '/',
    instagram: '/',

    contacts: {
        address: 'Հասցե։ Երևան, Հայաստան',
        email: 'marketing@megalight.am',
        phone: 'Հեռ: 374 93 99-10-93, 10 35-11-00',
    },
};
